export interface User {
    id: string;
    email: string;
    status: string;
    username: string;
    registerType: string;
}

export const USER_REGISTER_TYPES = {
    DEFAULT: 'default',
    MANUAL: 'manual',
};
