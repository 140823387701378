import {Injectable} from '@angular/core';
import {ApiClient} from '../../../shared/service/network/api-client.service';
import {from, Observable, of} from 'rxjs';
import {Sport} from '../../model/definition/definition.models';
import {Store} from '@ngrx/store';
import * as SportAction from '../../store/definition/sport.actions';
import * as SportSelector from '../../store/definition/sport.selectors';
import {DefinitionState} from '../../store/definition/definition.reducer';
import {distinctUntilChanged, filter, first, mergeMap, shareReplay, tap, toArray} from 'rxjs/operators';
import {areEquals} from '../../../shared/function/lowdash';
import {DefinitionMapper} from './definition.mapper';
import memorize from 'memorize-decorator';


@Injectable({
    providedIn: 'root'
})
export class SportService {
    readonly findRoute = 'api-v1-find-sport';
    readonly cache = {};
    readonly allSports$: Observable<Sport[]>;

    constructor(private apiClient: ApiClient, private store: Store<DefinitionState>, private definitionMapper: DefinitionMapper) {
        this.allSports$ = this.store.select(SportSelector.getAllSports).pipe(
            distinctUntilChanged(areEquals),
        );
    }
    @memorize({ttl: 10000})
    loadAll(): Observable<Sport[]> {
        this.apiClient.get<Sport[]>(this.findRoute)
            .pipe(
                mergeMap(rawSports => this.definitionMapper.hydrateDefinitions(rawSports)),
                tap(sports => this.store.dispatch(SportAction.loadAllSports({sports})))
            )
            .toPromise()
            .then(() => {
            });

        return this.getAll();
    }

    @memorize({ttl: 5000})
    loadById(id: string): Observable<any> {
        this.apiClient.get<Sport>(this.findRoute, {id})
            .pipe(
                mergeMap(rawSport => this.definitionMapper.hydrateDefinition(rawSport)),
                tap(sport => this.store.dispatch(SportAction.loadSport({sport})))
            )
            .toPromise()
            .then(() => {
            });

        return this.getById(id);
    }

    getAll(): Observable<Sport[]> {
        return this.store.select(SportSelector.getAllSports).pipe(
            distinctUntilChanged(areEquals),
            shareReplay()
        );
    }

    getById(id: string): Observable<Sport> {
        return this.store.select(SportSelector.getSport, id).pipe(
            distinctUntilChanged(areEquals),
            shareReplay()
        );
    }

    getByIds(ids: string[]): Observable<Sport[]> {
        return this.getAll().pipe(
            mergeMap((sports: Sport[]) => from(sports).pipe(
                filter(sport => ids.includes(sport.id)),
                toArray()
            )),
        );
    }

    getByNames(names: string[]): Observable<Sport[]> {
        return this.getAll().pipe(
            mergeMap((sports: Sport[]) => {
                return of(sports.filter(sport => names.includes(sport.name)));
            }),
        );
    }

}
