import { Action, createReducer, on } from '@ngrx/store';
import { CoachClientData } from '../../model/user/leet-user.model';
import { initState } from '../meta/meta.reducers';
import { clearState, initClientCoachData } from '../meta/meta.actions';
import * as CoachClientDataActions from './coach-client-data.actions';

export const STORE_KEY = 'coach-client-data';

export interface DefinitionState {
    coachClientData: CoachClientData[];
}

export const initialState: DefinitionState = {
    coachClientData: [],
};

const loadCoachClientData = (state, { coachClientData }) => {
    if (!coachClientData) return state;

    const isCoachClientDataInStore = !!state.coachClientData.find(
        (coachClientDataNode) => coachClientData.id === coachClientDataNode.id
    );
    if (isCoachClientDataInStore) return updateLeetUserCallToActions(state, coachClientData);

    return addCoachClientData(state, coachClientData);
};

const addCoachClientData = (state, coachClientData) => {
    return {
        coachClientData: [...state.coachClientData, coachClientData],
    };
};

const updateLeetUserCallToActions = (state: DefinitionState, coachClientData: CoachClientData) => {
    return {
        coachClientData: state.coachClientData.map((storeCoachClientData: CoachClientData) => {
            if (storeCoachClientData.id !== coachClientData.id) return storeCoachClientData;
            return coachClientData;
        }),
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initClientCoachData, initState),
        on(CoachClientDataActions.load, loadCoachClientData)
    );

    return definitionReducer(state, action);
}
