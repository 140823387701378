import { ActionStep, Step } from './step.model';
import {
    MeasureUnit,
    TIME_UNIT_MINUTE,
    TIME_UNIT_SECOND,
    valueToTimeMeasureUnit,
} from '../../exercise-template/measure-unit.model';
import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';

export class Rest extends ActionStep {
    private readonly validTimeMeasureUnitsValues = [TIME_UNIT_SECOND, TIME_UNIT_MINUTE];

    private measureUnit: MeasureUnit;
    private value: number;

    constructor(id: string, linkedId: string, measureUnit: MeasureUnit | null, value: number) {
        super(id, linkedId, StepTypes.REST_STEP_TYPE);
        this.measureUnit = measureUnit ? measureUnit : this.defaultMeasureUnit();
        this.value = value;

        this.assert();
    }

    public getMeasureUnit(): MeasureUnit {
        return this.measureUnit;
    }

    public getValue(): number {
        if (!this.value) return 0;

        return this.value;
    }

    public defaultMeasureUnit(): MeasureUnit {
        return {
            value: TIME_UNIT_SECOND,
        };
    }

    hasNextMeasureUnit(): boolean {
        return this.validTimeMeasureUnitsValues.length > 1;
    }

    updateMeasureUnit(value: string): void {
        const isValidMeasureUnitType = this.validTimeMeasureUnitsValues.find(
            (measureUnitType) => measureUnitType === value
        );
        if (!isValidMeasureUnitType) return;

        this.measureUnit = valueToTimeMeasureUnit(value);
    }

    nextMeasureUnit(): void {
        const nextMeasureUnitValueIndex =
            (this.validTimeMeasureUnitsValues.indexOf(this.measureUnit.value) + 1) %
            this.validTimeMeasureUnitsValues.length;
        this.measureUnit = valueToTimeMeasureUnit(this.validTimeMeasureUnitsValues[nextMeasureUnitValueIndex]);
    }

    public updateValue(value: number): void {
        this.value = value;
    }

    public getTitle(): string {
        return StepTypes.REST_STEP_TYPE;
    }

    getValidTimeMeasureUnitsValues(): string[] {
        return this.validTimeMeasureUnitsValues;
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): Step {
        const clonedMeasureUnit: MeasureUnit = {
            value: this.measureUnit.value,
        };

        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new Rest(uuid4(), linkedId, clonedMeasureUnit, this.value);
    }

    getSpecificStepType(): Step {
        return this as Rest;
    }
}
