import {Injectable} from '@angular/core';
import {ApiClient} from '../../../shared/service/network/api-client.service';
import {Store} from '@ngrx/store';
import {DefinitionState} from '../../store/definition/definition.reducer';
import {Observable, of, throwError} from 'rxjs';
import {Objective, RxObjective} from '../../model/definition/definition.models';
import * as ObjectiveAction from '../../store/definition/objective.actions';
import * as ObjectiveSelector from '../../store/definition/objective.selectors';
import {CreateOrUpdateObjective} from '../../command/definition/objective.commands';
import {EntityUpdated} from '../../../shared/model/network/network-models';
import {catchError, distinctUntilChanged, map, mergeMap, shareReplay, switchMap, tap} from 'rxjs/operators';
import {v4 as uuid4} from 'uuid';
import {areEquals} from '../../../shared/function/lowdash';
import {createDate} from '../../model/date/leet-date';
import {SportService} from './sport.service';
import memorize from 'memorize-decorator';
import {skipWhileNullOrUndefined} from '../../../shared/function/rxjs.operators';
import {Session} from '../session/session.service';
import {sortByName} from '../../function/definition.operators';

@Injectable({
    providedIn: 'root',
})
export class ObjectiveService {
    readonly findRoute = 'api-v1-find-objective';
    readonly updateRoute = 'api-v1-update-objective';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private sportService: SportService,
        private session: Session
    ) {
    }

    loadAll(): Observable<RxObjective[]> {
        this.apiClient
            .get<Objective[]>(this.findRoute)
            .toPromise()
            .then((objectives) => this.store.dispatch(ObjectiveAction.loadAll({objectives})));

        return this.getAll();
    }

    @memorize({ttl: 1000})
    load(id: string): Observable<RxObjective> {
        this.apiClient
            .get<Objective>(this.findRoute, {id})
            .toPromise()
            .then((objective) => this.store.dispatch(ObjectiveAction.load({objective})));

        return this.getById(id);
    }

    getAll(): Observable<RxObjective[]> {
        return this.store
            .select(ObjectiveSelector.getAll)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this)),
                sortByName()
            );
    }

    getById(id: string): Observable<RxObjective> {
        return this.store
            .select(ObjectiveSelector.get, id)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRx.bind(this))
            );
    }

    getByIds(ids: string[]): Observable<RxObjective[]> {
        return this.getAll().pipe(
            map((objectives: RxObjective[]) => objectives.filter((objective) => ids.includes(objective.id)))
        );
    }

    getPrivateScopedByCurrentUser(): Observable<Objective[]> {
        this.loadAll();
        return this.store
            .select(ObjectiveSelector.getPrivateScopedByCurrentUser, this.session.currentAuthUser().entityId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay(), map(this.toRxs.bind(this)));
    }

    update(command: CreateOrUpdateObjective): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.updateRoute, command, {id: command.id})
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = {id: command.id};
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    emptyObjective(): Observable<Objective> {
        const objective: Objective = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            sportsIds: [],
            scope: {
                scope: 'SCOPE_PUBLIC_ADMIN',
                leetUserOwnerId: '',
            },
            createdAt: createDate(),
        };

        return of(objective);
    }

    private toRxs(objectives: Objective[]): RxObjective[] {
        return objectives.map((objective: Objective) => this.toRx(objective));
    }

    private toRx(objective: Objective): RxObjective {
        return {
            ...objective,
            sports$: objective.sportsIds.map((sportId) =>
                of({}).pipe(switchMap(() => this.sportService.loadById(sportId)))
            ),
        };
    }
}
