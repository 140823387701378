import { Action, createReducer, on } from '@ngrx/store';
import { LeetUser } from '../../model/user/leet-user.model';
import * as LeetUserAction from './leet-user.actions';
import { clearState, initLeetUserState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'leet-user';

export interface DefinitionState {
    leetUsers: LeetUser[];
}

export const initialState: DefinitionState = {
    leetUsers: [],
};

const loadLeetUsers = (state: DefinitionState, { leetUsers }) => {
    let newState = state;
    leetUsers.forEach((leetUser) => (newState = loadLeetUser(newState, { leetUser })));
    return { ...newState };
};

const loadLeetUser = (state, { leetUser }) => {
    if (!leetUser) return state;

    const isLeetUserInStore = !!state.leetUsers.find((leetUserNode) => leetUser.id === leetUserNode.id);
    if (isLeetUserInStore) return updateLeetUser(state, leetUser);

    return addLeetUser(state, leetUser);
};

const addLeetUser = (state, leetUser) => {
    return {
        leetUsers: [...state.leetUsers, leetUser],
    };
};

const updateLeetUser = (state: DefinitionState, leetUser: LeetUser) => {
    return {
        leetUsers: [
            ...state.leetUsers.map((storeLeetUser: LeetUser) => {
                if (storeLeetUser.id !== leetUser.id) return storeLeetUser;
                return leetUser;
            }),
        ],
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initLeetUserState, initState),
        on(LeetUserAction.load, loadLeetUser),
        on(LeetUserAction.loadMany, loadLeetUsers)
    );

    return definitionReducer(state, action);
}
