import { createSelector } from '@ngrx/store';
import { getDefinitionState } from './definition.selector';
import { DefinitionState } from './definition.reducer';
import { isPublicScope } from '../../model/scope/scope.models';

const selectObjectives = (state: DefinitionState) => state.objectives;

export const getAll = createSelector(getDefinitionState, selectObjectives);

export const get = createSelector(getDefinitionState, (state: DefinitionState, id: string) =>
    selectObjectives(state).find((objectiveNode) => objectiveNode.id === id)
);

export const getPrivateScopedByCurrentUser = createSelector(
    getDefinitionState,
    (state: DefinitionState, leetUserOwnerId: string) =>
        selectObjectives(state).filter((objective) => {
            if (objective.status === 'disabled') return false;
            if (isPublicScope(objective.scope.scope)) return true;
            return objective.scope.leetUserOwnerId === leetUserOwnerId;
        })
);
