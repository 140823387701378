import { FOR_TIME_TYPE, TrainingSequence } from '../training-sequence.model';
import { ScalingSequence } from '../scaling-sequence.model';
import { TrainingExerciseRestriction } from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import {
    distanceMeasureTemplate,
    repetitionMeasureTemplate,
    serieMeasureTemplate,
    timeMeasureTemplate,
    weightMeasureTemplate,
} from '../../exercise-template/measure-template.model';
import { SequenceRestriction } from '../restriction/sequence-restriction.model';
import { SeriesMeasureModeTrainingExerciseRestriction } from '../restriction/series-measure-mode-training-exercise-restriction.model';

export class ForTimeSequence extends TrainingSequence {
    constructor(
        id: string,
        name: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string
    ) {
        super(id, name, FOR_TIME_TYPE, mode, scalingSequences, sessionSequenceId, notes);
    }

    _doAssert(): void {}

    public _sequenceRestrictions(): SequenceRestriction[] {
        return [new SeriesMeasureModeTrainingExerciseRestriction()];
    }

    public _trainingExerciseRestriction(): TrainingExerciseRestriction | null {
        return {
            availableExerciseMeasureTemplates: [
                timeMeasureTemplate(),
                distanceMeasureTemplate(),
                repetitionMeasureTemplate(),
            ],
            availableMeasureModeTemplates: [serieMeasureTemplate()],
            weightMeasureTemplate: weightMeasureTemplate(),
        };
    }

    _clone(): TrainingSequence {
        const scalingSequences = this.scalingSequences.map((scalingSequence) => scalingSequence.clone());

        return new ForTimeSequence(
            this.getId(),
            this.getName(),
            this.getMode(),
            scalingSequences,
            this.getSessionSequenceId(),
            this.getNotes()
        );
    }

    _canShowStep(stepLinkedId: string): boolean {
        return true;
    }

    _canAddExerciseInOnlyOneRound(): boolean {
        return false;
    }

    _allRoundsShouldHaveSameSteps(): boolean {
        return true;
    }

    _isExerciseMeasureModeRequired(): boolean {
        return false;
    }

    _addRestAfterExercise(): boolean {
        return false;
    }

    _canCreateComplex(): boolean {
        return false;
    }

    public _doSanetize(): void {}

    public _canAddBuyIn(): boolean {
        return false;
    }

    public _canAddBuyOut(): boolean {
        return false;
    }
}
