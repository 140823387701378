import { Action, createReducer, on } from '@ngrx/store';
import * as TrainingSequenceTypesAction from './training-sequence-types.actions';
import { clearState, initTrainingSequenceState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'training-template-sequence';

export interface DefinitionState {
    trainingSequenceTypes: string[];
}

export const initialState: DefinitionState = {
    trainingSequenceTypes: [],
};

const updateTrainingSequenceTypesList = (state, { trainingSequenceTypes }) => {
    return {
        ...state,
        trainingSequenceTypes,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initTrainingSequenceState, initState),
        on(TrainingSequenceTypesAction.loadAll, updateTrainingSequenceTypesList)
    );

    return definitionReducer(state, action);
}
