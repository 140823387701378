import { Action, createReducer, on } from '@ngrx/store';
import * as FileAction from './file.actions';
import { Video } from '../../model/file/video.models';
import { Image } from '../../model/file/image.models';
import { clearState, initFileState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';
import { VoiceMessage } from '../../model/file/voice-message.model';

export const STORE_KEY = 'file';

export interface DefinitionState {
    videos: Video[];
    images: Image[];
    voiceMessages: VoiceMessage[];
}

export const initialState: DefinitionState = {
    videos: [],
    images: [],
    voiceMessages: [],
};

const updateVideosList = (state, { videos }) => {
    return {
        ...state,
        videos: addOrReplaceNewVideos(videos, state.videos),
    };
};

const updateVoiceMessagesList = (state, { voiceMessages }) => {
    return {
        ...state,
        voiceMessages: addOrReplaceNewVoiceMessages(voiceMessages, state.voiceMessages),
    };
};

const updateImagesList = (state, { images }) => {
    return {
        ...state,
        images: addOrReplaceNewImages(images, state.images),
    };
};

const updateImage = (state, { image }) => {
    return {
        ...state,
        images: addOrUpdateImage(image, state.images),
    };
};

const updateVideo = (state, { video }) => {
    return {
        ...state,
        videos: addOrUpdateVideo(video, state.videos),
    };
};

const updateVoiceMessage = (state, { voiceMessage }) => {
    return {
        ...state,
        voiceMessages: addOrUpdateVoiceMessage(voiceMessage, state.voiceMessages),
    };
};

const addOrReplaceNewImages = (images: Image[], oldImages: Image[]): Image[] => {
    let newImages = oldImages;

    for (const newImage of images) newImages = addOrUpdateImage(newImage, newImages);

    return newImages;
};

const addOrReplaceNewVideos = (videos: Video[], oldVideos: Video[]): Video[] => {
    let newVideos = oldVideos;

    for (const newVideo of videos) newVideos = addOrUpdateVideo(newVideo, newVideos);

    return newVideos;
};

const addOrReplaceNewVoiceMessages = (
    voiceMessages: VoiceMessage[],
    oldVoiceMessages: VoiceMessage[]
): VoiceMessage[] => {
    let newVideos = oldVoiceMessages;
    for (const newVoiceMessage of voiceMessages) newVideos = addOrUpdateVoiceMessage(newVoiceMessage, newVideos);
    return newVideos;
};

const addOrUpdateImage = (image: Image, images: Image[]): Image[] => {
    let existsImage = false;

    const newImages = images.map((savedImage) => {
        if (savedImage.id !== image.id) return savedImage;

        existsImage = true;
        return image;
    });

    if (!existsImage) newImages.unshift(image);
    return newImages;
};

const addOrUpdateVideo = (video: Video, videos: Video[]): Video[] => {
    let existsVideo = false;

    const newVideos = videos.map((savedImage) => {
        if (savedImage.id !== video.id) return savedImage;

        existsVideo = true;
        return video;
    });

    if (!existsVideo) newVideos.unshift(video);

    return newVideos;
};

const addOrUpdateVoiceMessage = (voiceMessage: VoiceMessage, voiceMessages: VoiceMessage[]): VoiceMessage[] => {
    let existsVoiceMessage = false;

    const newVoiceMessages = voiceMessages.map((savedVoiceMessage) => {
        if (savedVoiceMessage.id !== voiceMessage.id) return savedVoiceMessage;
        existsVoiceMessage = true;
        return voiceMessage;
    });

    if (!existsVoiceMessage) newVoiceMessages.unshift(voiceMessage);

    return newVoiceMessages;
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initFileState, initState),
        on(FileAction.loadImages, updateImagesList),
        on(FileAction.loadVideos, updateVideosList),
        on(FileAction.loadImage, updateImage),
        on(FileAction.loadVideo, updateVideo),
        on(FileAction.loadVoiceMessage, updateVoiceMessage),
        on(FileAction.loadVoiceMessages, updateVoiceMessagesList)
    );

    return definitionReducer(state, action);
}
