import { Injectable } from '@angular/core';
import { LocationService } from '../definition/location.service';
import { SessionSequenceService } from '../definition/session-sequence.service';
import { MuscleGroupService } from '../definition/muscle-group.service';
import { TimePlanningService } from '../definition/time-planning.service';
import { DifficultyLevelService } from '../definition/difficulty-level.service';
import { MaterialService } from '../definition/material.service';
import { ObjectiveService } from '../definition/objective.service';
import { SportService } from '../definition/sport.service';
import { TrainingDurationService } from '../definition/training-duration.service';
import { ExerciseTemplateService } from '../exercise-template/exercise-template.service';
import { ChatService } from '../chat/chat.service';

@Injectable({
    providedIn: 'root',
})
export class LeetInitializerService {
    constructor() // private locationService: LocationService,
    // private sequenceService: SessionSequenceService,
    // private muscleGroupService: MuscleGroupService,
    // private timePlanningService: TimePlanningService,
    // private difficultyLevelService: DifficultyLevelService,
    // private materialService: MaterialService,
    // private objectiveService: ObjectiveService,
    // private sportService: SportService,
    // private trainingDurationService: TrainingDurationService,
    // private exerciseTemplateService: ExerciseTemplateService,
    // private chatService: ChatService
    {}

    initialize(): void {
        //     this.chatService.loadSessionChats();
        //     this.exerciseTemplateService.loadAll();
        //     this.locationService.loadAll();
        //     this.sequenceService.loadAll();
        //     this.muscleGroupService.loadAll();
        //     this.timePlanningService.loadAll();
        //     this.difficultyLevelService.loadAll();
        //     this.materialService.loadAll();
        //     this.objectiveService.loadAll();
        //     this.trainingDurationService.loadAll();
        //     this.sportService.loadAll();
    }
}
