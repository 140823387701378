import { REST_STEP_TYPE, ROUND_STEP_TYPE, TRAINING_EXERCISE_STEP_TYPE } from '../training-sequence/step/step.types';
import { Step } from '../training-sequence/step/step.model';

export const STEP_EXECUTION_STATUS_PENDING = 'pending';
export const STEP_EXECUTION_STATUS_COMPLETED = 'completed';
export const STEP_EXECUTION_STATUS_SKIPPED = 'skipped';

export abstract class StepExecution {
    private status: string;
    private stepId: string;
    private type: string;
    // @Transform((value, stepExecution) => {
    //   console.log(stepExecution);
    //   return stepExecution.type;
    // })
    private discriminatorType: string;
    readonly isContainer: boolean;

    constructor(status: string, stepId: string, type: string) {
        this.status = status;
        this.stepId = stepId;
        this.type = type;
        this.discriminatorType = this.type;
    }

    public getStepId(): string {
        return this.stepId;
    }

    public getTitle(): string {
        return '';
    }

    public skip(): void {
        this.status = STEP_EXECUTION_STATUS_SKIPPED;
    }

    public complete(stepExecutionData: StepExecution | null = null): void {
        if (this.isCompleted()) return;

        this.updateWithExecutionData(this);
        this.status = STEP_EXECUTION_STATUS_COMPLETED;
    }

    public isPending(): boolean {
        return this.status === STEP_EXECUTION_STATUS_PENDING;
    }

    public isCompleted(): boolean {
        return this.status === STEP_EXECUTION_STATUS_COMPLETED;
    }

    public isSkipped(): boolean {
        return this.status === STEP_EXECUTION_STATUS_SKIPPED;
    }

    public isExercise(): boolean {
        return this.type === TRAINING_EXERCISE_STEP_TYPE;
    }

    public isRest(): boolean {
        return this.type === REST_STEP_TYPE;
    }

    public isRound(): boolean {
        return this.type === ROUND_STEP_TYPE;
    }

    public clone(keepId = false): StepExecution {
        return this.doClone(keepId);
    }

    public getStatus(): string {
        return this.status;
    }

    protected abstract doClone(keepId: boolean): StepExecution;

    protected abstract updateWithExecutionData(stepExecutionData: StepExecution | null): void;

    abstract getSpecificStepType(): any;
}

export abstract class ContainerStepExecution extends StepExecution {
    readonly isContainer: boolean = true;
}

export abstract class ActionStepExecution extends StepExecution {
    readonly isContainer: boolean = false;
}

export function containerStepExecutions(stepExecutions: StepExecution[]): ContainerStepExecution[] {
    return stepExecutions.filter((stepExecution) => stepExecution.isContainer);
}
