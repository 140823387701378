import {createSelector} from '@ngrx/store';
import {getDefinitionState} from './definition.selector';
import {DefinitionState} from './definition.reducer';

const selectLocations = (state: DefinitionState) => state.locations;

export const getAll = createSelector(
    getDefinitionState, selectLocations
);

export const get = createSelector(
    getDefinitionState,
    (state: DefinitionState, id: string) => selectLocations(state).find((locationNode) => locationNode.id === id)
);
