import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { from, Observable, of, throwError } from 'rxjs';
import { RxObjective, RxTimePlanning, TimePlanning } from '../../model/definition/definition.models';
import * as PlanningAction from '../../store/definition/time-planing.actions';
import * as PlanningSelector from '../../store/definition/time-planning.selectors';
import { CreateOrUpdateTimePlanning } from '../../command/definition/time-planning.commands';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    tap,
    toArray,
} from 'rxjs/operators';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { DefinitionMapper } from './definition.mapper';
import { createDate } from '../../model/date/leet-date';
import { SportService } from './sport.service';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';

@Injectable({
    providedIn: 'root',
})
export class TimePlanningService {
    readonly findRoute = 'api-v1-find-time-planning';
    readonly updateRoute = 'api-v1-update-time-planning';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private sportService: SportService
    ) {
    }

    loadAll(): Observable<RxTimePlanning[]> {
        this.apiClient
            .get<TimePlanning[]>(this.findRoute)
            .toPromise()
            .then((timePlannings) => this.store.dispatch(PlanningAction.loadAll({ timePlannings })));

        return this.getAll();
    }

    load(id: string): Observable<RxTimePlanning> {
        this.apiClient
            .get<TimePlanning>(this.findRoute, { id })
            .toPromise()
            .then((timePlanning) => this.store.dispatch(PlanningAction.load({ timePlanning })));

        return this.getById(id);
    }

    getAll(): Observable<RxTimePlanning[]> {
        return this.store
            .select(PlanningSelector.getAll)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this))
            );
    }

    getById(id: string): Observable<RxTimePlanning> {
        return this.store
            .select(PlanningSelector.get, id)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), map(this.toRx.bind(this)));
    }

    getByIds(ids: string[]): Observable<RxTimePlanning[]> {
        return this.getAll().pipe(
            map((timePlannings: RxObjective[]) => timePlannings.filter((timePlanning) => ids.includes(timePlanning.id)))
        );
    }

    update(command: CreateOrUpdateTimePlanning): Observable<EntityUpdated> {
        return this.apiClient.post<TimePlanning>(this.updateRoute, command, command).pipe(
            mergeMap(() => {
                const response: EntityUpdated = { id: command.id };
                return of(response);
            }),
            tap(() => this.load(command.id)),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    private toRxs(timePlannings: TimePlanning[]): RxTimePlanning[] {
        return timePlannings.map((timePlanning: TimePlanning) => this.toRx(timePlanning));
    }

    private toRx(timePlanning: TimePlanning): RxTimePlanning {
        return {
            ...timePlanning,
            sports$: timePlanning.sportsIds.map((sportId) =>
                of({}).pipe(switchMap(() => this.sportService.loadById(sportId)))
            ),
        };
    }

    getEmpty(): Observable<TimePlanning> {
        const timePlanning = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            sportsIds: [],
            createdAt: createDate(),
        };

        return of(timePlanning);
    }
}
