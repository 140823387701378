import { SERIES_FOR_REPS_AND_REST_TYPE, TrainingSequence } from '../training-sequence.model';
import { ScalingSequence } from '../scaling-sequence.model';
import { TrainingExerciseRestriction } from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import {
    distanceMeasureTemplate,
    repetitionMeasureTemplate,
    serieMeasureTemplate,
    timeMeasureTemplate,
    weightMeasureTemplate,
} from '../../exercise-template/measure-template.model';
import { SequenceRestriction } from '../restriction/sequence-restriction.model';
import { MaxOneRoundPerSequenceRestriction } from '../restriction/max-one-round-per-sequence-restriction.model';
import { SeriesMeasureModeTrainingExerciseRestriction } from '../restriction/series-measure-mode-training-exercise-restriction.model';
import { Step } from '../step/step.model';
import { StepFactory } from '../../../service/training-sequence/step.factory';

export class SeriesForRepsAndRestTrainingSequence extends TrainingSequence {
    private series: number | null;

    constructor(
        id: string,
        name: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string,
        series: number | null
    ) {
        super(id, name, SERIES_FOR_REPS_AND_REST_TYPE, mode, scalingSequences, sessionSequenceId, notes);
        this.series = series;
    }

    _doAssert(): void {}

    public _sequenceRestrictions(): SequenceRestriction[] {
        return [new MaxOneRoundPerSequenceRestriction(), new SeriesMeasureModeTrainingExerciseRestriction()];
    }

    public _trainingExerciseRestriction(): TrainingExerciseRestriction | null {
        return {
            availableExerciseMeasureTemplates: [
                timeMeasureTemplate(),
                distanceMeasureTemplate(),
                repetitionMeasureTemplate(),
            ],
            availableMeasureModeTemplates: [serieMeasureTemplate()],
            weightMeasureTemplate: weightMeasureTemplate(),
        };
    }

    _clone(): TrainingSequence {
        const scalingSequences = this.scalingSequences.map((scalingSequence) => scalingSequence.clone());

        return new SeriesForRepsAndRestTrainingSequence(
            this.getId(),
            this.getName(),
            this.getMode(),
            scalingSequences,
            this.getSessionSequenceId(),
            this.getNotes(),
            this.series
        );
    }

    _canShowStep(stepLinkedId: string): boolean {
        const steps = this._stepsByLinkedId(stepLinkedId);
        return steps.length !== 0 && steps.filter((step) => step.isRound()).length === 0;
    }

    _canAddExerciseInOnlyOneRound(): boolean {
        return false;
    }

    _allRoundsShouldHaveSameSteps(): boolean {
        return false;
    }

    _isExerciseMeasureModeRequired(): boolean {
        return false;
    }

    _addRestAfterExercise(): boolean {
        return false;
    }

    getSeries(): number | null {
        return this.series;
    }

    _addStep(step: Step, index: number | null = null): void {
        super._addStep(step, index);

        const restIndex = index ? index + 1 : null;
        const rest = StepFactory.newInstance().createRest();

        super._addStep(rest, restIndex);
    }

    updateSeries(series: number): void {
        if (series < 1) {
            return;
        }

        this.series = series;
    }

    _canCreateComplex(): boolean {
        return false;
    }

    public _doSanetize(): void {}

    public _canAddBuyIn(): boolean {
        return false;
    }

    public _canAddBuyOut(): boolean {
        return false;
    }
}
