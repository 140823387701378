import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DefinitionState, STORE_KEY } from './user-subscription.reducer';

export const getUserSubscriptionState = createFeatureSelector<DefinitionState>(STORE_KEY);

const selectUserSubscriptions = (state: DefinitionState) => state.userSubscriptions;

export const getByLeetUserId = createSelector(getUserSubscriptionState, (state: DefinitionState, leetUserId: string) =>
    selectUserSubscriptions(state).find(
        (userSubscriptionNode) => userSubscriptionNode.userReference.entityId === leetUserId
    )
);
