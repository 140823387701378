import {
    DifficultyLevel,
    Location,
    Material,
    MuscleGroup,
    Objective,
    SessionSequence,
    Sport,
    TimePlanning,
    TrainingDuration,
} from '../../model/definition/definition.models';
import { Action, createReducer, on } from '@ngrx/store';
import * as SportAction from './sport.actions';
import * as LocationAction from './location.actions';
import * as MuscleAction from './muscle-group.actions';
import * as SessionAction from './session-sequence.actions';
import * as PlanningAction from './time-planing.actions';
import * as DifficultyAction from './difficulty-level.actions';
import * as MaterialAction from './material.actions';
import * as ObjectiveAction from './objective.actions';
import * as TrainingDurationAction from './training-duration.actions';
import { clearState, initDefinitionState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'leet-item';

export interface DefinitionState {
    sports: Sport[];
    locations: Location[];
    difficulties: DifficultyLevel[];
    materials: Material[];
    muscleGroups: MuscleGroup[];
    objectives: Objective[];
    sessionSequences: SessionSequence[];
    timePlannings: TimePlanning[];
    trainingDurations: TrainingDuration[];
}

export const initialState: DefinitionState = {
    sports: [],
    locations: [],
    difficulties: [],
    materials: [],
    muscleGroups: [],
    objectives: [],
    sessionSequences: [],
    timePlannings: [],
    trainingDurations: [],
};

const updateSportList = (state, { sports }) => {
    return {
        ...state,
        sports,
    };
};

const addSport = (state, sport) => {
    return {
        ...state,
        sports: [...state.sports, sport],
    };
};

const loadSport = (state, { sport }) => {
    const isSportPreviouslyStored = !!state.sports.find((sportNode) => sportNode.id === sport.id);
    if (isSportPreviouslyStored) return updateSport(state, { sport });
    return addSport(state, sport);
};

const updateSport = (state, { sport }) => {
    return {
        ...state,
        sports: state.sports.map((sportNode) => (sportNode.id === sport.id ? sport : sportNode)),
    };
};

const updateLocationList = (state, { locations }) => {
    return {
        ...state,
        locations,
    };
};

const updateLocation = (state, { location }) => {
    let existsLocation = false;

    const locations = state.locations.map((storeLocation: Location) => {
        if (storeLocation.id === location.id) {
            existsLocation = true;
            return location;
        }
        return storeLocation;
    });

    if (!existsLocation) {
        locations.unshift(location);
    }

    return {
        ...state,
        locations,
    };
};

const updateMuscleGroupList = (state, { muscleGroups }) => {
    return {
        ...state,
        muscleGroups,
    };
};

const updateMuscleGroup = (state, { muscleGroup }) => {
    let existsMuscleGroup = false;

    const muscleGroups = state.muscleGroups.map((storeMuscleGroup: MuscleGroup) => {
        if (storeMuscleGroup.id === muscleGroup.id) {
            existsMuscleGroup = true;
            return muscleGroup;
        }
        return storeMuscleGroup;
    });

    if (!existsMuscleGroup) muscleGroups.unshift(muscleGroup);

    return {
        ...state,
        muscleGroups,
    };
};

const updateSessionSequenceList = (state, { sessionSequences }) => {
    return {
        ...state,
        sessionSequences,
    };
};

const updateSessionSequence = (state, { sessionSequence }) => {
    let existsLocation = false;

    const sessionSequences = state.sessionSequences.map((storeSequence: Location) => {
        if (storeSequence.id === sessionSequence.id) {
            existsLocation = true;
            return sessionSequence;
        }
        return storeSequence;
    });

    if (!existsLocation) sessionSequences.unshift(sessionSequence);

    return {
        ...state,
        sessionSequences,
    };
};

const updateTimePlanningList = (state, { timePlannings }) => {
    return {
        ...state,
        timePlannings,
    };
};

const updateTimePlanning = (state, { timePlanning }) => {
    let existsTimePlanning = false;

    const timePlannings = state.timePlannings.map((storeTimePlanning: TimePlanning) => {
        if (storeTimePlanning.id === timePlanning.id) {
            existsTimePlanning = true;
            return timePlanning;
        }
        return storeTimePlanning;
    });

    if (!existsTimePlanning) {
        timePlannings.unshift(timePlanning);
    }

    return {
        ...state,
        timePlannings,
    };
};

const updateDifficultyLevelList = (state, { difficulties }) => {
    return {
        ...state,
        difficulties,
    };
};

const updateDifficultyLevel = (state, { difficultyLevel }) => {
    let existsDifficulty = false;

    const difficulties = state.difficulties.map((storeDifficulty: Location) => {
        if (storeDifficulty.id === difficultyLevel.id) {
            existsDifficulty = true;
            return difficultyLevel;
        }
        return storeDifficulty;
    });

    if (!existsDifficulty) difficulties.unshift(difficultyLevel);

    return {
        ...state,
        difficulties,
    };
};

const updateMaterialList = (state, { materials }) => {
    return {
        ...state,
        materials,
    };
};

const updateMaterial = (state, { material }) => {
    let existsMaterial = false;

    const materials = state.materials.map((storeMaterial: Material) => {
        if (storeMaterial.id === material.id) {
            existsMaterial = true;
            return material;
        }
        return storeMaterial;
    });

    if (!existsMaterial) {
        materials.unshift(material);
    }

    return {
        ...state,
        materials,
    };
};

const updateObjectiveList = (state, { objectives }) => {
    return {
        ...state,
        objectives,
    };
};

const updateObjective = (state, { objective }) => {
    let existsObjective = false;

    const objectives = state.objectives.map((storeObjective: Objective) => {
        if (storeObjective.id === objective.id) {
            existsObjective = true;
            return objective;
        }
        return storeObjective;
    });

    if (!existsObjective) objectives.unshift(objective);

    return {
        ...state,
        objectives,
    };
};

const updateTrainingDurationList = (state, { trainingDurations }) => {
    return {
        ...state,
        trainingDurations,
    };
};

const updateTrainingDuration = (state, { trainingDuration }) => {
    let existsTrainingDuration = false;

    const trainingDurations = state.trainingDurations.map((storeTrainingDuration: TrainingDuration) => {
        if (storeTrainingDuration.id === trainingDuration.id) {
            existsTrainingDuration = true;
            return trainingDuration;
        }
        return storeTrainingDuration;
    });

    if (!existsTrainingDuration) trainingDurations.unshift(trainingDuration);

    return {
        ...state,
        trainingDurations,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action): any {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initDefinitionState, initState),
        on(SportAction.loadAllSports, updateSportList),
        on(SportAction.loadSport, loadSport),
        on(LocationAction.loadAll, updateLocationList),
        on(LocationAction.load, updateLocation),
        on(MuscleAction.loadAll, updateMuscleGroupList),
        on(MuscleAction.load, updateMuscleGroup),
        on(SessionAction.loadAll, updateSessionSequenceList),
        on(SessionAction.load, updateSessionSequence),
        on(PlanningAction.loadAll, updateTimePlanningList),
        on(PlanningAction.load, updateTimePlanning),
        on(DifficultyAction.loadAll, updateDifficultyLevelList),
        on(DifficultyAction.load, updateDifficultyLevel),
        on(MaterialAction.loadAll, updateMaterialList),
        on(MaterialAction.load, updateMaterial),
        on(ObjectiveAction.loadAll, updateObjectiveList),
        on(ObjectiveAction.load, updateObjective),
        on(TrainingDurationAction.load, updateTrainingDuration),
        on(TrainingDurationAction.loadAll, updateTrainingDurationList)
    );

    return definitionReducer(state, action);
}
