import { Action, createReducer, on } from '@ngrx/store';
import * as ClubAction from './club.actions';
import { Club } from '../../model/club/club.model';
import { clearState, initClubState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'club';

export interface DefinitionState {
    clubs: Club[];
}

export const initialState: DefinitionState = {
    clubs: [],
};

const updateClub = (state, { club }) => {
    if (!club) {
        return {
            ...state,
            clubs: state.clubs,
        };
    }

    let existsClub = false;

    const clubs = state.clubs.map((storeClub: Club) => {
        if (storeClub.id === club.id) {
            existsClub = true;
            return club;
        }
        return storeClub;
    });

    if (!existsClub) {
        clubs.unshift(club);
    }

    return {
        ...state,
        clubs,
    };
};

const updateClubsList = (state, { clubs }) => {
    return {
        ...state,
        clubs,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initClubState, initState),
        on(ClubAction.load, updateClub),
        on(ClubAction.loadAll, updateClubsList)
    );

    return definitionReducer(state, action);
}
