import { Action, createReducer, on } from '@ngrx/store';
import { TrainingTemplate } from '../../model/training/training-template.model';
import * as TrainingTemplateActions from './training-template.actions';
import { clearState, initTrainingTemplateState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'training-template-templates';

export interface DefinitionState {
    trainingTemplates: TrainingTemplate[];
}

export const initialState: DefinitionState = {
    trainingTemplates: [],
};

const removeTrainingTemplate = (state, { id }) => {
    return {
        ...state,
        trainingTemplates: state.trainingTemplates.filter((trainingTemplate) => trainingTemplate.id !== id),
    };
};

const updateTrainingTemplatesList = (state, { trainingTemplates }) => {
    let finalTrainingTemplates = [];
    trainingTemplates.forEach((trainingTemplateNode) => {
        finalTrainingTemplates = addOrUpdateTrainingTemplate(trainingTemplateNode, finalTrainingTemplates);
    });
    return {
        ...state,
        trainingTemplates: [...finalTrainingTemplates],
    };
};

const updateTrainingTemplate = (state, { trainingTemplate }) => {
    return {
        ...state,
        trainingTemplates: addOrUpdateTrainingTemplate(trainingTemplate, state.trainingTemplates),
    };
};

const addOrUpdateTrainingTemplate = (trainingTemplate: TrainingTemplate, trainingTemplatesList: TrainingTemplate[]) => {
    let existsTrainingTemplate = false;

    trainingTemplatesList = trainingTemplatesList.map((savedTrainingTemplate) => {
        if (savedTrainingTemplate.getId() === trainingTemplate.getId()) {
            existsTrainingTemplate = true;
            return trainingTemplate;
        }

        return savedTrainingTemplate;
    });

    if (!existsTrainingTemplate) trainingTemplatesList.unshift(trainingTemplate);

    return trainingTemplatesList;
};

// const initState = (state, newState) => {
//     if (!newState.trainingTemplates) return state;
//     return {
//         ...state,
//         trainingTemplates: [
//             ...newState.trainingTemplates.map((plainTrainingTemplate) =>
//                 plainToClass(TrainingTemplate, plainTrainingTemplate)
//             ),
//         ],
//     };
// };

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initTrainingTemplateState, initState),
        on(TrainingTemplateActions.loadAll, updateTrainingTemplatesList),
        on(TrainingTemplateActions.load, updateTrainingTemplate),
        on(TrainingTemplateActions.remove, removeTrainingTemplate)
    );

    return definitionReducer(state, action);
}
