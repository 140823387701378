import {Component, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {Modal} from '../../../../../../../shared/shared/service/ionic/modal.ionic';

@Component({
    selector: 'app-swipe-bar',
    template: `
        <div class="swipe-bar">
            <div class="swipe-bar__line"></div>
        </div>
        <ion-buttons class="flex justify-content-end pr-3"  slot="end">
            <ion-button *ngIf="isWeb()" (click)="dismissModal()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    `,
    styleUrls: ['./swipe-bar.component.scss'],
})
export class SwipeBarComponent implements OnInit {
    constructor(private modalController: Modal, private platform: Platform) {
    }

    ngOnInit(): void {
    }

    async dismissModal(): Promise<any> {
        await this.modalController.dismiss();
    }

    isWeb(): boolean {
        return !this.platform.is('hybrid');
    }
}
