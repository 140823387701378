import {createAction, props} from '@ngrx/store';
import {TimePlanning} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Time Planning] Load all',
    props<{ timePlannings: TimePlanning[] }>()
);

export const load = createAction(
    '[Time Planning] Load',
    props<{ timePlanning: TimePlanning }>()
);


