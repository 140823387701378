import {createAction, props} from '@ngrx/store';
import {DifficultyLevel} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Difficulty Level] Load all',
    props<{ difficulties: DifficultyLevel[] }>()
);

export const load = createAction(
    '[Difficulty Level] Load',
    props<{ difficultyLevel: DifficultyLevel }>()
);


