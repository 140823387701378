import { Action, createReducer, on } from '@ngrx/store';
import * as ExerciseTemplateAction from './exercise-template.actions';
import { ExerciseTemplate } from '../../model/exercise-template/exercise-template.model';
import { clearState, initExerciseTemplateState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'exercise-template';

export interface DefinitionState {
    exerciseTemplates: ExerciseTemplate[];
    queryFilters: QueryFilterResult[];
}

interface QueryFilterResult {
    serializedQuery: string;
    exerciseTemplates: ExerciseTemplate[];
}

export const initialState: DefinitionState = {
    exerciseTemplates: [],
    queryFilters: [],
};

const removeExerciseTemplate = (state, { id }) => {
    const queryFilters = state.queryFilters.map((queryFilterResult: QueryFilterResult) => {
        return {
            serializedQuery: queryFilterResult.serializedQuery,
            exerciseTemplates: queryFilterResult.exerciseTemplates.filter(
                (exerciseTemplate) => exerciseTemplate.id !== id
            ),
        };
    });

    return {
        ...state,
        exerciseTemplates: state.exerciseTemplates.filter((exerciseTemplate) => exerciseTemplate.id !== id),
        queryFilters,
    };
};

const updateExerciseTemplate = (state, { exerciseTemplate }) => {
    let existsExerciseTemplate = false;

    const exerciseTemplates = state.exerciseTemplates.map((storeExerciseTemplate: ExerciseTemplate) => {
        if (storeExerciseTemplate.id === exerciseTemplate.id) {
            existsExerciseTemplate = true;
            return exerciseTemplate;
        }
        return storeExerciseTemplate;
    });

    if (!existsExerciseTemplate) {
        exerciseTemplates.unshift(exerciseTemplate);
    }

    return {
        ...state,
        exerciseTemplates,
    };
};

const updateExerciseTemplatesList = (state, { exerciseTemplates }) => {
    return {
        ...state,
        exerciseTemplates,
    };
};

const updateDefaultExerciseTemplatesList = (state, { defaultExerciseTemplates }) => {
    const exerciseTemplates = [];

    state.exerciseTemplates.forEach((storeExerciseTemplate: ExerciseTemplate) => {
        if (storeExerciseTemplate.visibility !== 'public_visibility') {
            exerciseTemplates.push(storeExerciseTemplate);
            return;
        }

        const exerciseTemplate = findExerciseTemplateById(storeExerciseTemplate.id, defaultExerciseTemplates);

        if (exerciseTemplate) exerciseTemplates.push(exerciseTemplate);
    });

    const defaultExerciseTemplatesIds = getDefaultExerciseTemplatesIds(defaultExerciseTemplates);

    defaultExerciseTemplates.forEach((exerciseTemplate: ExerciseTemplate) => {
        if (!defaultExerciseTemplatesIds.includes(exerciseTemplate.id)) exerciseTemplates.unshift(exerciseTemplate);
    });

    return {
        ...state,
        exerciseTemplates,
    };
};

const findExerciseTemplateById = (exerciseTemplateId, exerciseTemplates) => {
    for (const item of exerciseTemplates) if (item.id === exerciseTemplateId) return item;

    return null;
};

const getDefaultExerciseTemplatesIds = (allExercisesTemplates: ExerciseTemplate[]) => {
    const exercisesTemplates = [];

    allExercisesTemplates.forEach((exerciseTemplate: ExerciseTemplate) => {
        if (exerciseTemplate.visibility !== 'public_visibility') return;

        exercisesTemplates.push(exerciseTemplate);
    });

    return exercisesTemplates;
};

const updateQueryFilter = (state, { serializedQuery, exerciseTemplates }) => {
    let existsQueryFilter = false;

    const queryFilters = state.queryFilters.map((storeQueryFilter: QueryFilterResult) => {
        if (storeQueryFilter.serializedQuery === serializedQuery) {
            existsQueryFilter = true;
            return {
                serializedQuery,
                exerciseTemplates,
            };
        }

        return storeQueryFilter;
    });

    if (!existsQueryFilter)
        queryFilters.push({
            serializedQuery,
            exerciseTemplates,
        });

    return {
        ...state,
        queryFilters,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action): any {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initExerciseTemplateState, initState),
        on(ExerciseTemplateAction.load, updateExerciseTemplate),
        on(ExerciseTemplateAction.loadAll, updateExerciseTemplatesList),
        on(ExerciseTemplateAction.loadAllDefault, updateDefaultExerciseTemplatesList),
        on(ExerciseTemplateAction.loadByQueryFilter, updateQueryFilter),
        on(ExerciseTemplateAction.remove, removeExerciseTemplate)
    );

    return definitionReducer(state, action);
}
