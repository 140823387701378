import { Injectable, Injector } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { LeetUser, RxLeetUser } from '../../model/user/leet-user.model';
import * as LeetUserSelector from '../../store/leet-user/leet-user.selectors';
import * as LeetUserAction from '../../store/leet-user/leet-user.actions';
import { areEquals } from '../../../shared/function/lowdash';
import memorize from 'memorize-decorator';
import { v4 as uuid4 } from 'uuid';
import { USER_REGISTER_TYPES } from '../../model/user/user.model';
import { UNKNOWN_GENDER } from '../../model/gender/gender.model';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { ServiceContainer } from '../reactive/service-container.service';
import { UpdateLeetUserProfile } from '../../command/user/leet-user.commands';

@Injectable({
    providedIn: 'root',
})
export class LeetUserService {
    readonly getLeetUserRoute = 'find-leet-user';
    readonly leetUserByEmailRoute = 'find-leet-users-by-user-email';
    readonly getByIdsRoute = 'find-by-ids';
    readonly createCoachClientRoute = 'create-coach-client';
    readonly bySportRoute = 'leetuser-by-sport';
    readonly updateLeetUserProfileRoute = 'update-leet-user-profile';

    constructor(private apiClient: ApiClient, private store: Store, private injector: Injector) {}

    @memorize({ ttl: 1000 })
    load(leetUserId: string): Observable<RxLeetUser> {
        this.apiClient
            .get<any>(this.getLeetUserRoute, { leetUserId })
            .toPromise()
            .then((leetUser) => {
                this.store.dispatch(LeetUserAction.load({ leetUser }));
            });

        return this.get(leetUserId);
    }

    @memorize({ ttl: 1000 })
    async loadByEmail(email: string): Promise<RxLeetUser> {
        const leetUser = await this.apiClient
            .get<any>(this.leetUserByEmailRoute, { email })
            .toPromise();
        this.store.dispatch(LeetUserAction.load({ leetUser }));
        return this.toRx(leetUser);
    }

    @memorize({ ttl: 5000 })
    loadBySportsId(sportId: string): Observable<LeetUser[]> {
        this.apiClient
            .get<LeetUser[]>(this.bySportRoute, { id: sportId })
            .toPromise()
            .then((leetUsers) => this.store.dispatch(LeetUserAction.loadMany({ leetUsers })));

        return this.getBySportIds(sportId);
    }

    @memorize({ ttl: 5000 })
    loadByIds(ids: string[]): Observable<RxLeetUser[]> {
        this.apiClient
            .post<LeetUser[]>(this.getByIdsRoute, { ids })
            .toPromise()
            .then((leetUsers) => this.store.dispatch(LeetUserAction.loadMany({ leetUsers })));

        return this.getByIds(ids);
    }

    createCoachClient(
        email: string,
        username: string,
        clubId: string,
        sportId: string,
        coachLeetUserId: string,
        clientLeetUserId: string,
        password: string
    ): Observable<any> {
        const createPlayerUser = {
            userId: uuid4(),
            playerId: clientLeetUserId,
            plainPassword: password,
            email,
            username,
            sportId,
            registerType: USER_REGISTER_TYPES.DEFAULT,
            gender: { type: UNKNOWN_GENDER },
        };
        const addCoachClientToCoach = {
            clubId,
            coachLeetUserId,
            sportId,
            clientLeetUserId,
        };

        const postData = { createPlayerUser, addCoachClientToCoach };
        return this.apiClient.post(this.createCoachClientRoute, postData);
    }

    updateProfile(command: UpdateLeetUserProfile): Promise<any> {
        return this.apiClient
            .post(this.updateLeetUserProfileRoute, command, { leetUserId: command.leetUserId })
            .toPromise()
            .then(() => this.load(command.leetUserId));
    }

    public get(leetUserId: string): Observable<RxLeetUser> {
        return this.store
            .select(LeetUserSelector.get, leetUserId)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRx.bind(this))
            );
    }

    toRxs(leetUsers: LeetUser[]): RxLeetUser[] {
        return leetUsers.map((leetUser: LeetUser) => this.toRx(leetUser));
    }

    toRx(leetUser: LeetUser): RxLeetUser {
        if (!leetUser) return null;
        return {
            ...leetUser,
            user$: of({}).pipe(switchMap(() => this.injector.get(ServiceContainer).userService.load(leetUser.userId))),
            sport$: of({}).pipe(
                switchMap(() => this.injector.get(ServiceContainer).sportService.loadById(leetUser.sportId))
            ),
            // clubs$: leetUser.clubIds.map((clubId) => this.injector.get(ServiceContainer).clubService.load(clubId)),
            clubs$: [],
            clients: this.toRxs(leetUser.coachClients.clients),
            callToActions$: of({}).pipe(
                switchMap(() => this.injector.get(ServiceContainer).callToActionService.loadByLeetUserId(leetUser.id))
            ),
            userSubscription$: of({}).pipe(
                switchMap(() =>
                    this.injector.get(ServiceContainer).userSubscriptionService.loadByLeetUserId(leetUser.id)
                )
            ),
        };
    }

    public getByIds(ids: string[]): Observable<RxLeetUser[]> {
        return this.store
            .select(LeetUserSelector.getByIds, ids)
            .pipe(skipWhileNullOrUndefined(), map(this.toRxs.bind(this)));
    }

    private getBySportIds(sportId: string): Observable<RxLeetUser[]> {
        return this.store
            .select(LeetUserSelector.getBySportId, sportId)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this))
            );
    }
}
