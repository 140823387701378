import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionData } from './subscription-data.model';
import { v4 as uuid4 } from 'uuid';
import { RouteGenerator } from '../service/network/route-generator.service';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
    private subscriptionsData: SubscriptionData[] = [];

    ngOnDestroy(): void {
        this.allSubscriptions().forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    host(): string {
        return RouteGenerator.host();
    }

    protected subscribe<T>(
        observable: Observable<T>,
        next?: (value: T) => void,
        error?: (error: any) => void,
        complete?: () => void
    ): string {
        const id = uuid4();
        this.subscriptionsData.push({
            id,
            subscription: observable.subscribe(next, error, complete),
        });

        return id;
    }

    protected unsubscribe(subscriptionId: string): void {
        const subscription = this.subscriptionById(subscriptionId);

        if (!subscription || subscription.closed) return;

        subscription.unsubscribe();
    }

    protected allSubscriptions(): Subscription[] {
        return this.subscriptionsData.map((subscriptionData) => subscriptionData.subscription);
    }

    private subscriptionById(subscriptionId): Subscription | null {
        for (const item of this.subscriptionsData) if (item.id === subscriptionId) return item.subscription;

        return null;
    }
}
