import { Injectable } from '@angular/core';
import { MapperService } from '../mapper/mapper.service';
import { classToPlain } from 'class-transformer';

@Injectable({
    providedIn: 'root',
})
export class SerializerService {
    private static that;

    constructor(private mapperService: MapperService) {
        SerializerService.that = this;
    }

    public static newInstance(): SerializerService {
        return SerializerService.that;
    }

    public serialize(object: any): string {
        return JSON.stringify(object);
        return JSON.stringify(classToPlain(object));
    }

    public deserialize(data: string, objectClass: any = null): any {
        const deserializedData = JSON.parse(data);

        if (!objectClass) return deserializedData;

        return this.mapperService.map(deserializedData, objectClass);
    }
}
