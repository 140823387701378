import { Action, createReducer, on } from '@ngrx/store';
import * as UserAction from './user.actions';
import { User } from '../../model/user/user.model';
import { clearState, initUserState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'user';

export interface DefinitionState {
    users: User[];
}

export const initialState: DefinitionState = {
    users: [],
};

const updateUser = (state, { user }) => {
    return {
        ...state,
        users: addOrUpdateUserToList(user, state.users),
    };
};

const updateUsersList = (state, { users }) => {
    return {
        ...state,
        users,
    };
};

const updateUsersListByIds = (state, { users }) => {
    let newUsers = state.users;

    users.forEach((user: User) => {
        newUsers = addOrUpdateUserToList(user, newUsers);
    });

    return {
        ...state,
        users: newUsers,
    };
};

const addOrUpdateUserToList = (user, usersList) => {
    if (!user) {
        return usersList;
    }

    const users = [];
    let existsUser = false;

    usersList.forEach((savedUser: User) => {
        if (savedUser.id === user.id) {
            existsUser = true;
            users.push(user);
            return;
        }

        users.push(savedUser);
    });

    if (!existsUser) {
        users.unshift(user);
    }

    return users;
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initUserState, initState),
        on(UserAction.load, updateUser),
        on(UserAction.loadAll, updateUsersList),
        on(UserAction.loadByIds, updateUsersListByIds)
    );

    return definitionReducer(state, action);
}
