import { createSelector } from '@ngrx/store';
import { getDefinitionState } from './definition.selector';
import { DefinitionState } from './definition.reducer';
import { isPublicScope } from '../../model/scope/scope.models';

const selectMaterials = (state: DefinitionState) => state.materials;

export const getAll = createSelector(getDefinitionState, selectMaterials);

export const getScoped = createSelector(getDefinitionState, (state: DefinitionState, leetUserOwnerId: string) =>
    selectMaterials(state).filter((muscleNode) => {
        if (muscleNode.status === 'disabled') return false;
        if (muscleNode.scope.scope !== 'SCOPE_PRIVATE') return true;
        if (muscleNode.scope.leetUserOwnerId === leetUserOwnerId) return true;
        return false;
    })
);

export const getPublicAdminScoped = createSelector(getDefinitionState, (state: DefinitionState) =>
    selectMaterials(state).filter((muscleNode) => {
        if (muscleNode.status === 'disabled') return false;
        if (muscleNode.scope.scope === 'SCOPE_PUBLIC_ADMIN') return true;
    })
);

export const get = createSelector(getDefinitionState, (state: DefinitionState, id: string) =>
    selectMaterials(state).find((materialNode) => materialNode.id === id)
);

export const getPrivateScopedByCurrentUser = createSelector(
    getDefinitionState,
    (state: DefinitionState, leetUserOwnerId: string) =>
        selectMaterials(state).filter((material) => {
            if (material.status === 'disabled') return false;
            if (isPublicScope(material.scope.scope)) return true;
            return material.scope.leetUserOwnerId === leetUserOwnerId;
        })
);
