import { TrainingTemplate } from '../training/training-template.model';
import { TrainingSequence } from '../training-sequence/training-sequence.model';
import { Type } from 'class-transformer';
import { v4 as uuid4 } from 'uuid';
import { SelectedScalingSequence } from '../training-sequence/selected-scaling-sequence';

export class PlanTraining {
    private id: string;
    @Type(() => TrainingTemplate)
    private trainingTemplate: TrainingTemplate;
    private selectedScalingSequences: SelectedScalingSequence[];

    constructor(
        id: string,
        trainingTemplate: TrainingTemplate,
        planTrainingScalingSequences: SelectedScalingSequence[]
    ) {
        this.id = id;
        this.trainingTemplate = trainingTemplate;
        this.selectedScalingSequences = planTrainingScalingSequences;

        if (this.trainingTemplate) this.sanetize();
    }

    public getId(): string {
        return this.id;
    }

    public getTrainingTemplate(): TrainingTemplate {
        return this.trainingTemplate;
    }

    public getSelectedScalingSequences(): SelectedScalingSequence[] {
        return this.selectedScalingSequences;
    }

    public updatePlanTrainingScalingSequence(sequenceId: string, scalingSequenceId: string): void {
        const sequence = this.getTrainingSequence(sequenceId);

        if (!sequence) return;

        const scalingSequence = sequence._scalingSequenceById(scalingSequenceId);

        if (!scalingSequence) return;

        this.selectedScalingSequences = [
            ...this.selectedScalingSequences.filter(
                (planTrainingScalingSequence) => planTrainingScalingSequence.sequenceId !== sequenceId
            ),
            {
                sequenceId,
                scalingSequenceId,
            },
        ];
    }

    public sanetize(): void {
        if (this.selectedScalingSequences && this.selectedScalingSequences.length !== 0) {
            return;
        }

        this.selectedScalingSequences = this.trainingTemplate.getSequences().map((sequence) => {
            return {
                sequenceId: sequence.getId(),
                scalingSequenceId: sequence._getScalingSequences()[0].getId(),
            };
        });
    }

    public clone(replaceId = true): PlanTraining {
        const id = replaceId ? uuid4() : this.getId();

        return new PlanTraining(id, this.trainingTemplate, this.selectedScalingSequences);
    }

    private getTrainingSequence(sequenceId: string): TrainingSequence {
        for (const trainingSequence of this.trainingTemplate.getSequences())
            if (trainingSequence.getId() === sequenceId) return trainingSequence;

        return null;
    }
}
