import { Action, createReducer, on } from '@ngrx/store';
import * as PlanExecutionActions from './plan-execution.actions';
import { PlanExecution } from '../../model/plan-execution/plan-execution.model';
import { clearState, initPlanExecutionState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'plan-execution';

export interface DefinitionState {
    planExecutions: PlanExecution[];
}

export const initialState: DefinitionState = {
    planExecutions: [],
};

const updatePlayerPlanExecutions = (state, { planExecutions, playerId }) => {
    return {
        ...state,
        planExecutions: [
            ...state.planExecutions.filter((planExecution) => planExecution.getPlayerId() !== playerId),
            ...planExecutions,
        ],
    };
};

const updateCoachPlanExecutions = (state, { planExecutions, coachId }) => {
    return {
        ...state,
        planExecutions: [
            ...state.planExecutions.filter((planExecution) => planExecution.getCoachId() !== coachId),
            ...planExecutions,
        ],
    };
};

const updatePlanExecution = (state, { planExecution }) => {
    return {
        ...state,
        planExecutions: [
            ...state.planExecutions.filter(
                (savedPlanExecution) => savedPlanExecution.getId() !== planExecution.getId()
            ),
            planExecution,
        ],
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initPlanExecutionState, initState),
        on(PlanExecutionActions.loadByPlayer, updatePlayerPlanExecutions),
        on(PlanExecutionActions.loadByCoach, updateCoachPlanExecutions),
        on(PlanExecutionActions.load, updatePlanExecution)
    );

    return definitionReducer(state, action);
}
