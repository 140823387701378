export const ROUND_STEP_TYPE = 'round';
export const REST_STEP_TYPE = 'rest';
export const TRAINING_EXERCISE_STEP_TYPE = 'exercise';
export const COMPLEX_START_STEP_TYPE = 'complex_start';
export const COMPLEX_FINISH_STEP_TYPE = 'complex_finish';
export const BUY_IN_START_STEP_TYPE = 'buy_in_start';
export const BUY_IN_FINISH_STEP_TYPE = 'buy_in_finish';
export const BUY_OUT_START_STEP_TYPE = 'buy_out_start';
export const BUY_OUT_FINISH_STEP_TYPE = 'buy_out_finish';

export enum StepTypes {
    round = 'round',
    rest = 'rest',
    exercise = 'exercise',
    complex_start = 'complex_start',
    complex_finish = 'complex_finish',
    buy_in_start = 'buy_in_start',
    buy_in_finish = 'buy_in_finish',
    buy_out_start = 'buy_out_start',
    buy_out_finish = 'buy_out_finish',
}
