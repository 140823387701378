import { ContainerStep, Step } from './step.model';
import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';

export class BuyOutStart extends ContainerStep {
    private numberOfRounds: number | null;

    constructor(id: string, linkedId: string, numberOfRounds: number | null) {
        super(id, linkedId, StepTypes.BUY_OUT_START_STEP_TYPE);
        this.numberOfRounds = numberOfRounds;

        this.assert();
    }

    public getNumberOfRounds(): number | null {
        return this.numberOfRounds;
    }

    public updateNumberOfRounds(numberOfRounds: number): void {
        this.numberOfRounds = numberOfRounds;
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): Step {
        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new BuyOutStart(uuid4(), linkedId, this.numberOfRounds);
    }

    getSpecificStepType(): Step {
        return this as BuyOutStart;
    }
}
