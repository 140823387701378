import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../shared/page/base-component.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-leet-dropdown-select',
    template: `
        <div class="position-relative cursor-pointer">
            <ion-item (click)="toggleDropdown()" class="d-flex align-items-center">
                <ion-label position="floating">{{ label }}</ion-label>
                <ion-input
                    [readonly]="true"
                    [value]="this.inputValue"
                    (keydown)="$event.preventDefault()"
                    class="cursor-pointer"
                ></ion-input>
                <ion-icon name="caret-down-outline" class="dropdown-icon" slot="end"></ion-icon>
            </ion-item>
            <div
                class="d-flex flex-column dropdown-container"
                *ngIf="isDropdownShown"
                [style.bottom]="showTopDropdown ? '65px' : 'unset'"
            >
                <ion-searchbar [(ngModel)]="searchValue" placeholder="Buscar..."></ion-searchbar>
                <div class="dropdown-items-container">
                    <div
                        *ngFor="let element of allElements | dropdownSearchFilterPipe: searchValue"
                        class="dropdown-item d-flex justify-content-between align-items-center"
                        (click)="toggleElement(element.id)"
                    >
                        <span>{{ element.name | translate }}</span>
                        <ion-icon
                            name="checkmark-outline"
                            class="tick-icon"
                            *ngIf="selectedElementIds.includes(element.id)"
                        ></ion-icon>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./leet-dropdown-select.component.scss'],
})
export class LeetDropdownSelectComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() isMultiple;
    @Input() selectedElementIds;
    @Output() selectedElementIdsChange = new EventEmitter<string[]>();
    @Input() allElements = [];
    @Input() label = '';
    @Input() showTopDropdown = false;
    inputValue;
    isDropdownShown = false;
    searchValue = '';

    constructor(private eRef: ElementRef, private translateService: TranslateService) {
        super();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) this.hideDropdown();
    }

    ngOnInit(): void {
        this.renderInputValue();
    }

    ngOnChanges(): void {
        this.renderInputValue();
    }

    toggleDropdown() {
        this.isDropdownShown = !this.isDropdownShown;
    }

    hideDropdown() {
        this.isDropdownShown = false;
    }

    toggleElement(id) {
        if (this.selectedElementIds.includes(id))
            this.selectedElementIds = this.selectedElementIds.filter((elementId) => elementId !== id);
        else this.selectedElementIds = [...this.selectedElementIds, id];
        if (!this.isMultiple) this.selectedElementIds = this.selectedElementIds.filter((elementId) => elementId === id);

        this.selectedElementIdsChange.emit(this.selectedElementIds);
        this.renderInputValue();
    }

    async renderInputValue() {
        let value = '';
        for (const elementId of this.selectedElementIds) {
            const index = this.selectedElementIds.indexOf(elementId);
            const element = this.allElements.find((element) => element.id === elementId);
            if (index === this.selectedElementIds.length - 1) {
                value += await this.translateService.get(element.name).pipe(first()).toPromise();
                continue;
            }
            value += (await this.translateService.get(element.name).pipe(first()).toPromise()) + ', ';
        }
        this.inputValue = value;
    }
}
