import { ContainerStep, Step } from './step.model';
import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';

export class Round extends Step implements ContainerStep {
    private name: string;
    readonly isContainer: boolean = true;

    constructor(id: string, linkedId: string, name: string) {
        super(id, linkedId, StepTypes.ROUND_STEP_TYPE);

        this.name = name;

        this.assert();
    }

    public getTitle(): string {
        return this.name;
    }

    public updateName(name: string): void {
        this.name = name;
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): Step {
        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new Round(uuid4(), linkedId, this.name);
    }

    getSpecificStepType(): Step {
        return this as Round;
    }
}
