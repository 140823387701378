import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromDefinition from './store/definition/definition.reducer';
import * as fromTrainingSequence from './store/training-sequence/training-sequence.reducer';
import * as fromExerciseTemplate from './store/exercise-template/exercise-template.reducer';
import * as fromUser from './store/user/user.reducer';
import * as fromPlayer from './store/player/player.reducer';
import * as fromClub from './store/club/club.reducer';
import * as fromTrainingTemplate from './store/training-template/training-template.reducer';
import * as fromPlanTemplate from './store/plan-template/plan-template.reducer';
import * as fromTrainingExecution from './store/training-execution/training-execution.reducer';
import * as fromPlanExecution from './store/plan-execution/plan-execution.reducer';
import * as fromFile from './store/file/file.reducer';
import * as fromLeetUser from './store/leet-user/leet-user.reducer';
import * as fromChat from './store/chat/chat.reducer';
import * as fromLeetUserCallToActions from './store/call-to-action/call-to-action.reducer';
import * as fromCoachClientDatas from './store/coach-client-data/coach-client-data.reducer';
import * as fromUserSubscription from './store/subscription/user-subscription.reducer';
import * as fromTrainingExecutionListView from './store/training-execution-list-view/training-execution-list-view.reducer';
import * as fromExerciseTemplateListView from './store/exercise-template-list-view/exercise-template-list-view.reducer';
import { IonicModule } from '@ionic/angular';
import { ServiceContainer } from './service/reactive/service-container.service';
import { LeetInitializerService } from './service/initializer/leet-initializer.service';
import { LeetDropdownSelectComponent } from './component/leet-dropdown-select/leet-dropdown-select.component';
import { FormsModule } from '@angular/forms';
import { DropdownSearchFilterPipe } from './pipe/dropdown-element-search-filter.pipe';
import { LeetSearchableFilterInputSelectComponent } from './component/leet-searchable-filter-input-select/leet-searchable-filter-input-select.component';
import { LeetSearchableFilterInputSelectModalComponent } from './component/leet-searchable-filter-input-select-modal/leet-searchable-filter-input-select-modal.component';
import { SwipeBarComponent } from '../../apps/leet-coach/src/app/components/modals/swipe-bar/swipe-bar.component';

@NgModule({
    declarations: [
        LeetDropdownSelectComponent,
        DropdownSearchFilterPipe,
        LeetSearchableFilterInputSelectComponent,
        LeetSearchableFilterInputSelectModalComponent,
        SwipeBarComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        IonicModule,
        StoreModule.forFeature(fromDefinition.STORE_KEY, fromDefinition.reducer),
        StoreModule.forFeature(fromTrainingSequence.STORE_KEY, fromTrainingSequence.reducer),
        StoreModule.forFeature(fromExerciseTemplate.STORE_KEY, fromExerciseTemplate.reducer),
        StoreModule.forFeature(fromUser.STORE_KEY, fromUser.reducer),
        StoreModule.forFeature(fromPlayer.STORE_KEY, fromPlayer.reducer),
        StoreModule.forFeature(fromClub.STORE_KEY, fromClub.reducer),
        StoreModule.forFeature(fromTrainingTemplate.STORE_KEY, fromTrainingTemplate.reducer),
        StoreModule.forFeature(fromPlanTemplate.STORE_KEY, fromPlanTemplate.reducer),
        StoreModule.forFeature(fromTrainingExecution.STORE_KEY, fromTrainingExecution.reducer),
        StoreModule.forFeature(fromPlanExecution.STORE_KEY, fromPlanExecution.reducer),
        StoreModule.forFeature(fromFile.STORE_KEY, fromFile.reducer),
        StoreModule.forFeature(fromLeetUser.STORE_KEY, fromLeetUser.reducer),
        StoreModule.forFeature(fromChat.STORE_KEY, fromChat.reducer),
        StoreModule.forFeature(fromLeetUserCallToActions.STORE_KEY, fromLeetUserCallToActions.reducer),
        StoreModule.forFeature(fromCoachClientDatas.STORE_KEY, fromCoachClientDatas.reducer),
        StoreModule.forFeature(fromUserSubscription.STORE_KEY, fromUserSubscription.reducer),
        StoreModule.forFeature(fromTrainingExecutionListView.STORE_KEY, fromTrainingExecutionListView.reducer),
        StoreModule.forFeature(fromExerciseTemplateListView.STORE_KEY, fromExerciseTemplateListView.reducer),
        FormsModule,
    ],
    providers: [LeetInitializerService, ServiceContainer],
    exports: [
        LeetDropdownSelectComponent,
        LeetSearchableFilterInputSelectComponent,
        LeetSearchableFilterInputSelectModalComponent,
        SwipeBarComponent,
    ],
})
export class LeetModule {}
