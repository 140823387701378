import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {}

    async init(): Promise<void> {
        if (this._storage) return;
        this._storage = await this.storage.create();
    }

    public async set(key: string, value: any): Promise<any> {
        return this._storage?.set(key, value);
    }

    public async get(key: string): Promise<any> {
        return this._storage?.get(key);
    }

    public async remove(key: string): Promise<any> {
        return this._storage?.remove(key);
    }

    public async length(): Promise<number> {
        return this._storage?.length();
    }

    async clear(): Promise<void> {
        await this._storage?.clear();
    }
}
