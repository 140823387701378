import { Injectable, Injector } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import * as UserSubscriptionSelectors from '../../store/subscription/user-subscription.selectors';
import * as UserSubscriptionActions from '../../store/subscription/user-subscription.actions';
import { areEquals } from '../../../shared/function/lowdash';
import memorize from 'memorize-decorator';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { UserSubscription } from '../../model/subscription/user-subscription.model';

@Injectable({
    providedIn: 'root',
})
export class UserSubscriptionService {
    readonly getUserSubscriptionRoute = 'find-user-subscription-by-leet-user-id';
    readonly freeUserMaxTrainingsRoute = 'free-user-max-trainings';

    constructor(private apiClient: ApiClient, private store: Store, private injector: Injector) {}

    @memorize({ ttl: 5000 })
    loadByLeetUserId(leetUserId: string): Observable<UserSubscription> {
        this.apiClient
            .get<any>(this.getUserSubscriptionRoute, { leetUserId })
            .toPromise()
            .then((userSubscription) => {
                this.store.dispatch(UserSubscriptionActions.load({ userSubscription }));
            });

        return this.getByLeetUserId(leetUserId);
    }

    @memorize({ ttl: 10000 })
    freeUserMaxTrainings(): Observable<number> {
        return this.apiClient.get<number>(this.freeUserMaxTrainingsRoute);
    }

    public getByLeetUserId(leetUserId: string): Observable<UserSubscription> {
        return this.store
            .select(UserSubscriptionSelectors.getByLeetUserId, leetUserId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }
}
