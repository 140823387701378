import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { Observable } from 'rxjs';
import { User } from '../../model/user/user.model';
import * as UserAction from '../../store/user/user.actions';
import * as UserSelector from '../../store/user/user.selectors';
import { distinctUntilChanged, first, shareReplay, tap } from 'rxjs/operators';
import { areEquals } from '../../../shared/function/lowdash';
import memorize from 'memorize-decorator';
import { RouteGenerator } from '../../../shared/service/network/route-generator.service';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    readonly getRoute = 'find-user';
    readonly getByIdsRoute = 'find-users';
    readonly updateUserRoute = 'update-user';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private routeGenerator: RouteGenerator
    ) {}

    @memorize({ ttl: 5000 })
    loadByIds(userIds: string[]): Observable<User[]> {
        this.apiClient
            .post<User[]>(this.getByIdsRoute, { userIds })
            .toPromise()
            .then((users: User[]) => {
                this.store.dispatch(UserAction.loadByIds({ users }));
            });

        return this.store.select(UserSelector.getAll);
    }

    @memorize({ ttl: 5000 })
    load(userId: string): Observable<User> {
        this.apiClient
            .get<User>(this.getRoute, { userId })
            .toPromise()
            .then((user) => {
                this.store.dispatch(UserAction.load({ user }));
            });

        return this.get(userId);
    }

    updateUser(userId: string, email: string, username: string): Observable<any> {
        const postData = {
            email,
            username,
        };
        return this.apiClient.post(this.updateUserRoute, postData, {
            id: userId,
        });
    }

    @memorize({ ttl: 5000 })
    loadAll(): Observable<User[]> {
        this.apiClient
            .get<User[]>(this.getRoute)
            .toPromise()
            .then((users) => this.store.dispatch(UserAction.loadAll({ users })));

        return this.getAll();
    }

    get(id: string): Observable<User> {
        return this.store
            .select(UserSelector.get, id)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), skipWhileNullOrUndefined());
    }

    getAll(): Observable<User[]> {
        return this.store
            .select(UserSelector.getAll)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), skipWhileNullOrUndefined());
    }
}
