import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class NotificationService {
    constructor(private toastController: ToastController, private translator: TranslateService) {}

    public async showSavedNotification(): Promise<void> {
        const toast = await this.toastController.create({
            message: await this.translator.get('Saved').toPromise(),
            duration: 2000,
            buttons: [
                {
                    side: 'start',
                    icon: 'checkmark-outline',
                },
            ],
        });

        toast.present();
    }
}
