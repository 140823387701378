import { Action, createReducer, on } from '@ngrx/store';
import * as PlayerAction from './player.actions';
import { Player } from '../../model/user/player.model';
import { clearState, initPlayerState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'player';

export interface DefinitionState {
    players: Player[];
}

export const initialState: DefinitionState = {
    players: [],
};

const updatePlayer = (state, { player }) => {
    let existsPlayer = false;

    const players = state.players.map((storePlayer: Player) => {
        if (storePlayer.id === player.id) {
            existsPlayer = true;
            return player;
        }
        return storePlayer;
    });

    if (!existsPlayer) {
        players.unshift(player);
    }

    return {
        ...state,
        players,
    };
};

const updatePlayersList = (state, { players }) => {
    return {
        ...state,
        players,
    };
};

const updatePlayersListByUserId = (state, { players }) => {
    if (players.length === 0) {
        return;
    }

    const userId = players[0].userId;

    const newPlayers = state.players.filter((player: Player) => {
        return player.user.id !== userId;
    });

    players.forEach((player: Player) => {
        newPlayers.push(player);
    });

    return {
        ...state,
        players: newPlayers,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initPlayerState, initState),
        on(PlayerAction.load, updatePlayer),
        on(PlayerAction.loadAll, updatePlayersList),
        on(PlayerAction.loadByUserId, updatePlayersListByUserId)
    );

    return definitionReducer(state, action);
}
