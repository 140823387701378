import {createAction, props} from '@ngrx/store';
import {Video} from '../../model/file/video.models';
import {Image} from '../../model/file/image.models';
import { VoiceMessage } from '../../model/file/voice-message.model';

export const loadImages = createAction(
  '[File] Load Images',
  props<{ images: Image[] }>()
);

export const loadImage = createAction(
  '[File] Load Image',
  props<{ image: Image }>()
);

export const loadVideos = createAction(
  '[File] Load Videos',
  props<{ videos: Video[] }>()
);

export const loadVideo = createAction(
  '[File] Load Video',
  props<{ video: Video }>()
);

export const loadVoiceMessages = createAction(
    '[File] Load Voice messages',
    props<{ voiceMessages: VoiceMessage[] }>()
);

export const loadVoiceMessage = createAction(
    '[File] Load VoiceMessage',
    props<{ voiceMessage: VoiceMessage }>()
);
