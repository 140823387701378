import { ContainerStepExecution, StepExecution } from './step-execution';
import { ROUND_STEP_TYPE } from '../training-sequence/step/step.types';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';

export class RoundExecution extends ContainerStepExecution {
    private name: string;

    constructor(name: string, status: string, stepId: string) {
        super(status, stepId, ROUND_STEP_TYPE);
        this.name = name;
    }

    protected updateWithExecutionData(stepExecutionData: StepExecution | null): void {
        if (!stepExecutionData) return;

        const roundExecutionData = stepExecutionData as RoundExecution;

        this.name = roundExecutionData.name;
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            RoundExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getSpecificStepType(): RoundExecution {
        return this;
    }

    getTitle(): string {
        return this.name;
    }
}
