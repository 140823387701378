import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { Observable, of, throwError } from 'rxjs';
import { Material, RxMaterial } from '../../model/definition/definition.models';
import * as MaterialAction from '../../store/definition/material.actions';
import * as MaterialSelector from '../../store/definition/material.selectors';
import { CreateOrUpdateMaterial } from '../../command/definition/material.commands';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { catchError, distinctUntilChanged, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { createDate } from '../../model/date/leet-date';
import { SportService } from './sport.service';
import memorize from 'memorize-decorator';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { Session } from '../session/session.service';
import { sortByName } from '../../function/definition.operators';

@Injectable({
    providedIn: 'root',
})
export class MaterialService {
    readonly findRoute = 'api-v1-find-material';
    readonly updateRoute = 'api-v1-update-material';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private sportService: SportService,
        private session: Session
    ) {
    }

    loadAll(): Observable<RxMaterial[]> {
        this.apiClient
            .get<RxMaterial[]>(this.findRoute)
            .toPromise()
            .then((materials) => this.store.dispatch(MaterialAction.loadAll({ materials })));

        return this.getAll();
    }

    @memorize({ ttl: 1000 })
    load(id: string): Observable<RxMaterial> {
        this.apiClient
            .get<Material>(this.findRoute, { id })
            .toPromise()
            .then((material) => this.store.dispatch(MaterialAction.load({ material })));

        return this.getById(id);
    }

    getById(id: string): Observable<RxMaterial> {
        return this.store
            .select(MaterialSelector.get, id)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRx.bind(this))
            );
    }

    getAll(): Observable<RxMaterial[]> {
        return this.store
            .select(MaterialSelector.getAll)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this)),
                sortByName()
            );
    }

    getPrivateScoped(): Observable<RxMaterial[]> {
        return this.store
            .select(MaterialSelector.getScoped, this.session.currentAuthUser().entityId)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this))
            );
    }

    getPrivateScopedByCurrentUser(): Observable<Material[]> {
        return this.store
            .select(MaterialSelector.getPrivateScopedByCurrentUser, this.session.currentAuthUser().entityId)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this))
            );
    }

    getPublicAdminScoped(): Observable<RxMaterial[]> {
        return this.store
            .select(MaterialSelector.getPublicAdminScoped)
            .pipe(
                skipWhileNullOrUndefined(),
                distinctUntilChanged(areEquals),
                shareReplay(),
                map(this.toRxs.bind(this))
            );
    }

    getByIds(ids: string[]): Observable<RxMaterial[]> {
        return this.getAll().pipe(
            map((materials: RxMaterial[]) => materials.filter((material) => ids.includes(material.id)))
        );
    }

    update(command: CreateOrUpdateMaterial): Observable<EntityUpdated> {
        return this.apiClient
            .post<Material>(this.updateRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    toRxs(material: Material[]): RxMaterial[] {
        return material.map((location: Material) => this.toRx(location));
    }

    toRx(material: Material): RxMaterial {
        return {
            ...material,
            sports$: material.sportsIds.map((sportId) =>
                of({}).pipe(switchMap(() => this.sportService.loadById(sportId)))
            ),
        };
    }

    emptyMaterial(): Observable<RxMaterial> {
        const material: Material = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            sportsIds: [],
            scope: {
                scope: 'SCOPE_PUBLIC_ADMIN',
                leetUserOwnerId: '',
            },
            createdAt: createDate(),
        };
        return of(this.toRx(material));
    }
}
