import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { RouteGenerator } from '../network/route-generator.service';
import { LoginCommand } from '../../../leet/command/authentication/login.commands';
import { AuthUser, emptyAuthUser } from '../../model/authentication/auth-user.model';
import * as jwt_decode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { ApiClient } from '../network/api-client.service';
import { clearState } from '../../../leet/store/meta/meta.actions';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private readonly ROLE_ADMIN = 'ROLE_ADMIN';
    private readonly ROLE_PLAYER = 'ROLE_PLAYER';
    private readonly ROLE_COACH = 'ROLE_COACH';

    private readonly loginByClientInvitationTokenRoute = 'login-by-client-invitation-token';
    private readonly recoverPasswordRoute = 'recover-password';
    private changePasswordRoute = 'change-password';
    private loginByTokenRoute = 'login-by-token';
    private masterLoginRoute = 'master-login';

    constructor(
        private http: HttpClient,
        private router: RouteGenerator,
        private cacheService: CacheService,
        private apiClient: ApiClient,
        private store: Store
    ) {}

    login(command: LoginCommand): Observable<AuthUser | Error> {
        return this.http.post<any>(this.router.loginUrl(), command).pipe(
            map((data) => {
                if (!data || !data.token) return null;
                const authUser = this.tokenDataToAuthUser(data);
                this.cacheService.loadSessionUser(authUser);
                return authUser;
            }),
            catchError((error: Error) => {
                return throwError(error);
            })
        );
    }

    masterLogin(command: LoginCommand): Observable<AuthUser | Error> {
        return this.apiClient.post<any>(this.masterLoginRoute, command).pipe(
            map((data) => {
                if (!data || !data.token) return null;
                const authUser = this.tokenDataToAuthUser(data);
                this.cacheService.loadSessionUser(authUser);
                return authUser;
            }),
            catchError((error: Error) => {
                return throwError(error);
            })
        );
    }

    loginByToken(token: string): Observable<AuthUser | Error> {
        return this.http
            .post<any>(
                `${RouteGenerator.host()}/api/v1/${this.loginByTokenRoute}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .pipe(
                map((data) => {
                    if (!data || !data.token) return null;
                    const authUser = this.tokenDataToAuthUser(data);
                    this.cacheService.loadSessionUser(authUser);
                    return authUser;
                }),
                catchError((error: Error) => {
                    return throwError(error);
                })
            );
    }

    loginByClientInvitationToken(clientInvitationToken: string): Observable<AuthUser | Error> {
        return this.apiClient.post<any>(this.loginByClientInvitationTokenRoute, { clientInvitationToken }, { clientInvitationToken }).pipe(
            map((data) => {
                if (!data || !data.token) return null;
                const authUser = this.tokenDataToAuthUser(data);
                this.cacheService.loadSessionUser(authUser);
                return authUser;
            }),
            catchError((error: Error) => {
                console.error(error);
                return throwError(error);
            })
        );
    }

    recoverPassword(email: string, sportId: string): Observable<any> {
        return this.apiClient.post(this.recoverPasswordRoute, { email, sportId });
    }

    public renovateToken(): void {
        let authUser = this.cacheService.sessionUser();
        if (!authUser || !authUser.refreshToken) return;

        this.http
            .post<any>(this.router.renovateTokenUrl(), {
                refresh_token: authUser.refreshToken,
            })
            .pipe(
                tap((data) => {
                    if (!data || !data.token) return null;
                    authUser = this.tokenDataToAuthUser(data);
                    this.cacheService.loadSessionUser(authUser);
                })
            )
            .toPromise()
            .then(() => {});
    }

    logout(): void {
        this.cacheService.cleanCachedStoreState();
        this.cacheService.removeSessionUser();
        this.store.dispatch(clearState());
    }

    public getEmpty(): AuthUser {
        return emptyAuthUser();
    }

    public currentUser(): AuthUser | null {
        const authUser = this.cacheService.sessionUser();
        if (!authUser) return null;
        const expiresAt = new Date(authUser.expiresAtTimestamp * 1000);
        const now = new Date();
        const isExpired = now >= expiresAt;
        if (isExpired) return null;

        return authUser;
    }

    public isLogged(): boolean {
        return this.currentUser() !== null;
    }

    public isAdmin(): boolean {
        return this.currentUser() && this.currentUser().roles.includes(this.ROLE_ADMIN);
    }

    public isCoach(): boolean {
        return this.currentUser() && this.currentUser().roles.includes(this.ROLE_COACH);
    }

    public isPlayer(): boolean {
        return this.currentUser() && this.currentUser().roles.includes(this.ROLE_PLAYER);
    }

    changePassword(password: string, token: string): Observable<any> {
        return this.apiClient.post(this.changePasswordRoute, { password, token });
    }

    private tokenDataToAuthUser(data): AuthUser {
        const decodedToken = jwt_decode(data.token);
        return {
            publicId: decodedToken.publicId,
            expiresAtTimestamp: decodedToken.exp,
            roles: decodedToken.roles,
            refreshToken: data.refreshToken,
            token: data.token,
            entityId: data.entityId,
            entityType: data.entityType,
            userId: data.userId,
            id: data.id,
        };
    }
}
