import { Injectable } from '@angular/core';
import { LeetDate } from '../../model/date/leet-date';
import { Type } from 'class-transformer';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DefinitionMapper {
    constructor(private mapperService: MapperService) {}

    public hydrateDefinitions<T>(rawDefinitions: T[]): Observable<T[]> {
        return from(rawDefinitions).pipe(
            mergeMap((definition) => this.hydrateDefinition(definition)),
            toArray()
        );
    }

    public hydrateDefinition<T>(rawDefinition: T): Observable<T> {
        return of(rawDefinition).pipe(map((definition) => this.mapDefinition(definition)));
    }

    private mapDefinition<T>(definition: T): T {
        // @ts-ignore
        if (definition.createdAt) {
            // @ts-ignore
            definition.createdAt = new LeetDate(definition.createdAt);
        }

        return definition;
    }
}
//
// class DefinitionMappingObjects {
//     @Type(() => LeetDate)
//     createdAt: LeetDate;
// }
