import { Action, createReducer, on } from '@ngrx/store';
import * as ChatAction from './chat.actions';
import { Chat } from '../../model/chat/chat.model';
import { initState } from '../meta/meta.reducers';
import { clearState, initChatState } from '../meta/meta.actions';

export const STORE_KEY = 'chat';

export interface DefinitionState {
    chats: Chat[];
}

export const initialState: DefinitionState = {
    chats: [],
};

const updateChats = (state, { chats }) => {
    return {
        ...state,
        chats,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initChatState, initState),
        on(ChatAction.loadSession, updateChats)
    );

    return definitionReducer(state, action);
}
