import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor } from '../../../../shared/shared/service/authentication/jwt.interceptor';
import { UnauthorizedInterceptor } from '../../../../shared/shared/service/authentication/unauthorized.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { appConfig } from './app-config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { StorageService } from '../../../../shared/shared/service/cache/storage.service';
import { CacheService } from '../../../../shared/shared/service/cache/cache.service';
import { metaReducerFactory } from '../../../../shared/leet/store/meta/meta.reducers';
import { LeetModule } from '../../../../shared/leet/leet.module';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        LeetModule,
        BrowserModule,
        IonicModule.forRoot(appConfig),
        AppRoutingModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers: !environment.production ? [] : [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            }
        ),
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        FontAwesomeModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initAppFactory,
            deps: [StorageService, CacheService],
            multi: true,
        },
        {
            provide: META_REDUCERS,
            deps: [StorageService],
            useFactory: metaReducerFactory,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function initAppFactory(storageService: StorageService, cacheService: CacheService): () => Promise<void> {
    return async (): Promise<void> => {
        await storageService.init();
        await cacheService.initSessionUser();
    };
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, '../assets/trans/', '.json');
}
