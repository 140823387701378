import { TrainingSequenceDto } from './training-sequence-1-dto.model';
import { ScalingSequence } from './scaling-sequence.model';
import { Step } from './step/step.model';
import { Round } from './step/round.model';
import { SequenceRestriction } from './restriction/sequence-restriction.model';
import { ComplexStartAndFinishRestriction } from './restriction/complex-start-and-finish-restriction.model';
import { BuyInStartAndFinishRestriction } from './restriction/buy-in-start-and-finish-restriction.model';
import { BuyOutStartAndFinishRestriction } from './restriction/buy-out-start-and-finish-restriction.model';
import { AllRestAndExercisesAreInRoundRestriction } from './restriction/all-rest-and-exercises-are-in-round-restriction.model';
import { NoMultipleRestRestriction } from './restriction/no-multiple-rest-restriction.model';

export abstract class TrainingSequenceScalingAware extends TrainingSequenceDto {
    _deleteScalingSequence(scalingSequenceId: string): void {
        if (!this._canDeleteScalingSequence(scalingSequenceId)) return;

        this.scalingSequences = this.scalingSequences.filter(
            (scalingSequence) => scalingSequence.getId() !== scalingSequenceId
        );
    }

    _canDeleteScalingSequence(scalingSequenceId: string): boolean {
        return this.scalingSequences.length > 1 && this._scalingSequenceById(scalingSequenceId) !== null;
    }

    _canScalingSequenceBeRemoved(scalingSequenceId: string): boolean {
        return this.scalingSequences.length > 1;
    }

    _addScalingSequence(scalingSequence: ScalingSequence): void {
        if (this._existsScalingSequence(scalingSequence.getId())) return;

        this.scalingSequences.push(scalingSequence);
    }

    _removeScalingSequence(scalingSequenceId: string): void {
        if (!this._canScalingSequenceBeRemoved(scalingSequenceId)) return;

        this.scalingSequences = this.scalingSequences.filter(
            (scalingSequence) => scalingSequence.getId() !== scalingSequenceId
        );
    }

    _scalingSequenceById(scalingSequenceId: string): ScalingSequence | null {
        for (const scalingSequence of this.scalingSequences)
            if (scalingSequence.getId() === scalingSequenceId) return scalingSequence;

        return null;
    }

    mainScalingSequenceSteps(): Step[] {
        return this.mainScalingSequence().getSteps();
    }

    _firstScalingSequenceRoundSteps(): Round[] {
        return this.mainScalingSequenceSteps().filter((step) => step.isRound()) as Round[];
    }

    mainScalingSequence(): ScalingSequence | null {
        if (this.scalingSequences.length === 0) return null;

        return this.scalingSequences[0];
    }

    protected _existsScalingSequence(scalingSequenceId: string): boolean {
        return this._scalingSequenceById(scalingSequenceId) !== null;
    }

    protected _fistScalingSequenceStepsByLinkedId(linkedId: string): Step[] {
        return this.mainScalingSequenceSteps().filter((step) => step.getLinkedId() === linkedId);
    }

    protected _sharedSequenceRestrictions(): SequenceRestriction[] {
        return [
            new ComplexStartAndFinishRestriction(),
            new BuyInStartAndFinishRestriction(),
            new BuyOutStartAndFinishRestriction(),
            new AllRestAndExercisesAreInRoundRestriction(),
            new NoMultipleRestRestriction(),
        ];
    }

    protected _allSequenceRestrictions(): SequenceRestriction[] {
        return this._sharedSequenceRestrictions().concat(this._sequenceRestrictions());
    }

    abstract _sequenceRestrictions(): SequenceRestriction[];
}
