import { ContainerStep, Step } from './step.model';
import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';

export class BuyOutFinish extends ContainerStep {
    constructor(id: string, linkedId: string) {
        super(id, linkedId, StepTypes.BUY_OUT_FINISH_STEP_TYPE);

        this.assert();
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): Step {
        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new BuyOutFinish(uuid4(), linkedId);
    }

    getSpecificStepType(): Step {
        return this as BuyOutFinish;
    }
}
