import { MonoTypeOperatorFunction } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

export function skipWhileNullOrUndefined<T>(): MonoTypeOperatorFunction<T> {
    return skipWhile((value) => !value);
}

export function skipWhileEmptyArray<T>(): MonoTypeOperatorFunction<T[]> {
    return skipWhile((value) => value.length === 0);
}
