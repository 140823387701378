import { ActionStep, ContainerStep, Step } from './step/step.model';
import { TrainingSequenceLegacy } from './training-sequence-legacy.model';
import { ScalingSequence } from './scaling-sequence.model';

export const FREE_TYPE = 'free_training_sequence_type';
export const TABATA_TYPE = 'tabata_training_sequence_type';
export const AMRAP_TYPE = 'amrap_training_sequence_type';
export const TABATA_TENNIS_TYPE = 'tabata_tennis_training_sequence_type';
export const UNBROKEN_TYPE = 'unbroken_training_sequence_type';
export const TIME_STATIONS_TYPE = 'time_stations_training_sequence_type';
export const FOR_TIME_TYPE = 'for_time_training_sequence_type';
export const REPS_STATION_TYPE = 'reps_stations_training_sequence_type';
export const EMOM_TYPE = 'emom_training_sequence_type';
export const SERIES_FOR_TIME_AND_REST_TYPE = 'series_for_time_and_rest_training_sequence_type';
export const LADDER_FOR_TIME_TYPE = 'ladder_for_time_training_sequence_type';
export const SERIES_FOR_REPS_AND_REST_TYPE = 'series_for_reps_and_rest_training_sequence_type';
export const ROUNDS_FOR_TIME_TYPE = 'rounds_for_time_training_sequence_type';

export abstract class TrainingSequence extends TrainingSequenceLegacy {
    addContainer(container: ContainerStep): void {
        this.scalingSequences.forEach((sequence) => {
            sequence.addContainer(container);
        });
    }

    addActionStep(step: ActionStep, containerId: string): void {
        const container = this.containerById(containerId);
        this.scalingSequences.forEach((sequence) => {
            sequence.addActionStep(step, container);
        });
    }

    deleteActionStep(stepId: string): void {
        this.scalingSequences.forEach((scalingSequence) => scalingSequence.deleteStep(stepId));
    }

    deleteContainerStep(stepId: string): void {
        this.scalingSequences.forEach((scalingSequence) => scalingSequence.deleteContainerStep(stepId));
    }

    actionStepsInContainer(containerId: string): ActionStep[] {
        const scalingSequence = this.scalingSequenceByContainerId(containerId);
        if (!scalingSequence) return [];

        return scalingSequence.actionStepsInContainer(containerId);
    }

    containerById(id: string): ContainerStep | undefined {
        return this.allContainers().find((container) => container.getId() === id);
    }

    allContainers(): ContainerStep[] {
        let containers: ContainerStep[];
        containers = this.scalingSequences.flatMap((sequence) => {
            return sequence.getSteps().filter((step) => step.isContainer);
        });

        return containers;
    }

    allActionSteps(): ActionStep[] {
        return this.scalingSequences.flatMap((sequence) => {
            return sequence.getSteps().filter((step) => !step.isContainer);
        });
    }

    replaceContainerActionSteps(containerId: string, steps: Step[]): void {
        const scalingSequence = this.scalingSequenceByContainerId(containerId);
        if (!scalingSequence) return;

        scalingSequence.replaceContainerActionSteps(containerId, steps);
    }

    stepContainerIndex(containerId: string): number {
        const scalingSequence = this.scalingSequenceByContainerId(containerId);
        if (!scalingSequence) return -1;

        return scalingSequence.stepIndexById(containerId);
    }

    roundIndex(containerId: string): number {
        const scalingSequence = this.scalingSequenceByContainerId(containerId);
        if (!scalingSequence) return -1;

        return scalingSequence.roundIndex(containerId);
    }

    nextRoundId(currentRoundId: string): string | null {
        const scalingSequence = this.scalingSequenceByContainerId(currentRoundId);
        if (!scalingSequence) return null;

        return scalingSequence.nextRoundId(currentRoundId);
    }

    previousRoundId(currentRoundId: string): string | null {
        const scalingSequence = this.scalingSequenceByContainerId(currentRoundId);
        if (!scalingSequence) return null;

        return scalingSequence.previousRoundId(currentRoundId);
    }

    private scalingSequenceByContainerId(containerId: string): ScalingSequence | null {
        return this.scalingSequences.find((scalingSequenceNode) => scalingSequenceNode.stepById(containerId));
    }
}

export function allTrainingSequenceTypes(): string[] {
    return [
        FREE_TYPE,
        TABATA_TYPE,
        AMRAP_TYPE,
        TABATA_TENNIS_TYPE,
        UNBROKEN_TYPE,
        TIME_STATIONS_TYPE,
        FOR_TIME_TYPE,
        REPS_STATION_TYPE,
        EMOM_TYPE,
        SERIES_FOR_TIME_AND_REST_TYPE,
        LADDER_FOR_TIME_TYPE,
        SERIES_FOR_REPS_AND_REST_TYPE,
        ROUNDS_FOR_TIME_TYPE,
    ];
}
