import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class MaxOneRoundPerSequenceRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let numberOfRounds = 0;
        let step: Step;

        for (step of steps) {
            if (!step.isRound()) {
                continue;
            }

            if (++numberOfRounds > 1) {
                return false;
            }
        }

        return numberOfRounds === 1;
    }
}
