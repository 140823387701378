import { Injectable } from '@angular/core';
import { Step } from '../../model/training-sequence/step/step.model';
import { ComplexStart } from '../../model/training-sequence/step/complex-start.model';
import { TrainingExercise } from '../../model/training-sequence/step/training-exercise.model';

@Injectable({
    providedIn: 'root',
})
export class TrainingSequenceStepsHelper {
    public static isStepInComplex(stepLinkedId: string, steps: Step[]): boolean {
        let inComplex = false;

        for (const step of steps) {
            if (step.getLinkedId() === stepLinkedId)
                return inComplex || step.isComplexStart() || step.isComplexFinish();

            if (step.isComplexStart()) inComplex = true;
            else if (step.isComplexFinish()) inComplex = false;
        }

        return false;
    }

    public static stepsRemovingComplex(complexStartLinkedId: string, steps: Step[]): Step[] {
        let inComplex = false;
        let complexSeries = null;
        const newSteps = [];

        steps.forEach((step) => {
            if (step.isComplexStart() || step.isComplexFinish()) {
                inComplex = step.isComplexStart() && step.getLinkedId() === complexStartLinkedId;
                complexSeries = step.isComplexStart() ? (step as ComplexStart).getSeries() : null;
                return;
            }

            const newStep = step.clone();

            if (inComplex && newStep.isExercise())
                (newStep as TrainingExercise).updateMeasureModeUnitValue(complexSeries);

            newSteps.push(newStep);
        });

        return newSteps;
    }

    public static getStepsInsideRound(roundLinkedId: string, steps: Step[]): Step[] {
        let inRound = false;
        const newSteps = [];

        for (const step of steps) {
            if (step.isRound()) {
                if (inRound) break;

                if (step.getLinkedId() === roundLinkedId) {
                    inRound = true;
                    newSteps.push(step);
                    continue;
                }

                inRound = false;
                continue;
            }

            if (!inRound) continue;

            if (
                TrainingSequenceStepsHelper.isStepInBuyIn(step.getLinkedId(), steps) ||
                TrainingSequenceStepsHelper.isStepInBuyOut(step.getLinkedId(), steps)
            )
                break;

            newSteps.push(step);
        }

        return newSteps;
    }

    public static isStepInBuyIn(stepLinkedId: string, steps: Step[]): boolean {
        if (!steps[0].isBuyInStart()) return false;

        for (const step of steps) {
            if (step.getLinkedId() === stepLinkedId) return true;

            if (step.isBuyInFinish()) return false;
        }

        return false;
    }

    public static isStepInBuyOut(stepLinkedId: string, steps: Step[]): boolean {
        const lastStep = steps[steps.length - 1];

        if (!lastStep.isBuyOutFinish()) return false;
        else if (lastStep.getLinkedId() === stepLinkedId) return true;

        for (let index = steps.length - 1; index >= 0; index--) {
            const step = steps[index];

            if (step.isBuyOutStart()) return step.getLinkedId() === stepLinkedId;

            if (step.getLinkedId() === stepLinkedId) return true;
        }

        return false;
    }

    public static stepsRemovingBuyInAndBuyOut(steps: Step[]): Step[] {
        const newSteps = [];

        steps.forEach((step) => {
            if (
                TrainingSequenceStepsHelper.isStepInBuyIn(step.getLinkedId(), steps) ||
                TrainingSequenceStepsHelper.isStepInBuyOut(step.getLinkedId(), steps)
            )
                return;

            newSteps.push(step);
        });

        return newSteps;
    }

    public static getStepsInBuyIn(steps: Step[]): Step[] {
        const newSteps = [];

        steps.forEach((step) => {
            if (!TrainingSequenceStepsHelper.isStepInBuyIn(step.getLinkedId(), steps)) return;

            newSteps.push(step);
        });

        return newSteps;
    }

    public static getStepsInBuyOut(steps: Step[]): Step[] {
        const newSteps = [];

        steps.forEach((step) => {
            if (!TrainingSequenceStepsHelper.isStepInBuyOut(step.getLinkedId(), steps)) return;

            newSteps.push(step);
        });

        return newSteps;
    }
}
