import { ActionStepExecution, StepExecution } from './step-execution';
import { TRAINING_EXERCISE_STEP_TYPE } from '../training-sequence/step/step.types';
import { ExerciseTemplate } from '../exercise-template/exercise-template.model';
import { Measure } from '../training-sequence/measure.model';
import { Type } from 'class-transformer';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';
import { MeasureUnit } from '../exercise-template/measure-unit.model';
import { MeasureTemplate, TIME_MEASURE } from '../exercise-template/measure-template.model';

export class ExerciseExecution extends ActionStepExecution {
    private exerciseTemplate: ExerciseTemplate;

    @Type(() => Measure)
    private weightMeasure: Measure | null;
    @Type(() => Measure)
    private measureMode: Measure | null;
    @Type(() => Measure)
    private exerciseMeasure: Measure | null;

    private measureModeUnitValue: number | null;
    private exerciseMeasureUnitValue: number | null;
    private weightMeasureUnitValue: number | null;
    private intraExerciseRestTime: number | null;

    constructor(
        exerciseTemplate: ExerciseTemplate,
        measureModeUnitValue: number | null,
        exerciseMeasureUnitValue: number | null,
        weightMeasureUnitValue: number | null,
        weightMeasure: Measure | null,
        measureMode: Measure | null,
        exerciseMeasure: Measure | null,
        status: string,
        stepId: string
    ) {
        super(status, stepId, TRAINING_EXERCISE_STEP_TYPE);

        this.exerciseTemplate = exerciseTemplate;
        this.measureModeUnitValue = measureModeUnitValue;
        this.exerciseMeasureUnitValue = exerciseMeasureUnitValue;
        this.weightMeasureUnitValue = weightMeasureUnitValue;
        this.weightMeasure = weightMeasure;
        this.measureMode = measureMode;
        this.exerciseMeasure = exerciseMeasure;
    }

    public getExerciseTemplate(): ExerciseTemplate {
        return this.exerciseTemplate;
    }

    public getExerciseTemplateId(): string {
        return this.exerciseTemplate.id;
    }

    public getMeasureModeUnitValue(): number | null {
        return this.measureModeUnitValue;
    }

    public getExerciseMeasureUnitValue(): number | null {
        return this.exerciseMeasureUnitValue;
    }

    getMeasureModeType(): string {
        return this.measureMode.getType();
    }

    getSpecificStepType(): ExerciseExecution {
        return this;
    }

    getExerciseMeasureType(): string {
        return this.exerciseMeasure.getType();
    }

    getExerciseMeasureUnitType(): string {
        return this.exerciseMeasure.getUnit().value;
    }

    hasWeightMeasure(): boolean {
        return this.weightMeasure !== null;
    }

    getWeightMeasureType(): string {
        if (!this.hasWeightMeasure()) return '';
        return this.weightMeasure.getType();
    }

    getWeightMeasureUnitValue(): number {
        return this.weightMeasureUnitValue;
    }

    getWeightMeasureUnitType(): string {
        return this.weightMeasure.getUnit().value;
    }

    updateWeightMeasureUnitValue(value: number): void {
        this.weightMeasureUnitValue = value;
    }

    updateWeightMeasureUnit(measureUnit: MeasureUnit): void {
        measureUnit = this.availableWeightMeasureUnits().find(
            (measureUnitNode) => measureUnitNode.value === measureUnit.value
        );

        if (!measureUnit) return;
        this.weightMeasure = new Measure(this.weightMeasure.getType(), measureUnit, this.weightMeasure.getGenderMode());
    }

    availableWeightMeasureUnits(): MeasureUnit[] {
        return this.exerciseTemplate.weightMeasureTemplate.units;
    }

    canChangeExerciseMeasureType(type: string): boolean {
        return (
            this.exerciseMeasure.getType() !== type &&
            this.availableMeasureTemplates().filter((measureTemplate) => measureTemplate.type === type).length === 1
        );
    }

    public availableMeasureTemplates(): MeasureTemplate[] {
        return this.exerciseTemplate.availableMeasureTemplates;
    }

    public changeExerciseMeasureType(type: string): void {
        if (!this.canChangeExerciseMeasureType(type)) return;

        const newMeasureTemplate: MeasureTemplate = this.availableMeasureTemplates().filter(
            (measureTemplate) => measureTemplate.type === type
        )[0];

        this.exerciseMeasure = new Measure(
            newMeasureTemplate.type,
            newMeasureTemplate.units[0],
            newMeasureTemplate.genderMode
        );
    }

    updateExerciseMeasureUnitValue(value: number): void {
        this.exerciseMeasureUnitValue = value;
    }

    public updateMeasureModeUnitValue(value): void {
        this.measureModeUnitValue = value;
    }

    updateIntraExerciseRestTime(intraExerciseRestTime: number) {
        this.intraExerciseRestTime = intraExerciseRestTime;
    }

    getIntraExerciseRestTime(): number {
        if (!this.intraExerciseRestTime) return 0;
        return this.intraExerciseRestTime;
    }

    updateExerciseMeasureUnit(measureUnit: MeasureUnit): void {
        measureUnit = this.availableCurrentExerciseMeasureUnits().find(
            (measureUnitNode) => measureUnitNode.value === measureUnit.value
        );
        if (!measureUnit) return;

        this.exerciseMeasure = new Measure(
            this.exerciseMeasure.getType(),
            measureUnit,
            this.exerciseMeasure.getGenderMode()
        );
    }

    public getMeasureMode(): Measure {
        return this.measureMode;
    }

    availableCurrentExerciseMeasureUnits(): MeasureUnit[] {
        const measureTemplates = this.availableMeasureTemplates();
        for (const measureTemplate of measureTemplates) {
            if (measureTemplate.type !== this.exerciseMeasure.getType()) continue;
            return measureTemplate.units;
        }
    }

    public hasNextMeasureMode(): boolean {
        return this.measureMode.getType() === TIME_MEASURE;
    }

    public hasNextExerciseMeasureTemplateType(): boolean {
        return this.remainingExerciseMeasureTemplates().length > 0;
    }

    public remainingExerciseMeasureTemplates(): MeasureTemplate[] {
        return this.availableMeasureTemplates().filter(
            (measureTemplate) => measureTemplate.type !== this.exerciseMeasure.getType()
        );
    }

    public getExerciseMeasure(): Measure {
        return this.exerciseMeasure;
    }

    public hasNextUnitInCurrentExerciseMeasure(): boolean {
        return this.availableCurrentExerciseMeasureUnits().length > 1;
    }

    public getWeightMeasure(): Measure | null {
        return this.weightMeasure;
    }

    public getWeightMaleMeasureUnitValue(): number {
        if (this.weightMeasureUnitValue === null) return 0;
        return this.weightMeasureUnitValue;
    }

    public hasNextUnitInCurrentWeightMeasure(): boolean {
        return (
            this.hasWeightMeasure() &&
            this.getNextWeightMeasure().getUnit().value !== this.weightMeasure.getUnit().value
        );
    }

    getTitle(): string {
        return this.exerciseTemplate.name;
    }

    protected updateWithExecutionData(stepExecutionData: StepExecution | null): void {
        if (!stepExecutionData) throw new Error();

        const exerciseTemplateExecutionData = stepExecutionData as ExerciseExecution;

        this.exerciseTemplate = exerciseTemplateExecutionData.exerciseTemplate;
        this.measureModeUnitValue = exerciseTemplateExecutionData.measureModeUnitValue;
        this.exerciseMeasureUnitValue = exerciseTemplateExecutionData.exerciseMeasureUnitValue;
        this.weightMeasureUnitValue = exerciseTemplateExecutionData.weightMeasureUnitValue;
        this.weightMeasure = exerciseTemplateExecutionData.weightMeasure;
        this.measureMode = exerciseTemplateExecutionData.measureMode;
        this.exerciseMeasure = exerciseTemplateExecutionData.exerciseMeasure;
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            ExerciseExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    private getNextWeightMeasure(): Measure {
        const availableWeightMeasureUnits = this.exerciseTemplate.weightMeasureTemplate.units.map((unit) => unit.value);

        const nextWeightMeasureIndex =
            (availableWeightMeasureUnits.indexOf(this.weightMeasure.getUnit().value) + 1) %
            availableWeightMeasureUnits.length;

        const measureUnit = {
            value: availableWeightMeasureUnits[nextWeightMeasureIndex],
        };

        return new Measure(this.weightMeasure.getType(), measureUnit, this.weightMeasure.getGenderMode());
    }
}
