import {createSelector} from '@ngrx/store';
import {getDefinitionState} from './definition.selector';
import {DefinitionState} from './definition.reducer';

const selectTrainingDurations = (state: DefinitionState) => state.trainingDurations;

export const getAll = createSelector(
    getDefinitionState, selectTrainingDurations
);

export const get = createSelector(
    getDefinitionState,
    (state: DefinitionState, id: string) => selectTrainingDurations(state).find((durationNode) => durationNode.id === id)
);
