import {createAction, props} from '@ngrx/store';
import { MuscleGroup} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Muscle] Load all',
    props<{ muscleGroups: MuscleGroup[] }>()
);

export const load = createAction(
    '[Muscle] Load',
    props<{ muscleGroup: MuscleGroup }>()
);


