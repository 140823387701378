import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouteGenerator } from './route-generator.service';

@Injectable({
    providedIn: 'root',
})
export class ApiClient {
    constructor(private http: HttpClient, private router: RouteGenerator) {}

    public get<T>(routeName: string, params: object | null = null): Observable<T> {
        const url = this.router.generate(routeName, params);
        return this.http.get<T>(url, {}).pipe(
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    public post<T>(routeName: string, body: object, params: object = {}): Observable<T> {
        const url = this.router.generate(routeName, params);
        return this.http.post<T>(url, body).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    public getFile(routeName: string, params: object | null = null): Observable<any> {
        const url = this.router.generate(routeName, params);
        return this.http.get(url, { responseType: 'blob' as 'json' }).pipe(
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
}
