import {createAction, props} from '@ngrx/store';
import {Player} from '../../model/user/player.model';

export const load = createAction(
    '[Player] Load',
    props<{ player: Player }>()
);

export const loadAll = createAction(
    '[Player] Load all',
    props<{ players: Player[] }>()
);


export const loadByIds = createAction(
    '[Player] Load by ids',
    props<{ players: Player[] }>()
);

export const loadByUserId = createAction(
    '[Player] Load by leetUserId',
    props<{ players: Player[]; userId: string; }>()
);


