import { Action, createReducer, on } from '@ngrx/store';
import * as UserSubscriptionAction from './user-subscription.actions';
import { clearState, initUserSubscriptionsState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';
import { UserSubscription } from '../../model/subscription/user-subscription.model';

export const STORE_KEY = 'user-subscription';

export interface DefinitionState {
    userSubscriptions: UserSubscription[];
}

export const initialState: DefinitionState = {
    userSubscriptions: [],
};

const loadUserSubscription = (state, { userSubscription }) => {
    if (!userSubscription) return state;
    const isUserSubscriptionInStore = !!state.userSubscriptions.find(
        (userSubscriptionNode) => userSubscriptionNode.id === userSubscription.id
    );
    if (isUserSubscriptionInStore) return updateUserSubscription(state, userSubscription);
    return addUserSubscription(state, userSubscription);
};

const addUserSubscription = (state, userSubscription) => {
    return {
        ...state,
        userSubscriptions: [...state.userSubscriptions, userSubscription],
    };
};

const updateUserSubscription = (state: DefinitionState, userSubscription: UserSubscription) => {
    return {
        ...state,
        userSubscriptions: [
            ...state.userSubscriptions.map((storeUserSubscription: UserSubscription) => {
                if (storeUserSubscription.id !== userSubscription.id) return storeUserSubscription;
                return userSubscription;
            }),
        ],
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initUserSubscriptionsState, initState),
        on(UserSubscriptionAction.load, loadUserSubscription)
    );

    return definitionReducer(state, action);
}
