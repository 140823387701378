import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, mergeMap, shareReplay, switchMap, tap, toArray } from 'rxjs/operators';
import * as TrainingExecutionAction from '../../store/training-execution/training-execution.actions';
import * as TrainingExecutionListViewAction from '../../store/training-execution-list-view/training-execution-list-view.actions';
import { STATUS_DELETED, TrainingExecution } from '../../model/training-execution/training-execution.model';
import * as TrainingExecutionSelector from '../../store/training-execution/training-execution.selectors';
import { areEquals } from '../../../shared/function/lowdash';
import {
    CreateTrainingExecution,
    DeleteTrainingExecution,
    FinishTrainingExecution,
    UpdateTrainingExecution,
} from '../../command/training-execution/training-execution.commands';
import { EntityCreated, EntityUpdated } from '../../../shared/model/network/network-models';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import {
    CountTrainingExecutionsByParameters,
    TrainingExecutionsByParameters,
} from '../../query/training-execution/training-execution.queries';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';

@Injectable({
    providedIn: 'root',
})
export class TrainingExecutionService {
    private readonly findRoute = 'find-training-execution';
    private readonly findByPlayerRoute = 'find-player-training-executions';
    private readonly createRoute = 'create-training-execution';
    private readonly finishRoute = 'finish-training-execution';
    private readonly deleteRoute = 'delete-training-execution';
    private readonly byParametersRoute = 'training-executions-by-parameters';
    private readonly countByParametersRoute = 'training-executions-count-by-parameters';
    private readonly execTodayRoute = 'player-training-executions-exec-today';
    private readonly updateRoute = 'update-training-execution';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private mapperService: MapperService
    ) {}

    load(id: string): Observable<TrainingExecution> {
        this.apiClient
            .get<TrainingExecution>(this.findRoute, { id })
            .toPromise()
            .then((trainingExecution) => {
                trainingExecution = this.mapperService.map(trainingExecution, TrainingExecution);
                this.store.dispatch(TrainingExecutionAction.load({ trainingExecution }));
            });

        return this.getById(id);
    }

    loadByPlayerId(playerId: string): Observable<TrainingExecution[]> {
        this.apiClient
            .get<TrainingExecution[]>(this.findByPlayerRoute, { playerId })
            .toPromise()
            .then((rawTrainingExecutions) => {
                const trainingExecutions = rawTrainingExecutions.map((rawTrainingExecution) =>
                    this.mapperService.map(rawTrainingExecution, TrainingExecution)
                );
                this.store.dispatch(TrainingExecutionAction.loadByPlayer({ trainingExecutions, playerId }));
            });

        return this.getByPlayerId(playerId);
    }

    create(command: CreateTrainingExecution): Observable<EntityCreated> {
        return this.apiClient
            .post<any>(this.createRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityCreated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    update(command: UpdateTrainingExecution): Observable<EntityUpdated> {
        return this.apiClient.post(this.updateRoute, command, { id: command.id }).pipe(
            mergeMap(() => {
                const response: EntityCreated = { id: command.id };
                return of(response);
            }),
            tap(() => {
                if (command.status === STATUS_DELETED)
                    this.store.dispatch(TrainingExecutionAction.remove({ id: command.id }));
                else this.load(command.id);
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    finish(command: FinishTrainingExecution): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.finishRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    delete(command: DeleteTrainingExecution): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.deleteRoute, command, { id: command.id })
            .pipe(
                switchMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.store.dispatch(TrainingExecutionAction.remove({ id: command.id }))),
                tap(() => this.store.dispatch(TrainingExecutionListViewAction.remove({ id: command.id }))),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    getById(id: string): Observable<TrainingExecution> {
        return this.store
            .select(TrainingExecutionSelector.get, id)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    getByPlayerId(playerId: string): Observable<TrainingExecution[]> {
        return this.store
            .select(TrainingExecutionSelector.getByPlayerId, playerId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    loadByParameters(query: TrainingExecutionsByParameters): Observable<TrainingExecution[]> {
        this.apiClient
            .post<TrainingExecution[]>(this.byParametersRoute, query)
            .pipe(mergeMap((rawTrainingExecutions) => this.hydrateTrainingExecutions(rawTrainingExecutions)))
            .toPromise()
            .then((trainingExecutions) =>
                this.store.dispatch(TrainingExecutionAction.loadByParametersSuccess({ trainingExecutions }))
            );

        return this.getByParameters(query);
    }

    countByParameters(query: CountTrainingExecutionsByParameters): Observable<number> {
        return this.apiClient.post<number>(this.countByParametersRoute, query);
    }

    getByParameters(query: TrainingExecutionsByParameters): Observable<TrainingExecution[]> {
        return this.store
            .select(TrainingExecutionSelector.getByParameters, query)
            .pipe(distinctUntilChanged(areEquals), shareReplay());
    }

    getExecToday(playerId: string | null): Observable<TrainingExecution[]> {
        const params = playerId ? { playerId } : {};

        return this.apiClient
            .get<TrainingExecution[]>(this.execTodayRoute, params)
            .pipe(mergeMap((rawTrainingExecutions) => this.hydrateTrainingExecutions(rawTrainingExecutions)));
    }

    private hydrateTrainingExecutions(rawTrainingExecutions: any[]): Observable<TrainingExecution[]> {
        return from(rawTrainingExecutions).pipe(
            mergeMap((rawTrainingExecution) => this.hydrateTrainingExecution(rawTrainingExecution)),
            toArray()
        );
    }

    private hydrateTrainingExecution(rawTrainingExecution: any): Observable<TrainingExecution> {
        return of(rawTrainingExecution).pipe(
            mergeMap((trainingExecution) => of(this.mapperService.map(trainingExecution, TrainingExecution)))
        );
    }
}
