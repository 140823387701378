import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { LeetUserCallToActions } from '../../model/user/leet-user.model';
import * as CallToActionSelectors from '../../store/call-to-action/call-to-action.selectors';
import * as LeetUserCallToActionsActions from '../../store/call-to-action/call-to-action.actions';
import { areEquals } from '../../../shared/function/lowdash';
import memorize from 'memorize-decorator';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { AddLeetUserCallToAction } from '../../command/user/leet-user.commands';

@Injectable({
    providedIn: 'root',
})
export class LeetUserCallToActionService {
    readonly addCallToActionRoute = 'api-v1-add-call-to-action';
    readonly leetUserCallToActionsRoute = 'api-v1-leet-user-call-to-actions';

    constructor(private apiClient: ApiClient, private store: Store) {}

    @memorize({ ttl: 5000 })
    loadByLeetUserId(leetUserId: string): Observable<LeetUserCallToActions> {
        this.apiClient
            .get<LeetUserCallToActions>(this.leetUserCallToActionsRoute, { leetUserId })
            .toPromise()
            .then((leetUserCallToActions) =>
                this.store.dispatch(LeetUserCallToActionsActions.load({ leetUserCallToActions }))
            );

        return this.get(leetUserId);
    }

    public get(leetUserId: string): Observable<LeetUserCallToActions> {
        return this.store
            .select(CallToActionSelectors.get, leetUserId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    public addCallToAction(command: AddLeetUserCallToAction): Promise<any> {
        return this.apiClient
            .post<AddLeetUserCallToAction>(this.addCallToActionRoute, command)
            .toPromise()
            .then(() => this.loadByLeetUserId(command.leetUserId));
    }
}
