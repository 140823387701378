import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';
import { TrainingExercise } from '../step/training-exercise.model';

export class SeriesMeasureModeTrainingExerciseRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let step: Step;

        for (step of steps) {
            if (!step.isExercise()) {
                continue;
            }

            const trainingExercise: TrainingExercise = step as TrainingExercise;

            if (!trainingExercise.getMeasureMode().isSeriesType()) {
                return false;
            }
        }

        return true;
    }
}
