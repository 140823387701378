import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Observable, of, throwError } from 'rxjs';
import { CreateOrUpdateLocation } from '../../command/definition/location.commands';
import { Location, RxLocation } from '../../model/definition/definition.models';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import * as LocationAction from '../../store/definition/location.actions';
import * as LocationSelector from '../../store/definition/location.selectors';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { catchError, distinctUntilChanged, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { areEquals } from '../../../shared/function/lowdash';
import { DefinitionMapper } from './definition.mapper';
import { SportService } from './sport.service';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    readonly findRoute = 'api-v1-find-location';
    readonly updateRoute = 'api-v1-update-location';

    constructor(
        private apiClient: ApiClient,
        private sportService: SportService,
        private store: Store<DefinitionState>,
        private definitionMapper: DefinitionMapper
    ) {}

    loadAll(): Observable<RxLocation[]> {
        this.apiClient
            .get<Location[]>(this.findRoute)
            .toPromise()
            .then((locations: Location[]) => this.store.dispatch(LocationAction.loadAll({ locations })));

        return this.getAll();
    }

    load(id: string | null): Observable<Location> {
        this.apiClient
            .get<Location>(this.findRoute, { id })
            .toPromise()
            .then((location) => this.store.dispatch(LocationAction.load({ location })));

        return this.getById(id);
    }

    getById(id: string): Observable<RxLocation> {
        return this.store
            .select(LocationSelector.get, id)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), map(this.toRx.bind(this)));
    }

    getAll(): Observable<RxLocation[]> {
        return this.store
            .select(LocationSelector.getAll)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), map(this.toRxs.bind(this)));
    }

    update(command: CreateOrUpdateLocation): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.updateRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => of({ id: command.id })),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

    toRxs(locations: Location[]): RxLocation[] {
        return locations.map((location: Location) => this.toRx(location));
    }

    toRx(location: Location): RxLocation {
        return {
            ...location,
            sports$: location.sportsIds.map((sportId) =>
                of({}).pipe(switchMap(() => this.sportService.loadById(sportId)))
            ),
        };
    }
}
