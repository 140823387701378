import {createAction, props} from '@ngrx/store';
import {PlanTemplate} from '../../model/plan-template/plan-template.model';

export const load = createAction(
  '[Plan Template] Load',
  props<{ planTemplate: PlanTemplate }>()
);

export const loadAll = createAction(
  '[Plan Template] Load all',
  props<{ planTemplates: PlanTemplate[] }>()
);

export const remove = createAction(
  '[Plan Template] Remove',
  props<{ id: string }>()
);
