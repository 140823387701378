import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';
import {CommandValidator} from '../../model/form/command-validator';

@Injectable({
    providedIn: 'root'
})
export class FormBuilderService {

    constructor(private formBuilder: FormBuilder) {
    }

    build(command: any, validators: CommandValidator[] = []): FormGroup {
        const formCommand = {};

        Object.keys(command).forEach((key) => {
            const value = command[key];
            const validatorFns = this.getValidator(key, validators);

            formCommand[key] =  [value, validatorFns];
        });

        return this.formBuilder.group(formCommand);
    }

    private getValidator(key: string, validators: CommandValidator[]): ValidatorFn[] {
        const validator = validators.find(validatorNode => validatorNode.key === key);

        if (!validator) {
            return [];
        }

        return validator.validators;
    }
}
