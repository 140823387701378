import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    LeetSearchableFilterInputSelectModalComponent
} from '../leet-searchable-filter-input-select-modal/leet-searchable-filter-input-select-modal.component';
import {first, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../shared/page/base-component.service';
import {Modal} from '../../../shared/service/ionic/modal.ionic';

@Component({
    selector: 'app-leet-searchable-filter-input-select',
    template: `
        <div class="position-relative cursor-pointer">
            <ion-item (click)="launchModal()" class="d-flex align-items-center">
                <ion-label position="floating">{{ label }}</ion-label>
                <ion-input
                    [value]="this.inputValue"
                    (keydown)="$event.preventDefault()"
                    class="cursor-pointer"
                ></ion-input>
            </ion-item>
        </div>
    `,
    styleUrls: ['./leet-searchable-filter-input-select.component.scss'],
})
export class LeetSearchableFilterInputSelectComponent extends BaseComponent implements OnInit {
    @Input() isMultiple;
    @Input() selectedItems$: BehaviorSubject<FilterItem[]> = new BehaviorSubject<FilterItem[]>([]);
    @Input() availableItems$: Observable<FilterItem[]>;
    @Input() label = '';
    inputValue: string = '';

    constructor(
        private eRef: ElementRef,
        private modalController: Modal,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.initRenderInputValue();
    }

    initRenderInputValue() {
        this.subscribe(
            this.selectedItems$.pipe(
                tap(async (selectedItems) => {
                    let value = '';
                    for (const [index, item] of selectedItems.entries()) {
                        if (index === selectedItems.length - 1) {
                            value += await this.translateService.get(item.name).pipe(first()).toPromise();
                            continue;
                        }
                        value += (await this.translateService.get(item.name).pipe(first()).toPromise()) + ', ';
                    }
                    this.inputValue = value;
                })
            )
        );
    }

    async launchModal() {
        await this.modalController.present({
            component: LeetSearchableFilterInputSelectModalComponent,
            componentProps: {
                availableItems$: this.availableItems$,
                selectedItems$: this.selectedItems$,
                isMultiple: this.isMultiple,
                label: this.label,
            },
            cssClass: 'leet-full-modal',
            swipeToClose: true,
        });
    }
}

export interface FilterItem {
    id: string;
    name: string;
}
