import { Type } from 'class-transformer';
import { ScalingSequence } from './scaling-sequence.model';
import {
    AMRAP_TYPE,
    EMOM_TYPE,
    FOR_TIME_TYPE,
    FREE_TYPE,
    LADDER_FOR_TIME_TYPE,
    REPS_STATION_TYPE,
    ROUNDS_FOR_TIME_TYPE,
    SERIES_FOR_REPS_AND_REST_TYPE,
    SERIES_FOR_TIME_AND_REST_TYPE,
    TABATA_TENNIS_TYPE,
    TABATA_TYPE,
    TIME_STATIONS_TYPE,
    UNBROKEN_TYPE,
} from './training-sequence.model';

export const MODE_OPTIONAL = 'optional';
export const MODE_REQUIRED = 'required';

export abstract class TrainingSequenceDto {
    @Type(() => ScalingSequence)
    protected scalingSequences: ScalingSequence[];
    protected id: string;
    protected name: string;
    protected type: string;
    protected discriminatorType: string;
    protected mode: string;
    protected sessionSequenceId: string;
    protected notes: string;

    protected constructor(
        id: string,
        name: string,
        type: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string
    ) {
        this.id = id;
        this.name = name ? name : 'Free training-template';
        this.type = type;
        this.discriminatorType = type;
        this.mode = mode;
        this.scalingSequences = scalingSequences;
        this.sessionSequenceId = sessionSequenceId;
        this.notes = notes;

        this._assert();
    }

    _isOptional(): boolean {
        return this.mode === MODE_OPTIONAL;
    }

    _isRequired(): boolean {
        return this.mode === MODE_REQUIRED;
    }

    _changeToOptionalMode(): void {
        if (this._isOptional()) return;

        this.mode = MODE_OPTIONAL;
    }

    _changeToRequiredMode(): void {
        if (this._isRequired()) return;

        this.mode = MODE_REQUIRED;
    }

    _getScalingSequences(): ScalingSequence[] {
        return this.scalingSequences;
    }

    getScalingSequences(): ScalingSequence[] {
        return this.scalingSequences;
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getType(): string {
        return this.type;
    }

    getMode(): string {
        return this.mode;
    }

    getNotes(): string | null {
        return this.notes;
    }

    getSessionSequenceId(): string {
        return this.sessionSequenceId;
    }

    isFree(): boolean {
        return this.type === FREE_TYPE;
    }

    isForTime(): boolean {
        return this.type === FOR_TIME_TYPE;
    }

    isRoundsForTime(): boolean {
        return this.type === ROUNDS_FOR_TIME_TYPE;
    }

    isSeriesForRepsAndRest(): boolean {
        return this.type === SERIES_FOR_REPS_AND_REST_TYPE;
    }

    isSeriesForTimeAndRest(): boolean {
        return this.type === SERIES_FOR_TIME_AND_REST_TYPE;
    }

    isLadderForTime(): boolean {
        return this.type === LADDER_FOR_TIME_TYPE;
    }

    isEmom(): boolean {
        return this.type === EMOM_TYPE;
    }

    isRepsStations(): boolean {
        return this.type === REPS_STATION_TYPE;
    }

    isTimeStations(): boolean {
        return this.type === TIME_STATIONS_TYPE;
    }

    isAmrap(): boolean {
        return this.type === AMRAP_TYPE;
    }

    isTabataTennis(): boolean {
        return this.type === TABATA_TENNIS_TYPE;
    }

    isTabata(): boolean {
        return this.type === TABATA_TYPE;
    }

    isUnbroken(): boolean {
        return this.type === UNBROKEN_TYPE;
    }

    _assert(): void {
        if (this._doAssert) this._doAssert();
    }

    abstract _doAssert(): void;
}
