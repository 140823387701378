import { ContainerStepExecution, StepExecution } from './step-execution';
import { BUY_OUT_FINISH_STEP_TYPE } from '../training-sequence/step/step.types';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';

export class BuyOutFinishExecution extends ContainerStepExecution {
    constructor(status: string, stepId: string) {
        super(status, stepId, BUY_OUT_FINISH_STEP_TYPE);
    }

    protected updateWithExecutionData(stepExecutionData: StepExecution | null): void {
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            BuyOutFinishExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getSpecificStepType(): BuyOutFinishExecution {
        return this;
    }
}
