export interface Scope {
    scope: ScopeType;
    leetUserOwnerId: string;
}

export function isPublicScope(scope: ScopeType): boolean {
    return scope === 'SCOPE_PUBLIC' || scope === 'SCOPE_PUBLIC_ADMIN';
}

export type ScopeType = 'SCOPE_PUBLIC' | 'SCOPE_PRIVATE' | 'SCOPE_PUBLIC_ADMIN';
