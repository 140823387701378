import {createAction, props} from '@ngrx/store';
import {TrainingTemplate} from '../../model/training/training-template.model';

export const load = createAction(
    '[Training Template] Load',
    props<{ trainingTemplate: TrainingTemplate }>()
);

export const loadAll = createAction(
    '[Training Template] Load all',
    props<{ trainingTemplates: TrainingTemplate[] }>()
);

export const remove = createAction(
    '[Training Template] Remove',
    props<{ id: string }>()
);
