import {createAction, props} from '@ngrx/store';
import {Sport} from '../../model/definition/definition.models';

export const loadAllSports = createAction(
    '[Sport] Load Sports',
    props<{ sports: Sport[] }>()
);

export const loadSport = createAction(
    '[Sport] Load Sport',
    props<{ sport: Sport }>()
);


