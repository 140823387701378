import {MeasureUnit} from '../exercise-template/measure-unit.model';
import {
  DISTANCE_MEASURE,
  GENDER_MODE_UNIFIED,
  REPETITION_MEASURE,
  SERIE_MEASURE,
  TIME_MEASURE
} from '../exercise-template/measure-template.model';

export class Measure {
  private type: string;
  private unit: MeasureUnit | null;
  private genderMode: string;

  constructor(type: string, unit: MeasureUnit | null, genderMode: string) {
    this.type = type;
    this.unit = unit;
    this.genderMode = genderMode;
  }

  public getType(): string {
    return this.type;
  }

  public getUnit(): MeasureUnit | null {
    return this.unit;
  }

  public getGenderMode(): string {
    return this.genderMode;
  }

  public isGenderModeUnified(): boolean {
    return this.getGenderMode() === GENDER_MODE_UNIFIED;
  }

  public clone(): Measure {
    const clonedMeasureUnit: MeasureUnit = {
      value: this.getUnit().value
    };

    return new Measure(this.getType(), clonedMeasureUnit, this.getGenderMode());
  }

  public isSeriesType(): boolean {
    return this.type === SERIE_MEASURE;
  }

  public isTimeType(): boolean {
    return this.type === TIME_MEASURE;
  }

  public isRepsType(): boolean {
    return this.type === REPETITION_MEASURE;
  }

  public isDistanceType(): boolean {
    return this.type === DISTANCE_MEASURE;
  }
}
