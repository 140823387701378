import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../../shared/service/authentication/authentication.service';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Observable } from 'rxjs';
import { LoginCommand } from '../../command/authentication/login.commands';
import { AuthUser } from '../../../shared/model/authentication/auth-user.model';
import { environment } from '../../../../apps/leet-coach/src/environments/environment';
import { RxLeetUser } from '../../model/user/leet-user.model';
import { LeetUserService } from '../user/leet-user.service';
import { LeetInitializerService } from '../initializer/leet-initializer.service';
import { first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { User } from '../../model/user/user.model';
import * as _ from 'lodash';
import { createDate } from '../../model/date/leet-date';
import { UserSubscriptionService } from '../subscription/user-subscription.service';
import { TrainingExecutionService } from '../training-execution/training-execution.service';
import { countTrainingExecutionsByCoachIdParameters } from '../../query/training-execution/training-execution.queries';

@Injectable({ providedIn: 'root' })
export class Session {
    private readonly findPlayerRoute = 'api-v1-find-player';
    private readonly findCoachRoute = 'api-v1-find-coach';
    private readonly getCoachClients = 'api-v1-get-coach-clients';

    constructor(
        private authService: AuthenticationService,
        private apiClient: ApiClient,
        private leetUserService: LeetUserService,
        private userService: UserService,
        private leetInitializerService: LeetInitializerService,
        private router: Router,
        private subscriptionService: UserSubscriptionService,
        private trainingExecutionService: TrainingExecutionService
    ) {}

    login(command: LoginCommand): Observable<AuthUser | Error> {
        const authUser$ = this.authService.login(command);
        authUser$.pipe(
            takeWhile((authUser) => !!authUser),
            first()
        );

        return authUser$;
    }

    loginByClientInvitationToken(clientInvitationToken: string): Observable<AuthUser | Error> {
        const authUser$ = this.authService.loginByClientInvitationToken(clientInvitationToken);
        authUser$.pipe(
            takeWhile((authUser) => !!authUser),
            tap(() => this.leetInitializerService.initialize()),
            first()
        );

        return authUser$;
    }

    currentSportId(): string {
        return environment.sportId;
    }

    currentAuthUser(): AuthUser | null {
        return this.authService.currentUser();
    }

    currentLeetUserId() {
        return this.currentAuthUser().entityId;
    }

    currentLeetUser(): Observable<RxLeetUser> {
        return this.leetUserService.load(this.authService.currentUser().entityId);
    }

    hasActiveSubscription(): Observable<boolean> {
        return this.currentLeetUser().pipe(
            switchMap((leetUser) => leetUser.userSubscription$),
            map((userSubscription) => {
                if (_.isEmpty(userSubscription.currentSubscriptionItem)) return false;
                if (userSubscription.currentSubscriptionItem.expiresAt.timestamp < createDate().timestamp) return false;
                if (
                    userSubscription.currentSubscriptionItem.status.status === 'disabled' ||
                    userSubscription.currentSubscriptionItem.status.status === 'cancelled'
                )
                    return false;
                return true;
            })
        );
    }

    paidSeats$(): Observable<number> {
        return this.currentLeetUser().pipe(
            switchMap((leetUser) => leetUser.userSubscription$),
            map((userSubscription) => {
                return userSubscription.currentSubscriptionItem.seats;
            })
        );
    }

    async remainingScheduledTrainings() {
        const freeUserMaxTrainings = await this.subscriptionService.freeUserMaxTrainings().pipe(first()).toPromise();
        const trainingExecutionsCount = await this.trainingExecutionService
            .countByParameters(countTrainingExecutionsByCoachIdParameters(this.currentLeetUserId()))
            .pipe(first())
            .toPromise();
        return freeUserMaxTrainings - trainingExecutionsCount;
    }

    currentUser(): Observable<User> {
        return this.userService.load(this.authService.currentUser().userId);
    }

    logout(): void {
        this.authService.logout();
        this.router.navigate(['auth', 'login']);
    }

    isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    isCoach(): boolean {
        return this.authService.isCoach();
    }

    isPlayer(): boolean {
        return this.authService.isPlayer();
    }

    isLogged(): boolean {
        return this.authService.isLogged();
    }

    renovateToken(): void {
        this.authService.renovateToken();
    }
}
