import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function sortByName() {
    return function (source: Observable<any>) {
        return source.pipe(
            map((definitions: any[]) => {
                return [...definitions].sort((a, b) => a.name.localeCompare(b.name));
            })
        );
    };
}
