import * as moment from 'moment';
import { Moment } from 'moment';

export interface Datetime {
    timestamp: number;
}

export function createDate(): Datetime {
    return {
        timestamp: moment().unix(),
    };
}

export class LeetDate implements Datetime {
    timestamp: number;

    constructor(timestamp: number) {
        this.timestamp = timestamp;
        this.assert();
    }

    public static now(): LeetDate {
        return new LeetDate(moment().unix());
    }

    public format(format: string): string {
        return this.moment().format(format);
    }

    public moment(): Moment {
        return moment.unix(this.timestamp);
    }

    public endOfDay(): LeetDate {
        return new LeetDate(this.moment().endOf('day').unix());
    }

    private assert(): void {
        let momentValue: Moment | null = null;

        try {
            momentValue = this.moment();
        } catch (error) {}

        if (!momentValue) throw new Error();
    }
}

export function formatDate(timestamp: number): string {
    return new LeetDate(timestamp).format('D-MM-YYYY');
}
