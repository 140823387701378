import { PlanTraining } from './plan-training.model';
import { TrainingTemplate } from '../training/training-template.model';
import { v4 as uuid4 } from 'uuid';
import { Type } from 'class-transformer';

export class PlanDay {
    private id: string;
    @Type(() => PlanTraining)
    private planTrainings: PlanTraining[];
    private notes: string | null;
    private daysOff: number | null;

    constructor(id: string, planTrainings: PlanTraining[], notes: string | null, daysOff: number | null) {
        this.id = id;
        this.planTrainings = planTrainings;
        this.notes = notes;
        this.daysOff = daysOff ? daysOff : 0;
    }

    public getId(): string {
        return this.id;
    }

    public getPlanTrainings(): PlanTraining[] {
        return this.planTrainings;
    }

    public getNotes(): string | null {
        return this.notes;
    }

    public getDaysOff(): number | null {
        return this.daysOff;
    }

    public updateDaysOff(daysOff: number | null): number | null {
        if (!daysOff || daysOff < 0) {
            return;
        }

        this.daysOff = daysOff;
    }

    public canDeletePlanTraining(planTrainingId: string): boolean {
        return this.planTrainings.length > 1;
    }

    public hasPlanTrainings(): boolean {
        return this.planTrainings.length > 0;
    }

    public createAndAddPlanTraining(trainingTemplate: TrainingTemplate): void {
        this.planTrainings.push(new PlanTraining(uuid4(), trainingTemplate, []));
    }

    public addPlanTraining(planTraining: PlanTraining): void {
        if (this.existsPlanTraining(planTraining.getId())) {
            return;
        }

        this.planTrainings.push(planTraining);
    }

    public addPlanTrainings(planTrainings: PlanTraining[]): void {
        planTrainings.forEach((planTraining) => this.addPlanTraining(planTraining));
    }

    public movePlanTraining(planTrainingId: string, newIndex: number): void {
        const planTrainingIndex = this.planTrainingIndexById(planTrainingId);

        if (
            planTrainingIndex === null ||
            newIndex === planTrainingIndex ||
            newIndex < 0 ||
            newIndex >= this.planTrainings.length
        ) {
            return;
        }

        const planTraining = this.planTrainingById(planTrainingId);
        const newPlanTrainings = this.planTrainings.filter(
            (savedPlanTraining) => savedPlanTraining.getId() !== planTrainingId
        );

        if (newIndex === 0) {
            this.planTrainings = [planTraining, ...newPlanTrainings];
        } else if (newIndex === this.planTrainings.length - 1) {
            this.planTrainings = [...newPlanTrainings, planTraining];
        } else {
            this.planTrainings = [
                ...newPlanTrainings.slice(0, newIndex),
                planTraining,
                ...newPlanTrainings.slice(newIndex),
            ];
        }
    }

    public movePlanTrainingUp(planTrainingId: string): void {
        if (!this.canMovePlanTrainingUp(planTrainingId)) {
            return;
        }

        const newPlanTrainingIndex = this.planTrainingIndexById(planTrainingId) - 1;
        this.movePlanTraining(planTrainingId, newPlanTrainingIndex);
    }

    public movePlanTrainingDown(planTrainingId: string): void {
        if (!this.canMovePlanTrainingDown(planTrainingId)) {
            return;
        }

        const newPlanTrainingIndex = this.planTrainingIndexById(planTrainingId) + 1;
        this.movePlanTraining(planTrainingId, newPlanTrainingIndex);
    }

    public canMovePlanTrainingDown(planTrainingId: string): boolean {
        const planTrainingIndex = this.planTrainingIndexById(planTrainingId);

        return planTrainingIndex !== null && planTrainingIndex < this.planTrainings.length - 1;
    }

    public canMovePlanTrainingUp(planTrainingId: string): boolean {
        const planTrainingIndex = this.planTrainingIndexById(planTrainingId);

        return planTrainingIndex !== null && planTrainingIndex > 0;
    }

    public deletePlanTraining(planTrainingId: string): void {
        if (!this.canDeletePlanTraining(planTrainingId)) {
            return;
        }

        this.planTrainings = this.planTrainings.filter((planTraining) => planTraining.getId() !== planTrainingId);
    }

    public clone(replaceId = true): PlanDay {
        const id = replaceId ? uuid4() : this.getId();
        const planTrainings = this.planTrainings.map((planTraining) => planTraining.clone(replaceId));

        return new PlanDay(id, planTrainings, this.getNotes(), this.getDaysOff());
    }

    private planTrainingIndexById(planTrainingId: string): number | null {
        for (let index = 0; index < this.planTrainings.length; index++) {
            if (this.planTrainings[index].getId() === planTrainingId) {
                return index;
            }
        }

        return null;
    }

    private planTrainingById(planTrainingId: string): PlanTraining | null {
        for (const planTraining of this.planTrainings) {
            if (planTraining.getId() === planTrainingId) {
                return planTraining;
            }
        }

        return null;
    }

    private existsPlanTraining(planTrainingId: string): boolean {
        return this.planTrainingById(planTrainingId) !== null;
    }
}
