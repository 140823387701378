import {createSelector} from '@ngrx/store';
import {getDefinitionState} from './definition.selector';
import {DefinitionState} from './definition.reducer';

const selectSports = (state: DefinitionState) => state.sports;


export const getAllSports = createSelector(
    getDefinitionState, selectSports
);

export const getSport = createSelector(
    getDefinitionState,
    (state: DefinitionState, id: string) => {
        return selectSports(state).find((sportNode) => sportNode.id === id);
    }
);
