import { Injectable, Injector } from '@angular/core';
import { LeetUserService } from '../user/leet-user.service';
import { ClubService } from '../club/club.service';
import { UserService } from '../user/user.service';
import { SportService } from '../definition/sport.service';
import { LeetUserCallToActionService } from '../call-to-action/leet-user-call-to-action.service';
import { UserSubscriptionService } from '../subscription/user-subscription.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceContainer {
    private readonly _leetUserService: LeetUserService;
    private readonly _clubService: ClubService;
    private readonly _userService: UserService;
    private readonly _sportService: SportService;
    private readonly _callToActionService: LeetUserCallToActionService;
    private readonly _userSubscriptionService: UserSubscriptionService;

    constructor(private injector: Injector) {
        this._leetUserService = injector.get<LeetUserService>(LeetUserService);
        this._clubService = injector.get<ClubService>(ClubService);
        this._userService = injector.get<UserService>(UserService);
        this._sportService = injector.get<SportService>(SportService);
        this._callToActionService = injector.get<LeetUserCallToActionService>(LeetUserCallToActionService);
        this._userSubscriptionService = injector.get<UserSubscriptionService>(UserSubscriptionService);
    }

    get callToActionService(): LeetUserCallToActionService {
        return this._callToActionService;
    }

    get userSubscriptionService(): UserSubscriptionService {
        return this._userSubscriptionService;
    }

    get leetUserService(): LeetUserService {
        return this._leetUserService;
    }

    get clubService(): ClubService {
        return this._clubService;
    }

    get userService(): UserService {
        return this._userService;
    }

    get sportService(): SportService {
        return this._sportService;
    }
}
