import { createAction, props } from '@ngrx/store';
import { DefinitionState } from '../definition/definition.reducer';
import { DefinitionState as ChatState } from '../chat/chat.reducer';
import { DefinitionState as ClubState } from '../club/club.reducer';
import { DefinitionState as ExerciseTemplateState } from '../exercise-template/exercise-template.reducer';
import { DefinitionState as ExerciseTemplateListViewState } from '../exercise-template-list-view/exercise-template-list-view.reducer';
import { DefinitionState as FileState } from '../file/file.reducer';
import { DefinitionState as LeetUserState } from '../leet-user/leet-user.reducer';
import { DefinitionState as PlanExecutionState } from '../plan-execution/plan-execution.reducer';
import { DefinitionState as PlanTemplateState } from '../plan-template/plan-template.reducer';
import { DefinitionState as PlayerState } from '../player/player.reducer';
import { DefinitionState as TrainingExecutionState } from '../training-execution/training-execution.reducer';
import { DefinitionState as TrainingSequenceState } from '../training-sequence/training-sequence.reducer';
import { DefinitionState as TrainingTemplateState } from '../training-template/training-template.reducer';
import { DefinitionState as UserState } from '../user/user.reducer';
import { DefinitionState as LeetUserCallToaActionsState } from '../call-to-action/call-to-action.reducer';
import { DefinitionState as ClientCoachDataState } from '../coach-client-data/coach-client-data.reducer';
import { DefinitionState as UserSubscriptionState } from '../subscription/user-subscription.reducer';
import { DefinitionState as TrainingExecutionListViewState } from '../training-execution-list-view/training-execution-list-view.reducer';

export const initDefinitionState = createAction('[Definition] Init', props<{ state: DefinitionState }>());
export const initChatState = createAction('[Chat] Init', props<{ state: ChatState }>());
export const initClubState = createAction('[Club] Init', props<{ state: ClubState }>());
export const initExerciseTemplateState = createAction(
    '[ExerciseTemplate] Init',
    props<{ state: ExerciseTemplateState }>()
);
export const initExerciseTemplateListViewState = createAction(
    '[ExerciseTemplateListView] Init',
    props<{ state: ExerciseTemplateListViewState }>()
);
export const initFileState = createAction('[File] Init', props<{ state: FileState }>());
export const initLeetUserState = createAction('[LeetUser] Init', props<{ state: LeetUserState }>());
export const initLeetUserCallToActions = createAction(
    '[LeetUserCallToActions] Init',
    props<{ state: LeetUserCallToaActionsState }>()
);
export const initClientCoachData = createAction('[ClientCoachData] Init', props<{ state: ClientCoachDataState }>());
export const initCoach = createAction('[LeetUserCallToActions] Init', props<{ state: LeetUserCallToaActionsState }>());
export const initPlanExecutionState = createAction('[PlanExecution] Init', props<{ state: PlanExecutionState }>());
export const initPlanTemplateState = createAction('[PlanTemplate] Init', props<{ state: PlanTemplateState }>());
export const initPlayerState = createAction('[Player] Init', props<{ state: PlayerState }>());
export const initTrainingExecutionState = createAction(
    '[TrainingExecution] Init',
    props<{ state: TrainingExecutionState }>()
);

export const initTrainingExecutionListViewState = createAction(
    '[TrainingExecutionListView] Init',
    props<{ state: TrainingExecutionListViewState }>()
);
export const initTrainingSequenceState = createAction(
    '[TrainingSequence] Init',
    props<{ state: TrainingSequenceState }>()
);
export const initTrainingTemplateState = createAction(
    '[TrainingTemplate] Init',
    props<{ state: TrainingTemplateState }>()
);
export const initUserState = createAction('[User] Init', props<{ state: UserState }>());
export const initUserSubscriptionsState = createAction(
    '[UserSubscription] Init',
    props<{ state: UserSubscriptionState }>()
);

export const clearState = createAction('CLEAR_STATE');
