// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    sportId: '39642552-dbe8-41f6-b84c-7b40faa4fef9',
    recpatcha_site_key: '6LcUdAUbAAAAAH1plGfuu6WqH4PytDfj2SUB5Act',
    stripe_public_key:
        'pk_test_51JZAOcFJK3nlFkBOHPflvAJxKbgCVT5u0GNTnDwZ3TvifBeRy1usvgSwMiA0t7aaSY31DT7gDwQgdHFSabJehj5600aYCrqzbm',
    monthly_stripe_price_id: 'price_1JcmbTFJK3nlFkBO3uMpui1X',
    yearly_stripe_price_id: 'price_1JZWE9IBvtF9HQYfvl6mGwNm',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
