import { Step } from './step/step.model';
import { SequenceRestriction } from './restriction/sequence-restriction.model';
import { TrainingSequenceScalingAware } from './training-sequence-2-scaling-aware.model';
import { StepFactory } from '../../service/training-sequence/step.factory';
import { v4 as uuid4 } from 'uuid';
import { TrainingSequenceStepsHelper } from '../../service/training-sequence/training-sequence-steps-helper.service';
import { TrainingExercise } from './step/training-exercise.model';
import {
    TrainingExerciseRestriction
} from '../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import { TrainingSequence } from './training-sequence.model';

export abstract class TrainingSequenceLegacy extends TrainingSequenceScalingAware {
    _canStepBeAdded(step: Step, index: number | null): boolean {
        return this._allRoundsShouldHaveSameSteps()
            ? this._canStepBeAddedInAllRounds(step, index)
            : this._canStepBeAddedInIndex(step, index);
    }

    _addStep(step: Step, index: number | null = null): void {
        index = index || index === 0 ? index : this._getLastIndexWithoutBuyOut();

        if (this._allRoundsShouldHaveSameSteps()) {
            this._addStepInAllRounds(step, index);
            return;
        }

        this._addStepInIndex(step, index);
    }

    _lastStepIndexInRound(linkedId: string): number | null {
        let isCurrentRound = false;

        for (let index = 0; index < this.mainScalingSequenceSteps().length; index++) {
            const step = this.mainScalingSequenceSteps()[index];

            if (step.isRound()) {
                if (isCurrentRound) return index - 1;

                isCurrentRound = step.getLinkedId() === linkedId;
                continue;
            }

            if (!isCurrentRound) continue;

            if (this._isStepInBuyOut(step.getLinkedId())) return index - 1;
        }

        if (isCurrentRound) return this.mainScalingSequenceSteps().length - 1;

        return null;
    }

    _addSteps(steps: Step[], index: number | null = null): void {
        index = index || index === 0 ? index : this._getLastIndexWithoutBuyOut();

        if (!this._canStepsBeAdded(steps, index)) return;
        this.scalingSequences.forEach((scalingSequence) =>
            scalingSequence.addSteps(
                steps.map((step) => step.clone()),
                index
            )
        );
    }

    _canStepBeRemoved(stepLinkedId: string): boolean {
        return this._areStepsOrderValid(
            this.mainScalingSequenceSteps().filter((step) => step.getLinkedId() !== stepLinkedId)
        );
    }

    _canStepsBeRemoved(stepLinkedIds: string[]): boolean {
        return this._areStepsOrderValid(
            this.mainScalingSequenceSteps().filter((step) => !stepLinkedIds.includes(step.getLinkedId()))
        );
    }

    _moveStepUpByLinkedId(stepLinkedId: string): void {
        if (!this._canStepBeMovedUpByStepLinkedId(stepLinkedId)) return;

        this.scalingSequences.forEach((scalingSequence) => scalingSequence.moveStepUpByLinkedId(stepLinkedId));
    }

    _moveStepDownByLinkedId(stepLinkedId: string): void {
        if (!this._canStepBeMovedDownByStepLinkedId(stepLinkedId)) return;

        this.scalingSequences.forEach((scalingSequence) => scalingSequence.moveStepDownByLinkedId(stepLinkedId));
    }

    _canStepBeMovedUpByStepLinkedId(stepLinkedId: string): boolean {
        const linkedSteps = this._fistScalingSequenceStepsByLinkedId(stepLinkedId);

        for (const step of this._fistScalingSequenceStepsByLinkedId(stepLinkedId))
            if (!this._canStepBeMovedUpById(step.getId())) return false;

        return linkedSteps.length !== 0;
    }

    _canStepBeMovedDownByStepLinkedId(stepLinkedId: string): boolean {
        const linkedSteps = this._fistScalingSequenceStepsByLinkedId(stepLinkedId);

        for (const step of linkedSteps) if (!this._canStepBeMovedDownById(step.getId())) return false;

        return linkedSteps.length !== 0;
    }

    _stepIndexById(stepId: string, scalingSequenceId: string | null = null): number {
        if (scalingSequenceId) return this._scalingSequenceById(scalingSequenceId).stepIndexById(stepId);

        for (const scalingSequence of this.scalingSequences) {
            const stepIndex = scalingSequence.stepIndexById(stepId);
            if (stepIndex !== null) return stepIndex;
        }

        return null;
    }

    _removeStep(stepLinkedId: string): void {
        if (!this._canStepBeRemoved(stepLinkedId)) return;

        this.scalingSequences.forEach((scalingSequence) => scalingSequence.removeStep(stepLinkedId));
    }

    _stepsByLinkedId(linkedId: string): Step[] {
        return this.mainScalingSequenceSteps().filter((step) => step.getLinkedId() === linkedId);
    }

    _canStepsBeAdded(steps: Step[], index: number): boolean {
        if (steps.length === 0 || !this._isStepIndexValid(index)) return false;

        let newStepsOrder;

        if (index === 0) newStepsOrder = [...steps, ...this.mainScalingSequenceSteps()];
        else if (steps.length === index) newStepsOrder = [...this.mainScalingSequenceSteps(), ...steps];
        else {
            const firstPartSteps = this.mainScalingSequenceSteps().slice(0, index);
            const lastPartSteps = this.mainScalingSequenceSteps().slice(index);
            newStepsOrder = [...firstPartSteps, ...steps, ...lastPartSteps];
        }

        return this._areStepsOrderValid(newStepsOrder);
    }

    _firstStepIndexByLinkedId(linkedId: string): number | null {
        for (let index = 0; index < this.mainScalingSequenceSteps().length; index++)
            if (this.mainScalingSequenceSteps()[index].getLinkedId() === linkedId) return index;

        return null;
    }

    _firstStepByLinkedId(linkedId: string): Step | null {
        const stepIndex = this._firstStepIndexByLinkedId(linkedId);

        return stepIndex ? this.mainScalingSequenceSteps()[stepIndex] : null;
    }

    abstract _canShowStep(stepLinkedId: string): boolean;

    public _removeSteps(stepLinkedIds: string[]): void {
        if (!this._canStepsBeRemoved(stepLinkedIds)) return;
        this.scalingSequences.forEach((scalingSequence) => scalingSequence.removeSteps(stepLinkedIds));
    }

    _deleteRound(linkedId: string): void {
        if (!this._canRoundBeDeleted(linkedId)) return;

        if (this._allRoundsShouldHaveSameSteps()) {
            const allRoundStepsIds = this._getStepsInsideRoundIncludingRoundStep(linkedId).map((step) => step.getId());

            if (allRoundStepsIds.length === 0) return;

            this.scalingSequences.forEach((scalingSequence) => scalingSequence.removeStepsByIds(allRoundStepsIds));
            return;
        }

        this._removeSteps(this._getStepsInsideRoundIncludingRoundStep(linkedId).map((step) => step.getLinkedId()));
    }

    _canRoundBeDeleted(linkedId: string): boolean {
        return this._allRoundsShouldHaveSameSteps()
            ? this._numberOfRoundsInSteps() > 1
            : this._canStepsBeRemoved(
                this._getStepsInsideRoundIncludingRoundStep(linkedId).map((step) => step.getLinkedId())
            );
    }

    _deleteBuyIn(): void {
        if (!this.mainScalingSequenceSteps()[0].isBuyInStart()) return;

        const deleteStepLinkedIds = [];

        for (const step of this.mainScalingSequenceSteps()) {
            deleteStepLinkedIds.push(step.getLinkedId());

            if (step.isBuyInFinish()) {
                deleteStepLinkedIds.push(step.getLinkedId());
                break;
            }
        }

        this._removeSteps(deleteStepLinkedIds);
    }

    _deleteBuyOut(): void {
        if (!this._getLastStep().isBuyOutFinish()) return;

        const deleteStepLinkedIds = [];
        const steps = this.mainScalingSequenceSteps();

        for (let index = steps.length - 1; index >= 0; index--) {
            const step = steps[index];
            deleteStepLinkedIds.push(step.getLinkedId());

            if (step.isBuyOutStart()) break;
        }

        this._removeSteps(deleteStepLinkedIds);
    }

    _hasExercises(): boolean {
        return this.mainScalingSequenceSteps().filter((step) => step.isExercise()).length > 0;
    }

    _canStepBeAddedToComplex(stepLinkedId: string): boolean {
        let inComplex = false;
        let inBuyIn = false;
        let inBuyOut = false;
        let existsStep = false;

        for (const step of this.mainScalingSequenceSteps()) {
            if (step.getLinkedId() === stepLinkedId) {
                if (inComplex || inBuyOut || inBuyIn || (!step.isExercise() && !step.isRest())) return false;

                existsStep = true;
                continue;
            }

            if (step.isComplexStart()) {
                inComplex = true;
                continue;
            }

            if (step.isComplexFinish()) {
                inComplex = false;
                continue;
            }

            if (step.isBuyOutStart()) {
                inBuyOut = true;
                continue;
            }

            if (step.isBuyOutFinish()) {
                inBuyOut = false;
                continue;
            }

            if (step.isBuyInStart()) {
                inBuyIn = true;
                continue;
            }

            if (step.isBuyInFinish()) {
                inBuyIn = false;
                continue;
            }
        }

        return existsStep;
    }

    _canStepsBeAddedToComplex(stepLinkedIds: string[]): boolean {
        for (const stepLinkedId of stepLinkedIds) if (!this._canStepBeAddedToComplex(stepLinkedId)) return false;

        return true;
    }

    _createComplex(stepLinkedIds: string[]): void {
        if (
            !this._canCreateComplex() ||
            stepLinkedIds.length < 2 ||
            !this._areStepLinkedIdsValid(stepLinkedIds) ||
            !this._areStepsByLinkedIdsInSameRound(stepLinkedIds) ||
            !this._canStepsBeAddedToComplex(stepLinkedIds)
        )
            return;

        const complexSteps: Step[] = [StepFactory.newInstance().createComplexStart(uuid4())];
        let stepIndexes = [];

        stepLinkedIds.forEach((stepLinkedId) => {
            complexSteps.push(this._stepsByLinkedId(stepLinkedId).pop().clone());
            const currentStepIndexes = this._stepsByLinkedId(stepLinkedId).map((step) =>
                this._stepIndexById(step.getId())
            );
            stepIndexes = stepIndexes.concat(currentStepIndexes);
        });

        complexSteps.push(StepFactory.newInstance().createComplexFinish(uuid4()));
        const stepsIndex = stepIndexes.sort((a, b) => a - b)[0];

        this.scalingSequences.forEach((scalingSequence) => scalingSequence.removeSteps(stepLinkedIds));
        this.scalingSequences.forEach((scalingSequence) =>
            scalingSequence.addSteps(
                complexSteps.map((step) => step.clone()),
                stepsIndex
            )
        );
    }

    _removeComplex(complexStartLinkedId: string): void {
        this.scalingSequences.forEach((scalingSequence) => scalingSequence.removeComplex(complexStartLinkedId));
    }

    _canCloneLastRound(): boolean {
        return this._canRoundBeCloned(this._lastRoundLinkedId());
    }

    _canRoundBeCloned(roundLinkedId: string): boolean {
        return this._canStepsBeAdded(
            this._clonedStepsByRoundLinkedId(roundLinkedId),
            this.mainScalingSequenceSteps().length
        );
    }

    _cloneLastRound(): void {
        this._cloneRoundByLinkedId(this._lastRoundLinkedId());
    }

    _cloneRoundByLinkedId(linkedId: string): void {
        this._addSteps(this._clonedStepsByRoundLinkedId(linkedId));
    }

    _lastRoundLinkedId(): string {
        return this.mainScalingSequenceSteps()
            .filter((step) => step.isRound())
            .pop()
            .getLinkedId();
    }

    firstRoundId(): string {
        return this.mainScalingSequenceSteps()
            .filter((step) => step.isRound())
            .shift()
            .getId();
    }

    _clonedStepsByRoundLinkedId(linkedId: string): Step[] {
        return this._getStepsInsideRoundIncludingRoundStep(linkedId).map((step) =>
            step.clone(!step.isRound() && this._allRoundsShouldHaveSameSteps())
        );
    }

    _getStepsInsideRoundIncludingRoundStep(linkedId: string): Step[] {
        return TrainingSequenceStepsHelper.getStepsInsideRound(linkedId, this.mainScalingSequenceSteps());
    }

    _replaceRoundStepsOrder(steps: Step[]): void {
        if (steps[0].isRound()) return;

        const stepStartingIndex = this._stepIndexById(steps[0].getId());

        const scalingSequences = this._getScalingSequences();
        scalingSequences.forEach((scalingSequence) =>
            scalingSequence.removeSteps(steps.map((step) => step.getLinkedId()))
        );

        this._addSteps(steps, stepStartingIndex);
    }

    _showExerciseMeasure(stepLinkedId: string): boolean {
        return true;
    }

    _showExerciseMeasureMode(stepLinkedId: string): boolean {
        return this._isExerciseMeasureModeRequired() ? !this._isStepInComplex(stepLinkedId) : false;
    }

    _sanetize(): void {
        for (const scalingSequence of this.scalingSequences)
            for (const step of scalingSequence.getSteps()) {
                if (!step.isExercise()) continue;

                if (!this._showExerciseMeasureMode(step.getLinkedId()))
                    (step as TrainingExercise).updateMeasureModeUnitValue(1);

                if (!this._showExerciseMeasure(step.getLinkedId()))
                    (step as TrainingExercise).updateExerciseMeasureUnitValue(1);
            }

        this._doSanetize();
    }

    _hasBuyIn(): boolean {
        return TrainingSequenceStepsHelper.getStepsInBuyIn(this.mainScalingSequenceSteps()).length > 0;
    }

    _hasBuyOut(): boolean {
        return TrainingSequenceStepsHelper.getStepsInBuyOut(this.mainScalingSequenceSteps()).length > 0;
    }

    _getLastIndexWithoutBuyOut(): number {
        return (
            this._numberOfSteps() - TrainingSequenceStepsHelper.getStepsInBuyOut(this.mainScalingSequenceSteps()).length
        );
    }

    _isStepInComplex(stepLinkedId): boolean {
        return TrainingSequenceStepsHelper.isStepInComplex(stepLinkedId, this.mainScalingSequenceSteps());
    }

    _isStepInBuyOut(stepLinkedId): boolean {
        return TrainingSequenceStepsHelper.isStepInBuyOut(stepLinkedId, this.mainScalingSequenceSteps());
    }

    abstract _trainingExerciseRestriction(): TrainingExerciseRestriction | null;

    abstract _canAddExerciseInOnlyOneRound(): boolean;

    abstract _canAddBuyIn(): boolean;

    abstract _canAddBuyOut(): boolean;

    abstract _clone(): TrainingSequence;

    abstract _doSanetize(): void;

    abstract _allRoundsShouldHaveSameSteps(): boolean;

    abstract _isExerciseMeasureModeRequired(): boolean;

    abstract _canCreateComplex(): boolean;

    abstract _addRestAfterExercise(): boolean;

    protected _isStepIndexValid(index: number): boolean {
        return index >= 0 && index <= this.mainScalingSequenceSteps().length;
    }

    protected _areStepsOrderValid(steps: Step[]): boolean {
        let areStepsOrderValid = steps.length > 0;

        this._allSequenceRestrictions().forEach((sequenceRestriction: SequenceRestriction) => {
            areStepsOrderValid = areStepsOrderValid && sequenceRestriction.areStepsOrderValid(steps);
        });

        return areStepsOrderValid;
    }

    protected _getLastStep(): Step {
        const steps = this.mainScalingSequenceSteps();
        return steps[steps.length - 1];
    }

    protected _orderIndexesDesc(indexes: number[]): number[] {
        return indexes.sort().reverse();
    }

    protected _getStepsAfterAddStep(steps: Step[], step: Step, index: number): Step[] {
        let newSteps = [];

        if (index === steps.length) {
            newSteps = steps.map((savedStep) => savedStep);
            newSteps.push(step);
        } else
            for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
                if (stepIndex === index) newSteps.push(step);

                newSteps.push(steps[stepIndex]);
            }

        return newSteps;
    }

    protected _numberOfSteps(): number {
        return this.mainScalingSequenceSteps().length;
    }

    protected _areStepLinkedIdsValid(stepLinkedIds: string[]): boolean {
        if (stepLinkedIds.length === 0) return false;

        for (const stepLinkedId of stepLinkedIds) {
            const step = this._firstStepByLinkedId(stepLinkedId);
            if (!step || !(step.isExercise() || step.isRest())) return false;
        }

        return true;
    }

    private _canStepBeAddedInAllRounds(step: Step, index: number | null): boolean {
        index =
            index || index === 0
                ? this._getSanetizedIndex(index)
                : this._getSanetizedIndex(this.mainScalingSequenceSteps().length);

        if (!this._isStepIndexValid(index)) return false;

        const stepsAfterAddStepInAllRound = this._getStepsAfterAddStepInAllRound(
            this.mainScalingSequenceSteps(),
            step,
            this._allIndexesWhereInsertSteps(index)
        );

        return this._areStepsOrderValid(stepsAfterAddStepInAllRound);
    }

    private _getSanetizedIndex(index: number | null): number {
        const stepsPerRound = this._stepsPerRound();

        while (index > this._stepsPerRound()) index -= stepsPerRound;

        return index;
    }

    private _addStepInIndex(step: Step, index: number | null = null): void {
        index = index ? index : this.scalingSequences[0].getSteps().length;

        if (!this._canStepBeAdded(step, index)) return;

        this.scalingSequences.forEach((scalingSequence) => scalingSequence.addStep(step, index));
    }

    private _canStepBeAddedInIndex(step: Step, index: number | null): boolean {
        index = index || index === 0 ? index : this.mainScalingSequenceSteps().length;

        if (!this._isStepIndexValid(index)) return false;

        let newStepsOrder = [];

        if (index === this.mainScalingSequenceSteps().length) {
            newStepsOrder = this.mainScalingSequenceSteps().map((savedStep) => savedStep);
            newStepsOrder.push(step);
        } else
            for (let stepIndex = 0; stepIndex < this.mainScalingSequenceSteps().length; stepIndex++) {
                if (stepIndex === index) newStepsOrder.push(step);

                newStepsOrder.push(this.mainScalingSequenceSteps()[stepIndex]);
            }

        return this._areStepsOrderValid(newStepsOrder);
    }

    private _stepByIndex(index: number): Step | null {
        const steps = this.mainScalingSequenceSteps();

        return index < 0 || index >= steps.length ? null : steps[index];
    }

    private _stepById(id: string): Step | null {
        const steps = this.mainScalingSequenceSteps();

        for (const step of steps) if (step.getId() === id) return step;

        return null;
    }

    private _stepIndex(id: string): number | null {
        const steps = this.mainScalingSequenceSteps();

        for (let index = 0; index < steps.length; index++) if (steps[index].getId() === id) return index;

        return null;
    }

    private _canStepBeMovedUpById(stepId: string): boolean {
        const step = this._stepById(stepId);
        const stepIndex = this._stepIndex(stepId);

        if (!step || !stepIndex || step.isRound() || stepIndex === this.mainScalingSequenceSteps().length - 1)
            return false;

        const nextStep = this._stepByIndex(stepIndex + 1);

        if (nextStep.isRound()) return false;

        return this._areStepsOrderValid(this._getStepsChangingStepIndex(stepIndex, stepIndex + 1));
    }

    private _canStepBeMovedDownById(stepId: string): boolean {
        const step = this._stepById(stepId);
        const stepIndex = this._stepIndex(stepId);

        if (!step || !stepIndex || stepIndex === 0 || step.isRound()) return false;

        const prevStep = this._stepByIndex(stepIndex - 1);

        if (prevStep.isRound()) return false;

        return this._areStepsOrderValid(this._getStepsChangingStepIndex(stepIndex, stepIndex - 1));
    }

    private _getStepsChangingStepIndex(stepIndex: number, newStepIndex: number): Step[] {
        return this.mainScalingSequence().getStepsChangingStepIndex(stepIndex, newStepIndex);
    }

    private _areStepsByLinkedIdsInSameRound(stepLinkedIds: string[]): boolean {
        if (stepLinkedIds.length === 0) return false;

        const firstStepRound = this._roundIndexByStepLinkedId(stepLinkedIds[0]);

        if (!firstStepRound) return false;

        for (let index = 1; index < stepLinkedIds.length; index++)
            if (firstStepRound !== this._roundIndexByStepLinkedId(stepLinkedIds[index])) return false;

        return true;
    }

    private _roundIndexByStepLinkedId(stepLinkedId: string): number | null {
        let roundIndex = 0;

        for (const step of this.mainScalingSequenceSteps()) {
            if (step.isRound()) roundIndex++;

            if (step.getLinkedId() === stepLinkedId) return roundIndex;
        }

        return null;
    }

    private _addStepInAllRounds(step: Step, index: number | null): void {
        index =
            index || index === 0
                ? this._getSanetizedIndex(index)
                : this._getSanetizedIndex(this.scalingSequences[0].getSteps().length);

        if (!this._canStepBeAdded(step, index)) return;

        const indexes = this._orderIndexesDesc(this._allIndexesWhereInsertSteps(index));

        this.scalingSequences.forEach((scalingSequence) =>
            indexes.forEach((insertIndex) => scalingSequence.addStep(step.clone(), insertIndex))
        );
    }

    private _numberOfRoundsInSteps(): number {
        return this.mainScalingSequenceSteps().filter((step) => step.isRound()).length;
    }

    private _stepsPerRound(): number {
        return this.mainScalingSequenceSteps().length / this._numberOfRoundsInSteps();
    }

    private _allIndexesWhereInsertSteps(index: number): number[] {
        const stepsPerRound = this._stepsPerRound();
        index = this._getSanetizedIndex(index);
        const indexes = [index];

        for (let roundIndex = 1; roundIndex < this._numberOfRoundsInSteps(); roundIndex++)
            indexes.push(index + roundIndex * stepsPerRound);

        return indexes;
    }

    private _getStepsAfterAddStepInAllRound(steps: Step[], step: Step, indexes: number[]): Step[] {
        let newSteps = [];

        for (const index of this._orderIndexesDesc(indexes))
            newSteps = this._getStepsAfterAddStep(steps, step.clone(), index);

        return newSteps;
    }
}
