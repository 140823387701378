import { AfterViewInit, Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CacheService } from '../../../../shared/shared/service/cache/cache.service';
import { LocationService } from '../../../../shared/leet/service/definition/location.service';
import { SessionSequenceService } from '../../../../shared/leet/service/definition/session-sequence.service';
import { MuscleGroupService } from '../../../../shared/leet/service/definition/muscle-group.service';
import { TimePlanningService } from '../../../../shared/leet/service/definition/time-planning.service';
import { DifficultyLevelService } from '../../../../shared/leet/service/definition/difficulty-level.service';
import { MaterialService } from '../../../../shared/leet/service/definition/material.service';
import { ObjectiveService } from '../../../../shared/leet/service/definition/objective.service';
import { SportService } from '../../../../shared/leet/service/definition/sport.service';
import { TrainingDurationService } from '../../../../shared/leet/service/definition/training-duration.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private cacheService: CacheService,
        private locationService: LocationService,
        private sequenceService: SessionSequenceService,
        private muscleGroupService: MuscleGroupService,
        private timePlanningService: TimePlanningService,
        private difficultyLevelService: DifficultyLevelService,
        private materialService: MaterialService,
        private objectiveService: ObjectiveService,
        private sportService: SportService,
        private trainingDurationService: TrainingDurationService
    ) {}

    ngAfterViewInit(): void {}

    async ngOnInit(): Promise<void> {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.statusBar.backgroundColorByHexString('#ffffff');
            // this.cacheService.loadCachedStoreState();
            this.locationService.loadAll();
            this.sequenceService.loadAll();
            this.muscleGroupService.loadAll();
            this.timePlanningService.loadAll();
            this.difficultyLevelService.loadAll();
            this.materialService.loadAll();
            this.objectiveService.loadAll();
            this.trainingDurationService.loadAll();
            this.sportService.loadAll();
        });
    }
}
