import {createSelector} from '@ngrx/store';
import {getDefinitionState} from './definition.selector';
import {DefinitionState} from './definition.reducer';

const selectSessionSequences = (state: DefinitionState) => state.timePlannings;

export const getAll = createSelector(
    getDefinitionState, selectSessionSequences
);

export const get = createSelector(
    getDefinitionState,
    (state: DefinitionState, id: string) => selectSessionSequences(state).find((planningNode) => planningNode.id === id)
);
