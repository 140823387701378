import { AMRAP_TYPE, TrainingSequence } from '../training-sequence.model';
import { ScalingSequence } from '../scaling-sequence.model';
import {
    TrainingExerciseRestriction
} from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import {
    distanceMeasureTemplate,
    repetitionMeasureTemplate,
    serieMeasureTemplate,
    timeMeasureTemplate,
    weightMeasureTemplate,
} from '../../exercise-template/measure-template.model';
import { MeasureUnit, TIME_UNIT_MINUTE, TIME_UNIT_SECOND } from '../../exercise-template/measure-unit.model';
import { SequenceRestriction } from '../restriction/sequence-restriction.model';
import { MaxOneRoundPerSequenceRestriction } from '../restriction/max-one-round-per-sequence-restriction.model';
import { SeriesMeasureModeTrainingExerciseRestriction } from '../restriction/series-measure-mode-training-exercise-restriction.model';
import { v4 as uuid4 } from 'uuid';

export const DEFAULT_AMRAP_MEASURE_UNIT = TIME_UNIT_MINUTE;
export const AVAILABLE_AMRAP_MEASURE_UNITS = [TIME_UNIT_MINUTE, TIME_UNIT_SECOND];

export class AmrapTrainingSequence extends TrainingSequence {
    private measureUnit: MeasureUnit;
    private measureUnitValue: number | null;

    constructor(
        id: string,
        name: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string,
        measureUnit: MeasureUnit,
        measureUnitValue: number | null
    ) {
        super(id, name, AMRAP_TYPE, mode, scalingSequences, sessionSequenceId, notes);
        this.measureUnit = measureUnit;
        this.measureUnitValue = measureUnitValue;
    }

    _doAssert(): void {
    }

    public _sequenceRestrictions(): SequenceRestriction[] {
        return [new MaxOneRoundPerSequenceRestriction(), new SeriesMeasureModeTrainingExerciseRestriction()];
    }

    public _trainingExerciseRestriction(): TrainingExerciseRestriction | null {
        return {
            availableExerciseMeasureTemplates: [
                timeMeasureTemplate(),
                distanceMeasureTemplate(),
                repetitionMeasureTemplate(),
            ],
            availableMeasureModeTemplates: [serieMeasureTemplate()],
            weightMeasureTemplate: weightMeasureTemplate(),
        };
    }

    public getMeasureUnit(): MeasureUnit {
        return this.measureUnit;
    }

    getMeasureUnitValue(): number | null {
        return this.measureUnitValue;
    }

    _clone(): TrainingSequence {
        const scalingSequences = this.scalingSequences.map((scalingSequence) => scalingSequence.clone());

        return new AmrapTrainingSequence(
            uuid4(),
            this.getName(),
            this.getMode(),
            scalingSequences,
            this.getSessionSequenceId(),
            this.getNotes(),
            { value: this.measureUnit.value },
            this.measureUnitValue
        );
    }

    _canShowStep(stepLinkedId: string): boolean {
        const steps = this._stepsByLinkedId(stepLinkedId);
        return steps.length !== 0 && steps.filter((step) => step.isRound()).length === 0;
    }

    _canAddExerciseInOnlyOneRound(): boolean {
        return true;
    }

    _allRoundsShouldHaveSameSteps(): boolean {
        return false;
    }

    _isExerciseMeasureModeRequired(): boolean {
        return false;
    }

    _addRestAfterExercise(): boolean {
        return false;
    }

    nextMeasureUnit(): void {
        this.measureUnit = {
            value: AVAILABLE_AMRAP_MEASURE_UNITS.filter(
                (measureUnitValue) => measureUnitValue !== this.measureUnit.value
            )[0],
        };
    }

    updateMeasureUnitValue(value: number): void {
        this.measureUnitValue = value;
    }

    _canCreateComplex(): boolean {
        return false;
    }

    public _doSanetize(): void {
    }

    public _canAddBuyIn(): boolean {
        return false;
    }

    public _canAddBuyOut(): boolean {
        return false;
    }
}
