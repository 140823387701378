import {createAction, props} from '@ngrx/store';
import {TrainingDuration} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Training Duration] Load all',
    props<{ trainingDurations: TrainingDuration[] }>()
);

export const load = createAction(
    '[Training Duration] Load',
    props<{ trainingDuration: TrainingDuration }>()
);


