import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class AllRestAndExercisesAreInRoundRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let inRound = false;
        let inBuyIn = false;
        let inBuyOut = false;

        for (const step of steps) {
            if (step.isRound()) {
                inRound = true;
                continue;
            }

            if (step.isBuyInStart()) {
                inBuyIn = true;
                inRound = false;
                continue;
            }

            if (step.isBuyOutStart()) {
                inBuyOut = true;
                inRound = false;
                continue;
            }

            if (step.isBuyInFinish()) {
                inBuyIn = false;
                continue;
            }

            if (step.isBuyOutFinish()) {
                inBuyOut = false;
                continue;
            }

            if ((step.isRest() || step.isExercise()) && !inRound && !inBuyIn && !inBuyOut) return false;
        }

        return true;
    }
}
