import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class MapperService {
  private static that;

  constructor() {
    MapperService.that = this;
  }

  public static newInstance(): MapperService {
    return MapperService.that;
  }

  public map(data: any, objectClass: any): any {
    return plainToClass(objectClass, data);
  }
}
