import { createAction, props } from '@ngrx/store';
import { TrainingExecutionListView } from '../../model/training-execution-list-view/training-execution-list-view.model';

export const loadByParametersSuccess = createAction(
    '[Training Execution List View] Load by parameters success',
    props<{ trainingExecutionListViews: TrainingExecutionListView[] }>()
);

export const remove = createAction('[Training Execution List View] Remove', props<{ id: string }>());

