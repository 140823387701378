import { Pipe, PipeTransform } from '@angular/core';
import { toSearchableText } from '../../shared/function/string.helpers';
import { FilterItem } from '../component/leet-searchable-filter-input-select/leet-searchable-filter-input-select.component';

@Pipe({ name: 'dropdownSearchFilterPipe' })
export class DropdownSearchFilterPipe implements PipeTransform {
    transform(dropdownElements: FilterItem[], propertyValueToFilter: string): FilterItem[] {
        return dropdownElements.filter((dropdownElement) => {
            return toSearchableText(dropdownElement.name).includes(
                toSearchableText(propertyValueToFilter.toLowerCase())
            );
        });
    }
}
