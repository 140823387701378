import { createAction, props } from '@ngrx/store';
import { TrainingExecution } from '../../model/training-execution/training-execution.model';

export const load = createAction('[Training Execution] Load', props<{ trainingExecution: TrainingExecution }>());

export const remove = createAction('[Training Execution] Remove', props<{ id: string }>());

export const loadByPlayer = createAction(
    '[Training Execution] Load by player',
    props<{ trainingExecutions: TrainingExecution[]; playerId: string }>()
);

export const loadByParametersSuccess = createAction(
    '[Training Execution] Load by parameters success',
    props<{ trainingExecutions: TrainingExecution[] }>()
);
