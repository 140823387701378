import { Action, createReducer, on } from '@ngrx/store';
import * as PlanTemplateActions from './plan-template.actions';
import { PlanTemplate } from '../../model/plan-template/plan-template.model';
import { clearState, initPlanTemplateState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'plan-templates';

export interface DefinitionState {
    planTemplates: PlanTemplate[];
}

export const initialState: DefinitionState = {
    planTemplates: [],
};

const removePlanTemplate = (state, { id }) => {
    return {
        ...state,
        planTemplates: state.planTemplates.filter((planTemplate) => planTemplate.id !== id),
    };
};

const updatePlanTemplatesList = (state, { planTemplates }) => {
    return {
        ...state,
        planTemplates,
    };
};

const updatePlanTemplate = (state, { planTemplate }) => {
    return {
        ...state,
        planTemplates: addOrUpdatePlanTemplate(planTemplate, state.planTemplates),
    };
};

const addOrUpdatePlanTemplate = (planTemplate: PlanTemplate, planTemplatesList: PlanTemplate[]) => {
    let existsPlanTemplate = false;

    planTemplatesList = planTemplatesList.map((savedPlanTemplate) => {
        if (savedPlanTemplate.getId() === planTemplate.getId()) {
            existsPlanTemplate = true;
            return planTemplate;
        }

        return savedPlanTemplate;
    });

    if (!existsPlanTemplate) planTemplatesList.unshift(planTemplate);

    return planTemplatesList;
};

// const initPlanTemplate = (state, newState) => {
//     if (!newState.planTemplates) return state;
//     return {
//         ...state,
//         planTemplates: [
//             ...newState.planTemplates.map((plainPlanTemplate) => plainToClass(PlanTemplate, plainPlanTemplate)),
//         ],
//     };
// };

export function reducer(state: DefinitionState | undefined, action: Action): any {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initPlanTemplateState, initState),
        on(PlanTemplateActions.loadAll, updatePlanTemplatesList),
        on(PlanTemplateActions.load, updatePlanTemplate),
        on(PlanTemplateActions.remove, removePlanTemplate)
    );

    return definitionReducer(state, action);
}
