import { Datetime } from '../../model/date/leet-date';

export interface TrainingExecutionsByParameters {
    playerId: string | null;
    coachCreatorId: string | null;
    status: string | null;
    trainingStatus: string | null;
    startAtMinDate: Datetime | null;
    startAtMaxDate: Datetime | null;
    finishedAtMinDate: Datetime | null;
    finishedAtMaxDate: Datetime | null;
    scheduledAtMinDate: Datetime | null;
    scheduledAtMaxDate: Datetime | null;
}

export interface CountTrainingExecutionsByParameters extends TrainingExecutionsByParameters {}

export const countTrainingExecutionsByCoachIdParameters = (coachId: string): CountTrainingExecutionsByParameters => {
    return {
        playerId: null,
        coachCreatorId: coachId,
        status: null,
        trainingStatus: null,
        startAtMinDate: null,
        startAtMaxDate: null,
        finishedAtMinDate: null,
        finishedAtMaxDate: null,
        scheduledAtMinDate: null,
        scheduledAtMaxDate: null,
    };
};
