import {ActionReducer, MetaReducer} from '@ngrx/store';
import {StorageService} from 'shared/shared/service/cache/storage.service';
import {StorageStoreStateKey} from '../../../shared/service/cache/cache.service';
import {createDate} from '../../model/date/leet-date';

export function metaReducerFactory(storageService: StorageService): MetaReducer {
    let lastSavedStoreTimestamp = createDate().timestamp;

    return (reducer: ActionReducer<any>) => (state, action) => {
        if (createDate().timestamp - lastSavedStoreTimestamp < 5) return reducer(state, action);

        console.time('SAVE STATE ON LOCAL STORAGE');
        storageService.set(StorageStoreStateKey, state);

        lastSavedStoreTimestamp = createDate().timestamp;
        console.timeEnd('SAVE STATE ON LOCAL STORAGE');
        return reducer(state, action);
    };
}

export const initState = (originalState, initialState) => {
    return initialState;
};
