import {createAction, props} from '@ngrx/store';
import {Material} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Material] Load all',
    props<{ materials: Material[] }>()
);

export const load = createAction(
    '[Material] Load',
    props<{ material: Material }>()
);


