import {createAction, props} from '@ngrx/store';
import {PlanExecution} from '../../model/plan-execution/plan-execution.model';

export const load = createAction(
  '[Plan Execution] Load',
  props<{ planExecution: PlanExecution }>()
);

export const loadByPlayer = createAction(
  '[Plan Execution] Load by player',
  props<{ planExecutions: PlanExecution[], playerId: string }>()
);

export const loadByCoach = createAction(
  '[Plan Execution] Load by coach',
  props<{ planExecutions: PlanExecution[], coachId: string }>()
);
