import { ActionStep, ContainerStep, Step } from './step/step.model';
import { Round } from './step/round.model';
import { v4 as uuid4 } from 'uuid';
import { TrainingSequenceStepsHelper } from '../../service/training-sequence/training-sequence-steps-helper.service';
import { Type } from 'class-transformer';
import { Rest } from './step/rest.model';
import { TrainingExercise } from './step/training-exercise.model';
import { BuyInStart } from './step/buy-in-start.model';
import { BuyInFinish } from './step/buy-in-finish.model';
import { BuyOutStart } from './step/buy-out-start.model';
import { BuyOutFinish } from './step/buy-out-finish.model';
import { ComplexStart } from './step/complex-start.model';
import { ComplexFinish } from './step/complex-finish.model';
import {
    BUY_IN_FINISH_STEP_TYPE,
    BUY_IN_START_STEP_TYPE,
    BUY_OUT_FINISH_STEP_TYPE,
    BUY_OUT_START_STEP_TYPE,
    COMPLEX_FINISH_STEP_TYPE,
    COMPLEX_START_STEP_TYPE,
    REST_STEP_TYPE,
    ROUND_STEP_TYPE,
    TRAINING_EXERCISE_STEP_TYPE,
} from './step/step.types';
import { arrayInsert } from '../../../shared/function/array.helpers';

export class ScalingSequence {
    private id: string;
    private name: string;

    @Type(() => Step, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: Rest, name: REST_STEP_TYPE },
                { value: TrainingExercise, name: TRAINING_EXERCISE_STEP_TYPE },
                { value: Round, name: ROUND_STEP_TYPE },
                { value: BuyInStart, name: BUY_IN_START_STEP_TYPE },
                { value: BuyInFinish, name: BUY_IN_FINISH_STEP_TYPE },
                { value: BuyOutStart, name: BUY_OUT_START_STEP_TYPE },
                { value: BuyOutFinish, name: BUY_OUT_FINISH_STEP_TYPE },
                { value: ComplexStart, name: COMPLEX_START_STEP_TYPE },
                { value: ComplexFinish, name: COMPLEX_FINISH_STEP_TYPE },
            ],
        },
    })
    private steps: Step[];

    constructor(id: string, name: string, steps: Step[]) {
        this.id = id;
        this.name = name;
        this.steps = steps;
    }

    updateName(name: string): void {
        this.name = name;
    }

    addActionStep(step: ActionStep, container: ContainerStep): void {
        this.addStepAtContainersEnd(step, container);
    }

    addStepAtContainersEnd(step: ActionStep, container: ContainerStep): void {
        const nextContainer = this.findNextContainer(container.getId());
        if (!nextContainer) {
            this.addStepAtTheEnd(step);
            return;
        }

        const nextContainerIndex = this.stepIndexById(nextContainer.getId());
        this.addStep(step, nextContainerIndex);
    }

    addStepAtTheEnd(step: Step): void {
        this.steps.push(step);
    }

    findNextContainer(stepId: string): ContainerStep | undefined {
        const stepIndex = this.stepIndexById(stepId);
        const stepsFromCurrentStep = this.steps.slice(stepIndex + 1);

        return stepsFromCurrentStep.find((stepNode) => {
            if (!stepNode) return false;
            return stepNode.isContainer;
        });
    }

    actionStepsInContainer(containerId: string): ActionStep[] {
        const container = this.containerById(containerId);
        if (!container) return [];

        const nextContainer = this.findNextContainer(containerId);
        if (!nextContainer) return this.steps.slice(this.stepIndexById(containerId) + 1, this.steps.length);
        return this.steps.slice(this.stepIndexById(containerId) + 1, this.stepIndexById(nextContainer.getId()));
    }

    addStep(step: Step, index: number): void {
        this.steps = arrayInsert(this.steps, [step], index);
        this.updateRoundNames();
    }

    addContainer(container: ContainerStep): void {
        this.steps.push(container);
    }

    addSteps(steps: Step[], index: number): void {
        this.steps = arrayInsert(this.steps, steps, index);
        this.updateRoundNames();
    }

    removeStep(stepLinkedId: string): void {
        this.steps = this.steps.filter((step) => step.getLinkedId() !== stepLinkedId);
        this.updateRoundNames();
    }

    removeSteps(stepLinkedIds: string[]): void {
        this.steps = this.steps.filter((step) => !stepLinkedIds.includes(step.getLinkedId()));
        this.updateRoundNames();
    }

    removeComplex(complexStartLinkedId: string): void {
        this.steps = TrainingSequenceStepsHelper.stepsRemovingComplex(complexStartLinkedId, this.steps).map((step) =>
            step.clone()
        );
    }

    stepById(stepId: string): Step | undefined {
        return this.steps.find((step) => step.getId() === stepId);
    }

    containerById(containerId: string): ContainerStep | undefined {
        return this.steps.find((step) => step.isContainer && step.getId() === containerId);
    }

    removeStepsByIds(stepIds: string[]): void {
        this.steps = this.steps.filter((step) => !stepIds.includes(step.getId()));
        this.updateRoundNames();
    }

    moveStepUpByLinkedId(stepLinkedId: string): void {
        this.stepsByLinkedId(stepLinkedId).forEach((step) => this.moveStepUpById(step.getId()));
    }

    moveStepDownByLinkedId(stepLinkedId: string): void {
        this.stepsByLinkedId(stepLinkedId).forEach((step) => this.moveStepDownById(step.getId()));
    }

    getStepsChangingStepIndex(stepIndex: number, newStepIndex: number): Step[] {
        if (stepIndex === newStepIndex) return this.steps;

        const step = this.stepByIndex(stepIndex);
        const replacedStep = this.stepByIndex(newStepIndex);
        const newStepsOrder: Step[] = [];

        for (let index = 0; index < this.steps.length; index++)
            if (index === stepIndex) newStepsOrder.push(replacedStep);
            else if (index === newStepIndex) newStepsOrder.push(step);
            else newStepsOrder.push(this.steps[index]);

        return newStepsOrder;
    }

    stepIndexById(stepId): number {
        return this.steps.findIndex((step, index) => step.getId() === stepId);
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getSteps(): Step[] {
        return this.steps;
    }

    clone(): ScalingSequence {
        const newScalingSequenceSteps = this.getSteps().map((step) => step.clone());
        return new ScalingSequence(uuid4(), this.getName(), newScalingSequenceSteps);
    }

    deleteStep(stepId: string): void {
        const stepIndex = this.stepIndexById(stepId);
        if (stepIndex === -1) return;

        this.steps.splice(stepIndex, 1);
    }

    deleteContainerStep(containerId: string): void {
        const containerIndex = this.stepIndexById(containerId);
        if (containerIndex == null) return;

        let nextContainerIndex = this.nextContainerIndex(containerId);
        if (nextContainerIndex == null) nextContainerIndex = this.steps.length;

        this.steps.splice(containerIndex, nextContainerIndex - containerIndex);
    }

    replaceContainerActionSteps(containerId: string, steps: Step[]): void {
        const containerIndex = this.stepIndexById(containerId);
        if (containerIndex === -1) return;

        let nextContainerIndex = this.nextContainerIndex(containerId);
        if (nextContainerIndex == null) nextContainerIndex = this.steps.length;

        this.steps.splice(containerIndex + 1, nextContainerIndex - containerIndex - 1);

        this.addSteps(steps, containerIndex + 1);
    }

    roundIndex(containerId: string): number {
        let roundIndex = -1;
        let index = 0;
        this.steps.forEach((step) => {
            if (!step.isType(ROUND_STEP_TYPE)) return;
            if (step.getId() === containerId) roundIndex = index;
            index++;
        });

        return roundIndex;
    }

    nextRoundId(currentRoundId: string): string | null {
        let nextRoundId = null;
        let currentRoundFound = false;
        this.steps.forEach((step) => {
            if (!step.isRound()) return;
            if (currentRoundFound && !nextRoundId) nextRoundId = step.getId();
            if (step.getId() === currentRoundId) currentRoundFound = true;
        });

        return nextRoundId;
    }

    containers(): ContainerStep[] {
        return this.steps.filter((step) => step.isContainer);
    }

    previousRoundId(currentRoundId: string): string | null {
        let previousRoundId = null;
        let lastRoundId = null;
        this.steps.forEach((step, index) => {
            if (!step.isRound()) return;
            if (step.getId() === currentRoundId && lastRoundId && !previousRoundId) previousRoundId = lastRoundId;
            lastRoundId = step.getId();
        });

        return previousRoundId;
    }

    private moveStepUpById(stepId: string): void {
        const stepIndex = this.stepIndexById(stepId);
        this.moveStepToNewIndex(stepIndex, stepIndex + 1);
    }

    private moveStepDownById(stepId: string): void {
        const stepIndex = this.stepIndexById(stepId);
        this.moveStepToNewIndex(stepIndex, stepIndex - 1);
    }

    private moveStepToNewIndex(stepIndex: number, newStepIndex: number): void {
        this.steps = this.getStepsChangingStepIndex(stepIndex, newStepIndex);
    }

    private stepsByLinkedId(stepLinkedId: string): Step[] {
        return this.steps.filter((step) => step.getLinkedId() === stepLinkedId);
    }

    private stepByIndex(stepIndex: number): Step | undefined {
        return this.steps.find((step, index) => stepIndex === index);
    }

    private nextContainerIndex(containerId: string): number | null {
        const nextContainer = this.findNextContainer(containerId);
        if (!nextContainer) return null;

        const nextContainerIndex = this.stepIndexById(nextContainer.getId());
        if (nextContainerIndex === -1) return null;

        return nextContainerIndex;
    }

    private updateRoundNames(): void {
        let roundPosition = 1;

        this.steps
            .filter((step) => step.isRound())
            .forEach((round: Round) => {
                round.updateName('Round ' + roundPosition);
                roundPosition++;
            });
    }
}
