import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';
import { TrainingExercise } from './training-exercise.model';

export abstract class Step {
    private id: string;
    private linkedId: string;
    private type: string;
    readonly isContainer: boolean;

    protected constructor(id: string, linkedId: string, type: string) {
        this.id = id ? id : uuid4();
        this.linkedId = linkedId ? linkedId : uuid4();
        this.type = type;
    }

    public assert(): void {
        this.doAssert();
    }

    public isType(stepType: string): boolean {
        return this.type === stepType;
    }

    public isRound(): boolean {
        return this.type === StepTypes.ROUND_STEP_TYPE;
    }

    public isExercise(): boolean {
        return this.type === StepTypes.TRAINING_EXERCISE_STEP_TYPE;
    }

    public isRest(): boolean {
        return this.type === StepTypes.REST_STEP_TYPE;
    }

    public isComplexStart(): boolean {
        return this.type === StepTypes.COMPLEX_START_STEP_TYPE;
    }

    public isComplexFinish(): boolean {
        return this.type === StepTypes.COMPLEX_FINISH_STEP_TYPE;
    }

    public isBuyInStart(): boolean {
        return this.type === StepTypes.BUY_IN_START_STEP_TYPE;
    }

    public isBuyInFinish(): boolean {
        return this.type === StepTypes.BUY_IN_FINISH_STEP_TYPE;
    }

    public isBuyOutStart(): boolean {
        return this.type === StepTypes.BUY_OUT_START_STEP_TYPE;
    }

    public isBuyOutFinish(): boolean {
        return this.type === StepTypes.BUY_OUT_FINISH_STEP_TYPE;
    }

    public getId(): string {
        return this.id;
    }

    abstract getSpecificStepType(): any;

    public getLinkedId(): string {
        return this.linkedId;
    }

    public getType(): string {
        return this.type;
    }

    public clone(maintainLinkedId = true): Step {
        return this.doClone(maintainLinkedId);
    }

    public getTitle(): string {
        return '';
    }

    protected abstract doAssert(): void;

    protected abstract doClone(maintainLinkedId: boolean): Step;
}

export abstract class ContainerStep extends Step {
    readonly isContainer: boolean = true;
}

export abstract class ActionStep extends Step {
    readonly isContainer: boolean = false;
}
