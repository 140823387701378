import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DefinitionState, STORE_KEY } from './leet-user.reducer';

export const getLeetUserState = createFeatureSelector<DefinitionState>(STORE_KEY);

const selectLeetUsers = (state: DefinitionState) => state.leetUsers;

export const get = createSelector(getLeetUserState, (state: DefinitionState, id: string) =>
    selectLeetUsers(state).find((leetUser) => leetUser.id === id)
);

export const getByIds = createSelector(getLeetUserState, (state: DefinitionState, ids: string[]) => {
    return selectLeetUsers(state).filter((leetUser) => ids.includes(leetUser.id));
});

export const getBySportId = createSelector(getLeetUserState, (state: DefinitionState, sportId: string) =>
    selectLeetUsers(state).filter((leetUserNode) => leetUserNode.sportId === sportId)
);
