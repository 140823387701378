import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { SessionSequence } from '../../model/definition/definition.models';
import * as SessionAction from '../../store/definition/session-sequence.actions';
import * as SessionSelector from '../../store/definition/session-sequence.selectors';
import { CreateOrUpdateSessionSequence } from '../../command/definition/session-sequence.commands';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { catchError, distinctUntilChanged, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { createDate, LeetDate } from '../../model/date/leet-date';
import { DefinitionMapper } from './definition.mapper';

@Injectable({
    providedIn: 'root',
})
export class SessionSequenceService {
    readonly findRoute = 'api-v1-find-session-sequence';
    readonly updateRoute = 'api-v1-update-session-sequence';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private definitionMapper: DefinitionMapper
    ) {
    }

    emptySequence(): Observable<SessionSequence> {
        const sequence: SessionSequence = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            availableTrainingSequenceTypes: [],
            createdAt: createDate(),
        };

        return of(sequence);
    }

    loadAll(): Observable<SessionSequence[]> {
        this.apiClient
            .get<SessionSequence[]>(this.findRoute)
            .pipe(
                mergeMap((rawSessionSequences) => this.definitionMapper.hydrateDefinitions(rawSessionSequences)),
                tap((sessionSequences) => this.store.dispatch(SessionAction.loadAll({ sessionSequences })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getAll();
    }

    load(id: string): Observable<SessionSequence> {
        this.apiClient
            .get<SessionSequence>(this.findRoute, { id })
            .pipe(
                mergeMap((rawSessionSequence) => this.definitionMapper.hydrateDefinition(rawSessionSequence)),
                tap((sessionSequence) => this.store.dispatch(SessionAction.load({ sessionSequence })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getById(id);
    }

    getById(id: string): Observable<SessionSequence> {
        return this.store.select(SessionSelector.get, id).pipe(distinctUntilChanged(areEquals), shareReplay());
    }

    getAll(): Observable<SessionSequence[]> {
        return this.store.select(SessionSelector.getAll).pipe(distinctUntilChanged(areEquals), shareReplay());
    }

    update(command: CreateOrUpdateSessionSequence): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.updateRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }
}
