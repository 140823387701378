import {createAction, props} from '@ngrx/store';
import {Location} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Location] Load all',
    props<{ locations: Location[] }>()
);

export const load = createAction(
    '[Location] Load',
    props<{ location: Location }>()
);


