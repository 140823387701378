import { LADDER_FOR_TIME_TYPE, TrainingSequence } from '../training-sequence.model';
import { ScalingSequence } from '../scaling-sequence.model';
import { TrainingExerciseRestriction } from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import {
    distanceMeasureTemplate,
    REPETITION_MEASURE,
    repetitionMeasureTemplate,
    serieMeasureTemplate,
    TIME_MEASURE,
    timeMeasureTemplate,
    weightMeasureTemplate,
} from '../../exercise-template/measure-template.model';
import { SequenceRestriction } from '../restriction/sequence-restriction.model';
import { MaxOneRoundPerSequenceRestriction } from '../restriction/max-one-round-per-sequence-restriction.model';
import { SeriesMeasureModeTrainingExerciseRestriction } from '../restriction/series-measure-mode-training-exercise-restriction.model';
import { TrainingExercise } from '../step/training-exercise.model';

export class LadderForTimeTrainingSequence extends TrainingSequence {
    private reps1: number | null;
    private reps2: number | null;
    private reps3: number | null;
    private reps4: number | null;
    private reps5: number | null;

    constructor(
        id: string,
        name: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string,
        reps1: number | null,
        reps2: number | null,
        reps3: number | null,
        reps4: number | null,
        reps5: number | null
    ) {
        super(id, name, LADDER_FOR_TIME_TYPE, mode, scalingSequences, sessionSequenceId, notes);
        this.reps1 = reps1;
        this.reps2 = reps2;
        this.reps3 = reps3;
        this.reps4 = reps4;
        this.reps5 = reps5;
    }

    _doAssert(): void {}

    public _sequenceRestrictions(): SequenceRestriction[] {
        return [new MaxOneRoundPerSequenceRestriction(), new SeriesMeasureModeTrainingExerciseRestriction()];
    }

    public _trainingExerciseRestriction(): TrainingExerciseRestriction | null {
        return {
            availableExerciseMeasureTemplates: [distanceMeasureTemplate(), repetitionMeasureTemplate()],
            availableMeasureModeTemplates: [serieMeasureTemplate()],
            weightMeasureTemplate: weightMeasureTemplate(),
        };
    }

    _clone(): TrainingSequence {
        const scalingSequences = this.scalingSequences.map((scalingSequence) => scalingSequence.clone());

        return new LadderForTimeTrainingSequence(
            this.getId(),
            this.getName(),
            this.getMode(),
            scalingSequences,
            this.getSessionSequenceId(),
            this.getNotes(),
            this.getReps1(),
            this.getReps2(),
            this.getReps3(),
            this.getReps4(),
            this.getReps5()
        );
    }

    getReps1(): number | number {
        return this.reps1;
    }

    getReps2(): number | number {
        return this.reps2;
    }

    getReps3(): number | number {
        return this.reps3;
    }

    getReps4(): number | number {
        return this.reps4;
    }

    getReps5(): number | number {
        return this.reps5;
    }

    updateReps1(value: number): void {
        if (value < 1) {
            return;
        }

        this.reps1 = value;
    }

    updateReps2(value: number): void {
        if (value < 1) {
            return;
        }

        this.reps2 = value;
    }

    updateReps3(value: number): void {
        if (value < 1) {
            return;
        }

        this.reps3 = value;
    }

    updateReps4(value: number): void {
        if (value < 1) {
            return;
        }

        this.reps4 = value;
    }

    updateReps5(value: number): void {
        if (value < 1) {
            return;
        }

        this.reps5 = value;
    }

    _canShowStep(stepLinkedId: string): boolean {
        const steps = this._stepsByLinkedId(stepLinkedId);
        return steps.length !== 0 && steps.filter((step) => step.isRound()).length === 0;
    }

    _canAddExerciseInOnlyOneRound(): boolean {
        return true;
    }

    _allRoundsShouldHaveSameSteps(): boolean {
        return false;
    }

    _isExerciseMeasureModeRequired(): boolean {
        return false;
    }

    _addRestAfterExercise(): boolean {
        return false;
    }

    _showExerciseMeasure(stepLinkedId: string): boolean {
        const steps = this._stepsByLinkedId(stepLinkedId);

        return (
            steps.length !== 0 &&
            steps.filter((step) => !step.isExercise()).length === 0 &&
            steps.filter((step: TrainingExercise) => {
                const measureType = step.getExerciseMeasure().getType();
                return measureType !== REPETITION_MEASURE && measureType !== TIME_MEASURE;
            }).length !== 0
        );
    }

    _canCreateComplex(): boolean {
        return false;
    }

    public _doSanetize(): void {}

    public _canAddBuyIn(): boolean {
        return false;
    }

    public _canAddBuyOut(): boolean {
        return false;
    }
}
