import Routing from './fos-js-router-min-js';

const routes = require('./fos_js_routes.json');

Routing.setRoutingData(routes);

export class Router {
  static generate(routeName, params, absolute) {
    return Routing.generate(routeName, params, absolute);
  }
}
