import { Action, createReducer, on } from '@ngrx/store';
import * as ExerciseTemplateListViewActions from './exercise-template-list-view.actions';
import { clearState, initExerciseTemplateListViewState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';
import { ExerciseTemplateListView } from '../../model/exercise-template-list-view/exercise-template-list-view.model';

export const STORE_KEY = 'exercise-template-list-view';

export interface DefinitionState {
    exerciseTemplateListViews: ExerciseTemplateListView[];
}

export const initialState: DefinitionState = {
    exerciseTemplateListViews: [],
};

const updateExerciseTemplateListViews = (state, { exerciseTemplateListViews }) => {
    return {
        ...state,
        exerciseTemplateListViews,
    };
};

export function reducer(state: DefinitionState | undefined, action: Action): any {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initExerciseTemplateListViewState, initState),
        on(ExerciseTemplateListViewActions.loadAll, updateExerciseTemplateListViews)
    );

    return definitionReducer(state, action);
}
