import {Action, createReducer, on} from '@ngrx/store';
import {clearState, initTrainingExecutionState} from '../meta/meta.actions';
import {initState} from '../meta/meta.reducers';
import {TrainingExecutionListView} from '../../model/training-execution-list-view/training-execution-list-view.model';
import * as TrainingExecutionListViewActions from './training-execution-list-view.actions';

export const STORE_KEY = 'training-execution-list-view';

export interface DefinitionState {
    trainingExecutionListViews: TrainingExecutionListView[];
}

export const initialState: DefinitionState = {
    trainingExecutionListViews: [],
};

const addOrUpdateTrainingExecutions = (state, { trainingExecutionListViews }) => {
    const trainingExecutionListViewIds = trainingExecutionListViews.map(
        (trainingExecutionListView) => trainingExecutionListView.id
    );
    return {
        ...state,
        trainingExecutionListViews: [
            ...state.trainingExecutionListViews.filter(
                (stateTrainingExecution) => !trainingExecutionListViewIds.includes(stateTrainingExecution.id)
            ),
            ...trainingExecutionListViews,
        ],
    };
};

const removeTrainingExecutionListView = (state, { id }) => {
    return {
        ...state,
        trainingExecutionListViews: [
            ...state.trainingExecutionListViews.filter((stateTrainingExecution) => stateTrainingExecution.id !== id),
        ],
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initTrainingExecutionState, initState),
        on(TrainingExecutionListViewActions.loadByParametersSuccess, addOrUpdateTrainingExecutions),
        on(TrainingExecutionListViewActions.remove,  removeTrainingExecutionListView),
    );

    return definitionReducer(state, action);
}
