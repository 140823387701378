import { createSelector } from '@ngrx/store';
import { getDefinitionState } from './definition.selector';
import { DefinitionState } from './definition.reducer';

const selectDifficulties = (state: DefinitionState) => state.difficulties;

export const getAll = createSelector(getDefinitionState, selectDifficulties);

export const get = createSelector(getDefinitionState, (state: DefinitionState, id: string) =>
    selectDifficulties(state).find((difficultyNode) => difficultyNode.id === id)
);

export const getPrivateScopedByCurrentUser = createSelector(
    getDefinitionState,
    (state: DefinitionState, leetUserOwnerId: string) =>
        selectDifficulties(state).filter((objective) => {
            if (objective.status === 'disabled') return false;
            return true;
        })
);
