import { TrainingTemplate } from '../training/training-template.model';
import { SelectedScalingSequence } from '../training-sequence/selected-scaling-sequence';
import { ActionStepExecution, StepExecution } from './step-execution';
import { TrainingObjectiveExecution } from './objective-execution/training-objective-execution';
import { Type } from 'class-transformer';
import {
    BUY_IN_FINISH_STEP_TYPE,
    BUY_IN_START_STEP_TYPE,
    BUY_OUT_FINISH_STEP_TYPE,
    BUY_OUT_START_STEP_TYPE,
    COMPLEX_FINISH_STEP_TYPE,
    COMPLEX_START_STEP_TYPE,
    REST_STEP_TYPE,
    ROUND_STEP_TYPE,
    TRAINING_EXERCISE_STEP_TYPE,
} from '../training-sequence/step/step.types';
import { RestExecution } from './rest-execution';
import { ExerciseExecution } from './exercise-execution';
import { RoundExecution } from './round-execution';
import { BuyInStartExecution } from './buy-in-start-execution';
import { BuyInFinishExecution } from './buy-in-finish-execution';
import { BuyOutStartExecution } from './buy-out-start-execution';
import { BuyOutFinishExecution } from './buy-out-finish-execution';
import { ComplexStartExecution } from './complex-start-execution';
import { ComplexFinishExecution } from './complex-finish-execution';
import { Gender } from '../gender/gender.model';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';
import { Datetime, formatDate } from '../date/leet-date';
import { ScalingSequence } from '../training-sequence/scaling-sequence.model';
import * as moment from 'moment';
import 'moment/locale/es';

export const TRAINING_STATUS_OPEN = 'open';
export const TRAINING_STATUS_FINISHED = 'finished';

export const STATUS_DELETED = 'deleted';
export const STATUS_ENABLED = 'enabled';
export const STATUS_DISABLED = 'disabled';

export class TrainingExecution {
    private id: string;
    private playerId: string;
    @Type(() => TrainingTemplate)
    private trainingTemplate: TrainingTemplate;
    private status: string;
    private creatorLeetUserId: string;
    private trainingStatus: string;
    private scheduledAt: Datetime;
    private startAt: Datetime;
    private finishedAt: Datetime;
    private painPoints: PainPoint[];
    private comfortLevel: number;
    private featureConfiguration: FeatureConfiguration;

    private selectedScalingSequences: SelectedScalingSequence[];
    @Type(() => StepExecution, {
        discriminator: {
            property: 'discriminatorType',
            subTypes: [
                { value: RestExecution, name: REST_STEP_TYPE },
                { value: ExerciseExecution, name: TRAINING_EXERCISE_STEP_TYPE },
                { value: RoundExecution, name: ROUND_STEP_TYPE },
                { value: BuyInStartExecution, name: BUY_IN_START_STEP_TYPE },
                { value: BuyInFinishExecution, name: BUY_IN_FINISH_STEP_TYPE },
                { value: BuyOutStartExecution, name: BUY_OUT_START_STEP_TYPE },
                { value: BuyOutFinishExecution, name: BUY_OUT_FINISH_STEP_TYPE },
                { value: ComplexStartExecution, name: COMPLEX_START_STEP_TYPE },
                { value: ComplexFinishExecution, name: COMPLEX_FINISH_STEP_TYPE },
            ],
        },
    })
    private stepExecutions: StepExecution[];
    private trainingObjectivesExecution: TrainingObjectiveExecution[];
    private selectedGender: Gender;
    private notes: string;
    private voiceMessageIds: string[];

    constructor(
        id: string,
        playerId: string,
        trainingTemplate: TrainingTemplate,
        status: string,
        trainingStatus: string,
        creatorLeetUserId: string,
        selectedScalingSequences: SelectedScalingSequence[],
        stepExecutions: StepExecution[],
        trainingObjectivesExecution: TrainingObjectiveExecution[],
        notes: string,
        selectedGender: Gender
    ) {
        this.id = id;
        this.playerId = playerId;
        this.trainingTemplate = trainingTemplate;
        this.status = status;
        this.trainingStatus = trainingStatus;
        this.creatorLeetUserId = creatorLeetUserId;
        this.selectedScalingSequences = selectedScalingSequences ? selectedScalingSequences : [];
        this.stepExecutions = stepExecutions ? stepExecutions : [];
        this.trainingObjectivesExecution = trainingObjectivesExecution ? trainingObjectivesExecution : [];
        this.notes = notes;
        this.selectedGender = selectedGender;
    }

    isOpened(): boolean {
        return this.trainingStatus === TRAINING_STATUS_OPEN;
    }

    isFinished(): boolean {
        return this.trainingStatus === TRAINING_STATUS_FINISHED;
    }

    isDisabled(): boolean {
        return this.status === STATUS_DISABLED;
    }

    skipStep(): void {
        if (!this.hasMorePendingSteps()) return;

        this.nextPendingStepExecution().skip();
    }

    getCreatorLeetUserId(): string {
        return this.creatorLeetUserId;
    }

    getStatus(): string {
        return this.status;
    }

    getSelectedScalingSequences(): SelectedScalingSequence[] {
        return this.selectedScalingSequences;
    }

    completeStep(stepExecution: StepExecution): void {
        if (!this.hasMorePendingSteps()) return;

        const nextPendingStep = this.nextPendingStepExecution();

        if (nextPendingStep.getStepId() !== stepExecution.getStepId()) return;

        nextPendingStep.complete(stepExecution);
    }

    getScheduledAt(): Datetime {
        return this.scheduledAt;
    }

    getStartAt(): Datetime {
        return this.startAt;
    }

    getFinishedAt(): Datetime {
        return this.finishedAt;
    }

    nextPendingStepExecution(): StepExecution | null {
        if (this.isFinished()) return null;

        for (const stepExecution of this.stepExecutions)
            if (stepExecution.isPending()) {
                if (!stepExecution.isExercise() && !stepExecution.isRest()) {
                    stepExecution.complete();
                    continue;
                }

                return stepExecution;
            }

        return null;
    }

    hasMorePendingSteps(): boolean {
        return !this.isFinished() && this.nextPendingStepExecution() !== null;
    }

    finish(): void {
        if (this.isFinished()) return;
        for (const stepExecution of this.stepExecutions) if (stepExecution.isPending()) stepExecution.skip();
        this.trainingStatus = TRAINING_STATUS_FINISHED;
    }

    getStepExecutions(): StepExecution[] {
        return this.stepExecutions;
    }

    getPlayerId(): string {
        return this.playerId;
    }

    getId(): string {
        return this.id;
    }

    getNotes(): string | null {
        return this.notes;
    }

    getTrainingObjectivesExecution(): TrainingObjectiveExecution[] {
        return this.trainingObjectivesExecution;
    }

    clone(keepId: boolean = false, serializer): TrainingExecution {
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            TrainingExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getTrainingTemplate(): TrainingTemplate {
        return this.trainingTemplate;
    }

    getTrainingStatus(): string {
        return this.trainingStatus;
    }

    getScheduledAtFormated(): string {
        return formatDate(this.scheduledAt.timestamp);
    }

    isStepContainer(stepExecution): boolean {
        return this.containerStepExecutions().some(
            (stepExecutionNode) => stepExecutionNode.getStepId() === stepExecution.getStepId()
        );
    }

    containerStepExecutions(): StepExecution[] {
        return this.stepExecutionsByType(true);
    }

    actionStepExecutions(): StepExecution[] {
        return this.stepExecutionsByType(false);
    }

    actionStepExecutionsInContainerStepExecutions(containerStepExecutionStepId: string): ActionStepExecution[] {
        const stepsResult = [];
        const stepExecutions = this.getStepExecutions();
        let areValidStepExecutions = false;
        stepExecutions.forEach((stepExecution) => {
            if (stepExecution.getStepId() === containerStepExecutionStepId) {
                areValidStepExecutions = true;
                return;
            }
            if (!areValidStepExecutions) return;
            if (stepExecution.isContainer) {
                areValidStepExecutions = false;
                return;
            }

            stepsResult.push(stepExecution);
        });
        return stepsResult;
    }

    stepExecutionsByType(isContainer: boolean): StepExecution[] {
        const stepsResult = [];
        const stepExecutions = this.getStepExecutions();
        stepExecutions.forEach((stepExecution) => {
            const stepId = stepExecution.getStepId();
            const step = this.mainScalingSequence().stepById(stepId);
            if (step.isContainer && isContainer) stepsResult.push(stepExecution);
            if (!step.isContainer && !isContainer) stepsResult.push(stepExecution);
        });

        return stepsResult;
    }

    mainScalingSequence(): ScalingSequence | null {
        return this.getTrainingTemplate().mainScalingSequence();
    }

    stepExecutionByStepId(stepId: string): StepExecution | undefined {
        return this.stepExecutions.find((stepExecution) => stepExecution.getStepId() === stepId);
    }

    isLastPendingStepExecution(): boolean {
        const lastStepExecution = [...this.stepExecutions].pop();
        if (!this.nextPendingStepExecution()) return true;
        return this.nextPendingStepExecution().getStepId() === lastStepExecution.getStepId();
    }

    getPainPoints(): PainPoint[] {
        return this.painPoints;
    }

    getPainPointsByDirection(direction: string): PainPoint[] {
        return this.painPoints.filter((painPoint) => {
            return direction === painPoint.direction;
        });
    }

    updatePainPoints(painPoints: PainPoint[]): void {
        this.painPoints = painPoints;
    }

    updatePainPointsByDirection(painPoints: PainPoint[], direction: string): void {
        const filteredPainPoints = this.painPoints.filter((painPoint) => {
            return direction !== painPoint.direction;
        });

        this.painPoints = filteredPainPoints.concat(painPoints);
    }

    getFormattedDuration(): string {
        if (!this.getFinishedAt() || !this.getStartAt()) return '';

        const finishedAt = moment.unix(this.getFinishedAt().timestamp);
        const startAt = moment.unix(this.getStartAt().timestamp);
        const diff = finishedAt.diff(startAt);
        return moment.utc(diff).format('mm:ss') + ' s';
    }

    getFormattedDate(): string {
        moment.locale('es');
        return moment.unix(this.getScheduledAt().timestamp).format('DD MMMM YYYY');
    }

    updateStepExecutions(stepExecutions: StepExecution[]): void {
        this.stepExecutions = stepExecutions;
    }

    getVoiceMessageIds(): string[] {
        return this.voiceMessageIds;
    }

    getVoiceMessageId(): string | null {
        if (this.voiceMessageIds.length > 0) return this.voiceMessageIds[0];
        else return null;
    }

    delete(): void {
        this.status = STATUS_DELETED;
    }

    getComfortLevel(): number {
        return this.comfortLevel;
    }

    updateComfortLevel(level: number): void {
        this.comfortLevel = level;
    }

    isPainMapEnabled(): boolean {
        return this.featureConfiguration.features.includes(PAIN_MAP_FEATURE);
    }

    isComfortLevelEnabled(): boolean {
        return this.featureConfiguration.features.includes(COMFORT_LEVEL_FEATURE);
    }
}

export interface PainPoint {
    xPxOffset: number;
    yPxOffset: number;
    direction: string;
    level: string;
}

export interface FeatureConfiguration {
    features: string[];
}

export const PAIN_LEVEL_LOW = 'pain_level_low';
export const PAIN_LEVEL_MEDIUM = 'pain_level_medium';
export const PAIN_LEVEL_HIGH = 'pain_level_high';

export const PAIN_MAP_FEATURE = 'pain_map';
export const COMFORT_LEVEL_FEATURE = 'comfort_level';
