import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { from, Observable, of, throwError } from 'rxjs';
import { MuscleGroup } from '../../model/definition/definition.models';
import { CreateOrUpdateMuscleGroup } from '../../command/definition/muscle-group.commands';
import * as MuscleAction from '../../store/definition/muscle-group.actions';
import * as MuscleSelector from '../../store/definition/muscle-group.selectors';
import {
    catchError,
    distinctUntilChanged,
    filter,
    mergeMap,
    shareReplay,
    switchMap,
    tap,
    toArray,
} from 'rxjs/operators';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { createDate } from '../../model/date/leet-date';
import { DefinitionMapper } from './definition.mapper';
import memorize from 'memorize-decorator';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { Session } from '../session/session.service';
import { sortByName } from '../../function/definition.operators';

@Injectable({
    providedIn: 'root',
})
export class MuscleGroupService {
    readonly findRoute = 'api-v1-find-muscle-group';
    readonly updateRoute = 'api-v1-update-muscle-group';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private definitionMapper: DefinitionMapper,
        private session: Session
    ) {
    }

    getEmpty(): Observable<MuscleGroup> {
        const muscleGroup: MuscleGroup = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            scope: {
                scope: 'SCOPE_PUBLIC_ADMIN',
                leetUserOwnerId: '',
            },
            createdAt: createDate(),
        };

        return of(muscleGroup);
    }

    loadAll(): Observable<MuscleGroup[]> {
        this.apiClient
            .get<MuscleGroup[]>(this.findRoute)
            .pipe(
                mergeMap((rawMuscleGroups) => this.definitionMapper.hydrateDefinitions(rawMuscleGroups)),
                tap((muscleGroups) => this.store.dispatch(MuscleAction.loadAll({ muscleGroups })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getAll();
    }

    getPrivateScopedByCurrentUser(): Observable<MuscleGroup[]> {
        return this.store
            .select(MuscleSelector.getPrivateScopedByCurrentUser, this.session.currentAuthUser().entityId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    getPublicAdminScoped(): Observable<MuscleGroup[]> {
        return this.store
            .select(MuscleSelector.getPublicAdminScoped)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    @memorize({ ttl: 1000 })
    load(id: string): Observable<MuscleGroup> {
        this.apiClient
            .get<MuscleGroup>(this.findRoute, { id })
            .pipe(
                mergeMap((rawMuscleGroup) => this.definitionMapper.hydrateDefinition(rawMuscleGroup)),
                tap((muscleGroup) => this.store.dispatch(MuscleAction.load({ muscleGroup })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getById(id);
    }

    getById(id: string): Observable<MuscleGroup> {
        return this.store
            .select(MuscleSelector.get, id)
            .pipe(distinctUntilChanged(areEquals), shareReplay(), skipWhileNullOrUndefined());
    }

    getAll(): Observable<MuscleGroup[]> {
        return this.store
            .select(MuscleSelector.getAll)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay(), sortByName());
    }

    getByIds(ids: string[]): Observable<MuscleGroup[]> {
        return this.getAll().pipe(
            mergeMap((muscleGroups: MuscleGroup[]) =>
                from(muscleGroups).pipe(
                    filter((muscleGroup) => ids.includes(muscleGroup.id)),
                    toArray()
                )
            )
        );
    }

    update(command: CreateOrUpdateMuscleGroup): Observable<EntityUpdated> {
        return this.apiClient.post<MuscleGroup>(this.updateRoute, command, command).pipe(
            switchMap(() => {
                const response: EntityUpdated = { id: command.id };
                return of(response);
            }),
            tap(() => this.load(command.id)),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
}
