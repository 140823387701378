import {createAction, props} from '@ngrx/store';
import {Chat} from '../../model/chat/chat.model';

export const loadSession = createAction(
    '[Chat] Load Session',
    props<{ chats: Chat[] }>()
);



