import { ActionStep, Step } from './step.model';
import { ExerciseTemplate } from '../../exercise-template/exercise-template.model';
import { Measure } from '../measure.model';
import * as StepTypes from './step.types';
import {
    TrainingExerciseRestriction
} from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import { GENDER_MODE_UNIFIED, MeasureTemplate, TIME_MEASURE } from '../../exercise-template/measure-template.model';
import { v4 as uuid4 } from 'uuid';
import {
    MeasureUnit,
    TIME_UNIT_HOUR,
    TIME_UNIT_MINUTE,
    TIME_UNIT_SECOND,
} from '../../exercise-template/measure-unit.model';
import { Type } from 'class-transformer';

export const TIME_MODE_MEASURE_UNITS: string[] = [TIME_UNIT_SECOND, TIME_UNIT_MINUTE];

export class TrainingExercise extends ActionStep {
    readonly exerciseTemplate: ExerciseTemplate;
    private intraExerciseRestTime: number | null;
    @Type(() => Measure)
    private measureMode: Measure;
    @Type(() => Measure)
    private exerciseMeasure: Measure;
    @Type(() => Measure)
    private weightMeasure: Measure | null;
    private exerciseMeasureUnitValue: number | null;
    private weightMaleMeasureUnitValue: number | null;
    private weightFemaleMeasureUnitValue: number | null;
    private measureModeUnitValue: number | null;
    private restriction: TrainingExerciseRestriction = null;

    constructor(
        id: string,
        linkedId: string,
        exerciseTemplate: ExerciseTemplate,
        exerciseMeasure: Measure,
        measureMode: Measure,
        weightMeasure: Measure | null,
        exerciseMeasureUnitValue: number | null,
        weightMaleMeasureUnitValue: number | null,
        weightFemaleMeasureUnitValue: number | null,
        measureModeUnitValue: number | null,
        restriction: TrainingExerciseRestriction | null,
        intraExerciseRestTime: number | null
    ) {
        super(id, linkedId, StepTypes.TRAINING_EXERCISE_STEP_TYPE);

        this.exerciseTemplate = exerciseTemplate;
        this.exerciseMeasure = exerciseMeasure;
        this.measureMode = measureMode;
        this.weightMeasure = weightMeasure;
        this.exerciseMeasureUnitValue = exerciseMeasureUnitValue;
        this.weightMaleMeasureUnitValue = weightMaleMeasureUnitValue;
        this.weightFemaleMeasureUnitValue = weightFemaleMeasureUnitValue;
        this.measureModeUnitValue = measureModeUnitValue;
        this.restriction = restriction;
        this.intraExerciseRestTime = intraExerciseRestTime;
        this.assert();
    }

    updateMeasureModeUnitValue(value): void {
        this.measureModeUnitValue = value;
    }

    getExerciseTemplate(): ExerciseTemplate {
        return this.exerciseTemplate;
    }

    hasNextMeasureMode(): boolean {
        return this.measureMode.getType() === TIME_MEASURE;
    }

    getExerciseTemplateId(): string {
        return this.exerciseTemplate.id;
    }

    nextMeasureMode(): void {
        if (!this.hasNextMeasureMode()) return;

        const nextMeasureModeUnit = TIME_MODE_MEASURE_UNITS.filter(
            (measureUnitValue) => measureUnitValue !== this.measureMode.getUnit().value
        ).pop();

        this.measureMode = new Measure(TIME_MEASURE, { value: nextMeasureModeUnit }, GENDER_MODE_UNIFIED);
    }

    getExerciseMeasure(): Measure {
        return this.exerciseMeasure;
    }

    getMeasureModeType(): string {
        return this.measureMode.getType();
    }

    getExerciseMeasureType(): string {
        return this.exerciseMeasure.getType();
    }

    getMeasureMode(): Measure {
        return this.measureMode;
    }

    getWeightMeasure(): Measure | null {
        return this.weightMeasure;
    }

    getWeightMeasureType(): string {
        if (!this.weightMeasure) return '';
        return this.weightMeasure.getType();
    }

    getExerciseMeasureUnitValue(): number {
        if (this.exerciseMeasureUnitValue === null) return 0;
        return this.exerciseMeasureUnitValue;
    }

    getWeightMaleMeasureUnitValue(): number {
        if (this.weightMaleMeasureUnitValue === null) return 0;
        return this.weightMaleMeasureUnitValue;
    }

    getRestriction(): TrainingExerciseRestriction | null {
        return this.restriction;
    }

    getWeightFemaleMeasureUnitValue(): number | null {
        if (this.weightFemaleMeasureUnitValue === null) return 0;
        return this.weightFemaleMeasureUnitValue;
    }

    getMeasureModeUnitValue(): number {
        if (!this.measureModeUnitValue) return 0;
        return this.measureModeUnitValue;
    }

    getMeasureModeSeconds(): number {
        if (!this.getExerciseMeasure().isTimeType()) return 0;
        const timeUnit = this.getExerciseMeasure().getUnit().value;
        const unitValue = this.getExerciseMeasureUnitValue();
        if (timeUnit === TIME_UNIT_SECOND) return unitValue;
        else if (timeUnit === TIME_UNIT_MINUTE) return unitValue * 60;
        else if (timeUnit === TIME_UNIT_HOUR) return unitValue * 60 * 60;
    }

    isWeightMeasureGenderModeUnified(): boolean {
        return this.hasWeightMeasure() && this.weightMeasure.isGenderModeUnified();
    }

    hasWeightMeasure(): boolean {
        return this.getWeightMeasure() !== null;
    }

    hasNextUnitInCurrentWeightMeasure(): boolean {
        return (
            this.hasWeightMeasure() &&
            this.getNextWeightMeasure().getUnit().value !== this.weightMeasure.getUnit().value
        );
    }

    nextUnitInCurrentWeightMeasure(): void {
        if (!this.hasNextUnitInCurrentWeightMeasure()) return;

        this.weightMeasure = this.getNextWeightMeasure();
    }

    availableWeightMeasureUnits(): MeasureUnit[] {
        return this.exerciseTemplate.weightMeasureTemplate.units;
    }

    canChangeExerciseMeasureType(type: string): boolean {
        return (
            this.exerciseMeasure.getType() !== type &&
            this.availableMeasureTemplates().filter((measureTemplate) => measureTemplate.type === type).length === 1
        );
    }

    nextExerciseMeasureType(): void {
        const remainingMeasureTemplates = this.remainingExerciseMeasureTemplates();
        let measureTemplateChanged = false;
        remainingMeasureTemplates.forEach((measureTemplate) => {
            if (this.canChangeExerciseMeasureType(measureTemplate.type) && !measureTemplateChanged) {
                this.changeExerciseMeasureType(measureTemplate.type);
                measureTemplateChanged = true;
            }
        });
    }

    remainingExerciseMeasureTemplates(): MeasureTemplate[] {
        return this.availableMeasureTemplates().filter(
            (measureTemplate) => measureTemplate.type !== this.exerciseMeasure.getType()
        );
    }

    hasNextExerciseMeasureTemplateType(): boolean {
        return this.remainingExerciseMeasureTemplates().length > 0;
    }

    changeExerciseMeasureType(type: string): void {
        if (!this.canChangeExerciseMeasureType(type)) return;

        const newMeasureTemplate: MeasureTemplate = this.availableMeasureTemplates().filter(
            (measureTemplate) => measureTemplate.type === type
        )[0];

        this.exerciseMeasure = new Measure(
            newMeasureTemplate.type,
            newMeasureTemplate.units[0],
            newMeasureTemplate.genderMode
        );
    }

    hasNextUnitInCurrentExerciseMeasure(): boolean {
        return this.availableCurrentExerciseMeasureUnits().length > 1;
    }

    nextUnitInCurrentExerciseMeasure(restriction?: TrainingExerciseRestriction): void {
        if (!restriction) restriction = this.restriction;
        if (!this.hasNextUnitInCurrentExerciseMeasure()) return;

        this.exerciseMeasure = this.exerciseMeasureWithNextUnit(restriction);
    }

    availableMeasureTemplates(): MeasureTemplate[] {
        const validMeasureTemplates: MeasureTemplate[] = [];
        const availableMeasureTemplateTypes = this.restriction.availableExerciseMeasureTemplates.map(
            (measureTemplate) => measureTemplate.type
        );

        this.exerciseTemplate.availableMeasureTemplates.forEach((measureTemplate) => {
            if (!availableMeasureTemplateTypes.includes(measureTemplate.type)) return;

            const availableMeasureUnitValues = this.restriction.availableExerciseMeasureTemplates
                .filter((restrictionMeasureTemplate) => restrictionMeasureTemplate.type === measureTemplate.type)
                .pop()
                .units.map((unit) => unit.value);

            const availableMeasureUnits = measureTemplate.units.filter((unit) =>
                availableMeasureUnitValues.includes(unit.value)
            );

            if (availableMeasureUnits.length === 0) return;

            validMeasureTemplates.push({
                type: measureTemplate.type,
                units: availableMeasureUnits,
                genderMode: measureTemplate.genderMode,
            });
        });

        return validMeasureTemplates;
    }

    updateWeightMeasureUnitValue(value: number, force = false): void {
        if (!this.isWeightMeasureGenderModeUnified() && !force) return;

        this.weightFemaleMeasureUnitValue = value;
        this.weightMaleMeasureUnitValue = value;
    }

    updateWeightMaleMeasureUnitValue(value: number): void {
        if (this.isWeightMeasureGenderModeUnified()) return;

        this.weightMaleMeasureUnitValue = value;
    }

    updateWeightFemaleMeasureUnitValue(value: number): void {
        if (this.isWeightMeasureGenderModeUnified()) return;

        this.weightFemaleMeasureUnitValue = value;
    }

    updateExerciseMeasureUnitValue(value: number): void {
        this.exerciseMeasureUnitValue = value;
    }

    getTitle(): string {
        return this.exerciseTemplate.name;
    }

    getSpecificStepType(): Step {
        return this as TrainingExercise;
    }

    updateWeightMeasureUnit(measureUnit: MeasureUnit): void {
        measureUnit = this.availableWeightMeasureUnits().find(
            (measureUnitNode) => measureUnitNode.value === measureUnit.value
        );
        if (!measureUnit) return;

        this.weightMeasure = new Measure(this.weightMeasure.getType(), measureUnit, this.weightMeasure.getGenderMode());
    }

    updateExerciseMeasureUnit(measureUnit: MeasureUnit): void {
        measureUnit = this.availableCurrentExerciseMeasureUnits().find(
            (measureUnitNode) => measureUnitNode.value === measureUnit.value
        );
        if (!measureUnit) return;

        this.exerciseMeasure = new Measure(
            this.exerciseMeasure.getType(),
            measureUnit,
            this.exerciseMeasure.getGenderMode()
        );
    }

    availableCurrentExerciseMeasureUnits(): MeasureUnit[] {
        const measureTemplates = this.availableMeasureTemplates();
        for (const measureTemplate of measureTemplates) {
            if (measureTemplate.type !== this.exerciseMeasure.getType()) continue;
            return measureTemplate.units;
        }
    }

    getWeightMeasureUnitType(): string {
        return this.getWeightMeasure().getUnit().value;
    }

    getExerciseMeasureUnitType(): string {
        return this.getExerciseMeasure().getUnit().value;
    }

    updateRestriction(restriction: TrainingExerciseRestriction): void {
        this.restriction = restriction;
    }

    updateIntraExerciseRestTime(intraExerciseRestTime: number) {
        this.intraExerciseRestTime = intraExerciseRestTime;
    }

    getIntraExerciseRestTime(): number {
        if (!this.intraExerciseRestTime) return 0;
        return this.intraExerciseRestTime;
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): TrainingExercise {
        const clonedWeightMeasure = this.weightMeasure ? this.weightMeasure.clone() : null;
        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new TrainingExercise(
            uuid4(),
            linkedId,
            this.getExerciseTemplate(),
            this.exerciseMeasure.clone(),
            this.measureMode.clone(),
            clonedWeightMeasure,
            this.getExerciseMeasureUnitValue(),
            this.weightMaleMeasureUnitValue,
            this.weightFemaleMeasureUnitValue,
            this.getMeasureModeUnitValue(),
            this.getRestriction(),
            this.getIntraExerciseRestTime()
        );
    }

    private getNextWeightMeasure(): Measure {
        const availableWeightMeasureUnits = this.exerciseTemplate.weightMeasureTemplate.units.map((unit) => unit.value);

        const nextWeightMeasureIndex =
            (availableWeightMeasureUnits.indexOf(this.weightMeasure.getUnit().value) + 1) %
            availableWeightMeasureUnits.length;

        const measureUnit = {
            value: availableWeightMeasureUnits[nextWeightMeasureIndex],
        };

        return new Measure(this.weightMeasure.getType(), measureUnit, this.weightMeasure.getGenderMode());
    }

    private exerciseMeasureWithNextUnit(restriction: TrainingExerciseRestriction): Measure {
        const exerciseMeasureUnits = this.availableCurrentExerciseMeasureUnits().map(
            (measureUnit) => measureUnit.value
        );
        const nextExerciseMeasureUnitIndex =
            (exerciseMeasureUnits.indexOf(this.exerciseMeasure.getUnit().value) + 1) % exerciseMeasureUnits.length;

        const nextMeasureUnit = {
            value: exerciseMeasureUnits[nextExerciseMeasureUnitIndex],
        };

        return new Measure(this.exerciseMeasure.getType(), nextMeasureUnit, this.exerciseMeasure.getGenderMode());
    }
}
