import {
    DifficultyLevel,
    Location,
    Material,
    MuscleGroup,
    Objective,
    Sport,
    TimePlanning,
    TrainingDuration,
} from '../definition/definition.models';
import {
    AMRAP_TYPE,
    EMOM_TYPE,
    FOR_TIME_TYPE,
    FREE_TYPE,
    LADDER_FOR_TIME_TYPE,
    REPS_STATION_TYPE,
    ROUNDS_FOR_TIME_TYPE,
    SERIES_FOR_REPS_AND_REST_TYPE,
    SERIES_FOR_TIME_AND_REST_TYPE,
    TABATA_TENNIS_TYPE,
    TABATA_TYPE,
    TIME_STATIONS_TYPE,
    TrainingSequence,
    UNBROKEN_TYPE,
} from '../training-sequence/training-sequence.model';
import { Type } from 'class-transformer';
import { FreeTrainingSequence } from '../training-sequence/type/free-training-sequence.model';
import { AmrapTrainingSequence } from '../training-sequence/type/amrap-training-sequence.model';
import { EmomTrainingSequence } from '../training-sequence/type/emom-training-sequence.model';
import { ForTimeSequence } from '../training-sequence/type/for-time-sequence.model';
import { LadderForTimeTrainingSequence } from '../training-sequence/type/ladder-for-time-training-sequence.model';
import { RepsStationsSequence } from '../training-sequence/type/reps-stations-sequence.model';
import { RoundsForTimeSequence } from '../training-sequence/type/rounds-for-time-sequence.model';
import { SeriesForRepsAndRestTrainingSequence } from '../training-sequence/type/series-for-reps-and-rest-training-sequence.model';
import { SeriesForTimeAndRestTrainingSequence } from '../training-sequence/type/series-for-time-and-rest-training-sequence.model';
import { TabataTennisTrainingSequence } from '../training-sequence/type/tabata-tennis-training-sequence.model';
import { TabataTrainingSequence } from '../training-sequence/type/tabata-training-sequence.model';
import { TimeStationsSequence } from '../training-sequence/type/time-stations-sequence.model';
import { UnbrokenTrainingSequence } from '../training-sequence/type/unbroken-training-sequence.model';
import { v4 as uuid4 } from 'uuid';
import { LeetDate } from '../date/leet-date';
import { ScalingSequence } from '../training-sequence/scaling-sequence.model';
import { TrainingExercise } from '../training-sequence/step/training-exercise.model';

export const TRAINING_TEMPLATE_ENABLED_STATUS = 'enabled';
export const TRAINING_TEMPLATE_DISABLED_STATUS = 'disabled';
export const TRAINING_TEMPLATE_DELETED_STATUS = 'deleted';

const TRAINING_TEMPLATE_PUBLIC_VISIBILITY = 'public_visiblity';

export class TrainingTemplate {
    id: string;
    name: string;
    description: string;
    duration: TrainingDuration;
    difficultyLevel: DifficultyLevel;
    locations: Location[];
    objectives: Objective[];
    status: string;
    imageId: string;
    @Type(() => TrainingSequence, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: FreeTrainingSequence, name: FREE_TYPE },
                { value: AmrapTrainingSequence, name: AMRAP_TYPE },
                { value: EmomTrainingSequence, name: EMOM_TYPE },
                { value: ForTimeSequence, name: FOR_TIME_TYPE },
                {
                    value: LadderForTimeTrainingSequence,
                    name: LADDER_FOR_TIME_TYPE,
                },
                { value: RepsStationsSequence, name: REPS_STATION_TYPE },
                { value: RoundsForTimeSequence, name: ROUNDS_FOR_TIME_TYPE },
                {
                    value: SeriesForRepsAndRestTrainingSequence,
                    name: SERIES_FOR_REPS_AND_REST_TYPE,
                },
                {
                    value: SeriesForTimeAndRestTrainingSequence,
                    name: SERIES_FOR_TIME_AND_REST_TYPE,
                },
                {
                    value: TabataTennisTrainingSequence,
                    name: TABATA_TENNIS_TYPE,
                },
                { value: TabataTrainingSequence, name: TABATA_TYPE },
                { value: TimeStationsSequence, name: TIME_STATIONS_TYPE },
                { value: UnbrokenTrainingSequence, name: UNBROKEN_TYPE },
            ],
        },
    })
    sequences: TrainingSequence[];

    sports: Sport[];
    timePlannings: TimePlanning[];
    notes: string | null;
    creatorLeetUserId: string;
    visibility: string;
    allowedUserIds: string[];
    @Type(() => LeetDate)
    createdAt: LeetDate;

    constructor(
        id: string,
        name: string,
        duration: TrainingDuration,
        difficultyLevel: DifficultyLevel,
        locations: Location[],
        objectives: Objective[],
        status: string,
        sequences: TrainingSequence[],
        sports: Sport[],
        timePlannings: TimePlanning[],
        notes: string | null,
        creatorId: string,
        visibility: string,
        allowedUserIds: string[],
        description: string,
        imageId: string
    ) {
        this.id = id;
        this.name = name;
        this.duration = duration;
        this.difficultyLevel = difficultyLevel;
        this.locations = locations;
        this.objectives = objectives;
        this.status = status;
        this.sequences = sequences;
        this.sports = sports;
        this.timePlannings = timePlannings;
        this.notes = notes;
        this.creatorLeetUserId = creatorId;
        this.visibility = visibility;
        this.allowedUserIds = allowedUserIds;
        this.description = description;
        this.imageId = imageId;
    }

    static empty(): TrainingTemplate {
        return new TrainingTemplate(uuid4(), '', null, null, [], [], 'enabled', [], [], [], null, '', '', [], '', '');
    }

    public isEnabled(): boolean {
        return this.status === TRAINING_TEMPLATE_ENABLED_STATUS;
    }

    public isDisabled(): boolean {
        return this.status === TRAINING_TEMPLATE_DISABLED_STATUS;
    }

    public getStatus(): string {
        return this.status;
    }

    public addSequence(sequence: TrainingSequence): void {
        if (this.existsSequence(sequence.getId())) return;

        this.sequences.push(sequence);
    }

    public removeSequence(sequenceId: string): void {
        this.sequences = this.sequences.filter((sequence) => sequence.getId() !== sequenceId);
    }

    public existsSequence(sequenceId: string): boolean {
        return this.sequenceById(sequenceId) !== null;
    }

    public sequenceById(sequenceId: string): TrainingSequence | null {
        for (const sequence of this.sequences) if (sequence.getId() === sequenceId) return sequence;

        return null;
    }

    public getId(): string {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    getImageId(): string {
        return this.imageId;
    }

    public getDuration(): TrainingDuration {
        return this.duration;
    }

    public getDifficultyLevel(): DifficultyLevel {
        return this.difficultyLevel;
    }

    public difficultyLevelId(): string {
        return this.difficultyLevel.id;
    }

    public getLocations(): Location[] {
        return this.locations;
    }

    public getObjectives(): Objective[] {
        return this.objectives;
    }

    public getSequences(): TrainingSequence[] {
        return this.sequences;
    }

    public getSports(): Sport[] {
        return this.sports;
    }

    public getTimePlannings(): TimePlanning[] {
        return this.timePlannings;
    }

    public getNotes(): string | null {
        return this.notes;
    }

    public canSequenceBeRequired(sequenceId: string): boolean {
        const sequence = this.sequenceById(sequenceId);
        return sequence && !sequence._isRequired();
    }

    public canSequenceBeOptional(sequenceId: string): boolean {
        const sequence = this.sequenceById(sequenceId);
        return sequence && !sequence._isOptional() && this.requiredSequences().length > 1;
    }

    public changeSequenceToOptionalMode(sequenceId: string): void {
        if (!this.canSequenceBeOptional(sequenceId)) return;

        this.sequenceById(sequenceId)._changeToOptionalMode();
    }

    public changeSequenceToRequiredMode(sequenceId: string): void {
        if (!this.canSequenceBeRequired(sequenceId)) return;

        this.sequenceById(sequenceId)._changeToRequiredMode();
    }

    public clone(replaceId = true): TrainingTemplate {
        const sequences = this.sequences.map((sequence) => sequence._clone());
        const id = replaceId ? uuid4() : this.getId();

        return new TrainingTemplate(
            id,
            this.name,
            this.duration,
            this.difficultyLevel,
            this.locations,
            this.objectives,
            this.status,
            sequences,
            this.sports,
            this.timePlannings,
            this.notes,
            this.creatorLeetUserId,
            this.visibility,
            this.allowedUserIds,
            this.description,
            this.imageId
        );
    }

    getAllowedUserIds() {
        return this.allowedUserIds;
    }

    mainScalingSequence(): ScalingSequence | null {
        if (this.sequences.length === 0) return null;

        return this.sequences[0].mainScalingSequence();
    }

    muscleGroups(): MuscleGroup[] {
        return this.mainScalingSequence()
            .getSteps()
            .filter((step) => step.isExercise())
            .map((exercise: TrainingExercise) => exercise.getExerciseTemplate().muscleGroups)
            .reduce((accumulator, currentValue) => [...accumulator, ...currentValue]);
    }

    muscleGroupIds(): string[] {
        return this.muscleGroups().map((muscleGroup) => muscleGroup.id);
    }

    materials(): Material[] {
        return this.mainScalingSequence()
            .getSteps()
            .filter((step) => step.isExercise())
            .map((exercise: TrainingExercise) => exercise.getExerciseTemplate().materials)
            .reduce((accumulator, currentValue) => [...accumulator, ...currentValue]);
    }

    materialIds(): string[] {
        return this.materials().map((material) => material.id);
    }

    objectiveIds(): string[] {
        return this.objectives.map((objective) => objective.id);
    }

    isPublic() {
        return this.visibility === TRAINING_TEMPLATE_PUBLIC_VISIBILITY;
    }

    featureIds() {
        return [...this.muscleGroupIds(), this.difficultyLevelId(), ...this.materialIds(), ...this.objectiveIds()];
    }

    private requiredSequences(): TrainingSequence[] {
        return this.sequences.filter((sequence) => sequence._isRequired());
    }
}
