import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FilterItem} from '../leet-searchable-filter-input-select/leet-searchable-filter-input-select.component';
import {first, map, tap} from 'rxjs/operators';
import {BaseComponent} from '../../../shared/page/base-component.service';
import {Modal} from '../../../shared/service/ionic/modal.ionic';

@Component({
    selector: 'app-leet-searchable-filter-input-select-modal',
    template: `
        <ion-header>
            <!--            <app-swipe-bar></app-swipe-bar>-->
            <ion-toolbar class="box-shadow-unset">
                <ion-title>{{ label | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content scroll-y="false" [fullscreen]="true">
            <ion-list class="d-flex flex-column mh-100">
                <div class="d-flex align-items-center">
                    <ion-searchbar
                        placeholder="{{ 'Search...' | translate }}"
                        [(ngModel)]="searchValue"
                    ></ion-searchbar>
                </div>
                <div class="overflow-auto mt-2">
                    <ion-item *ngFor="let item of availableItems$ | async | dropdownSearchFilterPipe: searchValue">
                        <ion-label>
                            <h2>{{ item.name }}</h2>
                        </ion-label>
                        <ion-checkbox
                            [checked]="selectedItemIds.includes(item.id)"
                            (ionChange)="checkItem($event, item.id)"
                            slot="start"
                        ></ion-checkbox>
                    </ion-item>
                    <ng-container>
                        <div class="pt-5"></div>
                    </ng-container>
                    <ng-container>
                        <div class="pt-5"></div>
                    </ng-container>
                </div>
            </ion-list>
            <div class="save-btn-container" (click)="modalController.dismiss()">
                <ion-button class="btn-primary">{{ 'Save' | translate }}</ion-button>
            </div>
        </ion-content>
    `,
    styleUrls: ['./leet-searchable-filter-input-select-modal.component.scss'],
})
export class LeetSearchableFilterInputSelectModalComponent extends BaseComponent implements OnInit {
    @Input() isMultiple;
    @Input() selectedItems$: BehaviorSubject<FilterItem[]> = new BehaviorSubject<FilterItem[]>([]);
    @Input() availableItems$: BehaviorSubject<FilterItem[]> = new BehaviorSubject<FilterItem[]>([]);
    @Input() label = '';
    selectedItemIds: string[] = [];
    searchValue = '';

    constructor(public modalController: Modal) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(
            this.selectedItems$.pipe(
                tap((selectedItems) => (this.selectedItemIds = selectedItems.map((item) => item.id)))
            )
        );
    }

    async checkItem($event: any, itemId: string) {
        if ($event.detail.checked) {
            if (!this.isMultiple) this.selectedItemIds = [];
            this.selectedItemIds.push(itemId);
        } else this.selectedItemIds = this.selectedItemIds.filter((selectedItemId) => itemId !== selectedItemId);

        const selectedItems = await this.availableItems$
            .pipe(
                map((availableItems) => availableItems.filter((item) => this.selectedItemIds.includes(item.id))),
                first()
            )
            .toPromise();

        this.selectedItems$.next(selectedItems);
    }
}
