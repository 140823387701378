import {createAction, props} from '@ngrx/store';
import {Club} from '../../model/club/club.model';

export const load = createAction(
    '[Club] Load',
    props<{ club: Club }>()
);

export const loadAll = createAction(
    '[Club] Load all',
    props<{ clubs: Club[] }>()
);


