import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class JsService {
    constructor() {
        this.load();
    }

    load(): void {
        this.loadArrayUniqueProperty();
        this.loadArrayCopyProperty();
    }

    private loadArrayCopyProperty(): void {
        // @ts-ignore
        Array.prototype.copy = function (): any[] {
            const newArray = [];

            for (const element of this) newArray.push(element);

            return newArray;
        };
    }

    private loadArrayUniqueProperty(): void {
        // @ts-ignore
        Array.prototype.unique = function (): any[] {
            const newArray = [];

            for (const element of this) {
                if (newArray.includes(element)) continue;

                newArray.push(element);
            }

            return newArray;
        };
    }
}
