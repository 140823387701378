export interface AuthUser {
    publicId: string;
    expiresAtTimestamp: number;
    roles: string[];
    token: string | null;
    refreshToken: string | null;
    entityId: string | null;
    entityType: string | null;
    userId: string | null;
    id: string | null;
}

export function emptyAuthUser(): AuthUser {
    return {
        publicId: '',
        expiresAtTimestamp: Math.round(new Date().getTime() / 1000),
        roles: [],
        token: null,
        refreshToken: null,
        entityId: null,
        entityType: null,
        userId: null,
        id: null,
    };
}
