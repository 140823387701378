import { ContainerStepExecution, StepExecution } from './step-execution';
import { COMPLEX_START_STEP_TYPE } from '../training-sequence/step/step.types';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';

export class ComplexStartExecution extends ContainerStepExecution {
    private series: number;

    constructor(series: number, status: string, stepId: string) {
        super(status, stepId, COMPLEX_START_STEP_TYPE);

        this.series = series;
    }

    public getSeries(): number {
        return this.series;
    }

    protected updateWithExecutionData(stepExecutionData: StepExecution | null): void {
        if (!stepExecutionData) return;

        const complexStartExecutionData = stepExecutionData as ComplexStartExecution;
        this.series = complexStartExecutionData.getSeries();
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            ComplexStartExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getSpecificStepType(): ComplexStartExecution {
        return this;
    }
}
