import { ROUNDS_FOR_TIME_TYPE, TrainingSequence } from '../training-sequence.model';
import { ScalingSequence } from '../scaling-sequence.model';
import { TrainingExerciseRestriction } from '../../../service/restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import {
    distanceMeasureTemplate,
    repetitionMeasureTemplate,
    serieMeasureTemplate,
    weightMeasureTemplate,
} from '../../exercise-template/measure-template.model';
import { SequenceRestriction } from '../restriction/sequence-restriction.model';
import { MaxOneRoundPerSequenceRestriction } from '../restriction/max-one-round-per-sequence-restriction.model';
import { SeriesMeasureModeTrainingExerciseRestriction } from '../restriction/series-measure-mode-training-exercise-restriction.model';

export class RoundsForTimeSequence extends TrainingSequence {
    private numberOfRounds: number | null;

    constructor(
        id: string,
        name: string,
        mode: string,
        scalingSequences: ScalingSequence[],
        sessionSequenceId: string,
        notes: string,
        numberOfRounds: number | null
    ) {
        super(id, name, ROUNDS_FOR_TIME_TYPE, mode, scalingSequences, sessionSequenceId, notes);
        this.numberOfRounds = numberOfRounds;
    }

    _doAssert(): void {}

    public _sequenceRestrictions(): SequenceRestriction[] {
        return [new MaxOneRoundPerSequenceRestriction(), new SeriesMeasureModeTrainingExerciseRestriction()];
    }

    public _trainingExerciseRestriction(): TrainingExerciseRestriction | null {
        return {
            availableExerciseMeasureTemplates: [distanceMeasureTemplate(), repetitionMeasureTemplate()],
            availableMeasureModeTemplates: [serieMeasureTemplate()],
            weightMeasureTemplate: weightMeasureTemplate(),
        };
    }

    public getNumberOfRounds(): number | null {
        return this.numberOfRounds;
    }

    _clone(): TrainingSequence {
        const scalingSequences = this.scalingSequences.map((scalingSequence) => scalingSequence.clone());

        return new RoundsForTimeSequence(
            this.getId(),
            this.getName(),
            this.getMode(),
            scalingSequences,
            this.getSessionSequenceId(),
            this.getNotes(),
            this.numberOfRounds
        );
    }

    _canShowStep(stepLinkedId: string): boolean {
        const steps = this._stepsByLinkedId(stepLinkedId);
        return steps.length !== 0 && steps.filter((step) => step.isRound()).length === 0;
    }

    _canAddExerciseInOnlyOneRound(): boolean {
        return false;
    }

    _allRoundsShouldHaveSameSteps(): boolean {
        return true;
    }

    _isExerciseMeasureModeRequired(): boolean {
        return false;
    }

    _addRestAfterExercise(): boolean {
        return false;
    }

    updateNumberOfRounds(numberOfRounds: number | null): void {
        this.numberOfRounds = numberOfRounds;
    }

    _canCreateComplex(): boolean {
        return false;
    }

    public _doSanetize(): void {}

    public _canAddBuyIn(): boolean {
        return false;
    }

    public _canAddBuyOut(): boolean {
        return false;
    }
}
