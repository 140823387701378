import { Injectable, isDevMode } from '@angular/core';
import { Router } from './router';

@Injectable({
    providedIn: 'root',
})
export class RouteGenerator {
    private static readonly DEV_HOST = 'http://localhost:8075';
    //private static readonly DEV_HOST = 'http://192.168.1.54:8075';
    // private static readonly DEV_HOST = 'http://192.168.1.214:8075';
    private static readonly PROD_HOST = 'https://sportify.dinacode.com';
    private static readonly apiRoutePrefix = 'api-v1-';

    static host(): string {
        return isDevMode() ? this.DEV_HOST : this.PROD_HOST;
    }

    generate(apiRouteName: string, params: object): string {
        let relativePath = '';
        try {
            const fullApiRouteName = RouteGenerator.apiRoutePrefix + apiRouteName;
            relativePath = Router.generate(fullApiRouteName, params);
        } catch (exception) {
            relativePath = Router.generate(apiRouteName, params);
        }

        return RouteGenerator.host() + relativePath;
    }

    absoluteUrlFromRelativeUrl(relativeUrl: string): string {
        return RouteGenerator.host() + relativeUrl;
    }

    loginUrl(): string {
        return RouteGenerator.host() + '/api/login_check';
    }

    renovateTokenUrl(): string {
        return RouteGenerator.host() + '/api/refresh-coachId';
    }
}
