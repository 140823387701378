import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DefinitionState, STORE_KEY } from './training-execution.reducer';
import { TrainingExecutionsByParameters } from '../../query/training-execution/training-execution.queries';

export const getTrainingExecutionState = createFeatureSelector<DefinitionState>(STORE_KEY);

const selectTrainingExecutions = (state: DefinitionState) => state.trainingExecutions;

export const get = createSelector(getTrainingExecutionState, (state: DefinitionState, id: string) =>
    selectTrainingExecutions(state).find((trainingExecutionNode) => trainingExecutionNode.getId() === id)
);

export const getByPlayerId = createSelector(getTrainingExecutionState, (state: DefinitionState, playerId: string) =>
    selectTrainingExecutions(state).filter((trainingExecutionNode) => trainingExecutionNode.getPlayerId() === playerId)
);

export const getByParameters = createSelector(
    getTrainingExecutionState,
    (state: DefinitionState, query: TrainingExecutionsByParameters) =>
        selectTrainingExecutions(state).filter((trainingExecutionNode) => {
            for (const [filterKey, value] of Object.entries(query)) {
                if (!query[filterKey]) continue;
                if (filterKey === 'playerId' && value !== trainingExecutionNode.getPlayerId()) return false;
                if (filterKey === 'coachCreatorId' && value !== trainingExecutionNode.getCreatorLeetUserId())
                    return false;
                if (filterKey === 'status' && value !== trainingExecutionNode.getStatus()) return false;
                if (filterKey === 'trainingStatus' && value !== trainingExecutionNode.getTrainingStatus()) return false;
                if (filterKey === 'startAtMinDate' && value.timestamp > trainingExecutionNode.getStartAt().timestamp)
                    return false;
                if (filterKey === 'startAtMaxDate' && value.timestamp < trainingExecutionNode.getStartAt().timestamp)
                    return false;

                if (
                    filterKey === 'finishedAtMinDate' &&
                    value.timestamp > trainingExecutionNode.getFinishedAt().timestamp
                )
                    return false;
                if (
                    filterKey === 'finishedAtMaxDate' &&
                    value.timestamp < trainingExecutionNode.getFinishedAt().timestamp
                )
                    return false;

                if (
                    filterKey === 'scheduledAtMinDate' &&
                    value.timestamp > trainingExecutionNode.getScheduledAt().timestamp
                )
                    return false;
                if (
                    filterKey === 'scheduledAtMaxDate' &&
                    value.timestamp < trainingExecutionNode.getScheduledAt().timestamp
                )
                    return false;
            }

            return true;
        })
);
