import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class ComplexStartAndFinishRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let isComplexStarted = false;
        let hasComplexExercises = false;
        for (const step of steps) {
            if (step.isComplexStart()) {
                if (isComplexStarted) return false;

                isComplexStarted = true;
                continue;
            }

            if (!step.isComplexFinish()) {
                if (isComplexStarted && step.isExercise()) hasComplexExercises = true;

                continue;
            }

            if (!hasComplexExercises || !isComplexStarted) return false;

            hasComplexExercises = false;
            isComplexStarted = false;
        }

        return !isComplexStarted;
    }
}
