import { DifficultyLevel, Objective, Sport, TimePlanning } from '../definition/definition.models';
import { Coach } from '../user/coach.model';
import { PlanDay } from './plan-day.model';
import { Type } from 'class-transformer';
import { TrainingTemplate } from '../training/training-template.model';
import { v4 as uuid4 } from 'uuid';

export const PLAN_TEMPLATE_STATUS_ENABLED = 'enabled';
export const PLAN_TEMPLATE_STATUS_DISABLED = 'disabled';
export const PLAN_TEMPLATE_STATUS_DELETED = 'deleted';
export const PLAN_TEMPLATE_PUBLIC_VISIBILITY = 'public';
export const PLAN_TEMPLATE_PRIVATE_VISIBILITY = 'private';

export class PlanTemplate {
    private id: string;
    private status: string;
    private name: string;
    private notes: string | null;
    private coach: Coach | null;
    private difficultyLevel: DifficultyLevel;
    private timePlannings: TimePlanning[];
    private objectives: Objective[];
    private sports: Sport[];
    @Type(() => PlanDay)
    private planDays: PlanDay[];
    private visibility: string;

    constructor(
        id: string,
        status: string,
        name: string,
        notes: string | null,
        coach: Coach | null,
        difficultyLevel: DifficultyLevel,
        timePlannings: TimePlanning[],
        objectives: Objective[],
        sports: Sport[],
        planDays: PlanDay[],
        visibility: string
    ) {
        this.id = id;
        this.status = status;
        this.name = name;
        this.notes = notes;
        this.coach = coach;
        this.difficultyLevel = difficultyLevel;
        this.timePlannings = timePlannings;
        this.objectives = objectives;
        this.sports = sports;
        this.planDays = planDays;
        this.visibility = visibility;

        this.sanetize();
    }

    public isPublic(): boolean {
        return this.visibility === PLAN_TEMPLATE_PUBLIC_VISIBILITY;
    }

    public isPrivate(): boolean {
        return this.visibility === PLAN_TEMPLATE_PRIVATE_VISIBILITY;
    }

    public getId(): string {
        return this.id;
    }

    public isEnabled(): boolean {
        return this.status === PLAN_TEMPLATE_STATUS_ENABLED;
    }

    public isDisabled(): boolean {
        return this.status === PLAN_TEMPLATE_STATUS_DISABLED;
    }

    public getName(): string {
        return this.name;
    }

    public getCoach(): Coach | null {
        return this.coach;
    }

    public hasCoach(): boolean {
        return !!this.coach;
    }

    public getNotes(): string | null {
        return this.notes;
    }

    public getDifficultyLevel(): DifficultyLevel {
        return this.difficultyLevel;
    }

    public getTimePlannings(): TimePlanning[] {
        return this.timePlannings;
    }

    public getObjectives(): Objective[] {
        return this.objectives;
    }

    public getSports(): Sport[] {
        return this.sports;
    }

    public getPlanDays(): PlanDay[] {
        return this.planDays;
    }

    public getStatus(): string {
        return this.status;
    }

    public addPlanDay(planDay: PlanDay): void {
        if (this.existsPlanDay(planDay.getId())) {
            return;
        }

        this.planDays.push(planDay);
    }

    public canDeletePlanDay(planDayId: string): boolean {
        return true;
    }

    public deletePlanDay(planDayId: string): void {
        if (!this.canDeletePlanDay(planDayId)) {
            return;
        }

        this.planDays = this.getPlanDaysIfDeletePlanDay(planDayId);
    }

    public initializePlanDayAndAddTrainings(planDayId: string, trainingTemplates: TrainingTemplate[]): void {
        const existsPlanDay = this.existsPlanDay(planDayId);

        const planDay = existsPlanDay ? this.planDayById(planDayId) : new PlanDay(uuid4(), [], null, 0);

        trainingTemplates.forEach((trainingTemplate) => planDay.createAndAddPlanTraining(trainingTemplate));

        if (planDay.getPlanTrainings().length === 0) {
            this.deletePlanDay(planDayId);
            return;
        }

        if (!existsPlanDay) {
            this.planDays.push(planDay);
        }
    }

    private getPlanDaysIfDeletePlanDay(planDayId: string): PlanDay[] {
        return this.planDays.filter((planDay) => planDay.getId() !== planDayId);
    }

    private arePlanDaysValid(planDays: PlanDay[]): boolean {
        return planDays.length > 0;
    }

    public canMovePlanDayUp(planDayId: string): boolean {
        return this.planDayIndexById(planDayId) > 0;
    }

    public canMovePlanDayDown(planDayId: string): boolean {
        return this.planDayIndexById(planDayId) < this.planDays.length - 1;
    }

    public movePlanDayDown(planDayId: string): void {
        if (!this.canMovePlanDayDown(planDayId)) {
            return;
        }

        const newIndex = this.planDayIndexById(planDayId) + 1;
        this.movePlanDayToIndex(planDayId, newIndex);
    }

    public movePlanDayUp(planDayId: string): void {
        if (!this.canMovePlanDayUp(planDayId)) {
            return;
        }

        const newIndex = this.planDayIndexById(planDayId) - 1;
        this.movePlanDayToIndex(planDayId, newIndex);
    }

    public movePlanDayToIndex(planDayId: string, newIndex: number): void {
        const planDay = this.planDayById(planDayId);
        const newPlanDays = this.planDays.filter((savedPlanDay) => savedPlanDay.getId() !== planDayId);

        if (newIndex === 0) {
            this.planDays = [planDay, ...newPlanDays];
        } else if (newIndex === this.planDays.length - 1) {
            this.planDays = [...newPlanDays, planDay];
        } else {
            this.planDays = [...newPlanDays.slice(0, newIndex), planDay, ...newPlanDays.slice(newIndex)];
        }
    }

    private planDayById(id: string): PlanDay | null {
        for (const planDay of this.planDays) {
            if (planDay.getId() === id) {
                return planDay;
            }
        }

        return null;
    }

    private planDayByIndex(index: number): PlanDay | null {
        if (index < 0 || index >= this.planDays.length) {
            return null;
        }

        return this.planDays[index];
    }

    private planDayIdByIndex(index: number): string | null {
        const planDay = this.planDayByIndex(index);

        return planDay ? planDay.getId() : null;
    }

    private planDayIndexById(id: string): number | null {
        for (let index = 0; index < this.planDays.length; index++) {
            if (this.planDays[index].getId() === id) {
                return index;
            }
        }

        return null;
    }

    public existsPlanDay(planDayId: string): boolean {
        return this.planDayById(planDayId) !== null;
    }

    private sanetize(): void {
        if (!this.isPublic() && !this.isPrivate()) {
            this.visibility = PLAN_TEMPLATE_PRIVATE_VISIBILITY;
        }
    }

    public clone(replaceId = true): PlanTemplate {
        const id = replaceId ? uuid4() : this.getId();
        const planDays = this.planDays.map((planDay) => planDay.clone(replaceId));

        return new PlanTemplate(
            id,
            this.getStatus(),
            this.getName(),
            this.getNotes(),
            this.getCoach(),
            this.getDifficultyLevel(),
            this.getTimePlannings(),
            this.getObjectives(),
            this.getSports(),
            planDays,
            this.visibility
        );
    }
}
