import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DefinitionState, STORE_KEY} from './user.reducer';

export const getUserState = createFeatureSelector<DefinitionState>(
    STORE_KEY
);

const selectUsers = (state: DefinitionState) => state.users;

export const getAll = createSelector(
    getUserState, selectUsers
);

export const get = createSelector(
    getUserState,
    (state: DefinitionState, id: string) => selectUsers(state).find((userNode) => userNode.id === id)
);
