import { Injectable, Injector } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { Observable } from 'rxjs';
import { Club, RxClub } from '../../model/club/club.model';
import * as ClubAction from '../../store/club/club.actions';
import * as ClubSelector from '../../store/club/club.selectors';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { areEquals } from '../../../shared/function/lowdash';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { ServiceContainer } from '../reactive/service-container.service';

@Injectable({
    providedIn: 'root',
})
export class ClubService {
    readonly findRoute = 'get-club';
    readonly updateRoute = 'update-club';

    constructor(private apiClient: ApiClient, private store: Store<DefinitionState>, private injector: Injector) {}

    load(clubId: string): Observable<RxClub> {
        this.apiClient
            .get<Club>(this.findRoute, { clubId })
            .toPromise()
            .then((club) => {
                this.store.dispatch(ClubAction.load({ club }));
            });

        return this.get(clubId);
    }

    loadAll(): Observable<Club[]> {
        this.apiClient
            .get<Club[]>(this.findRoute)
            .toPromise()
            .then((clubs) => {
                this.store.dispatch(ClubAction.loadAll({ clubs }));
            });

        return this.getAll();
    }

    get(id: string): Observable<RxClub> {
        return this.store
            .select(ClubSelector.get, id)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), map(this.toRx.bind(this)));
    }

    getAll(): Observable<Club[]> {
        return this.store.select(ClubSelector.getAll).pipe(distinctUntilChanged(areEquals));
    }

    updateClub(clubId: string, name: string, fiscalAddress: string, status: string): Observable<any> {
        const postData = {
            clubId,
            clubName: name,
            fiscalAddress,
            status,
        };

        return this.apiClient.post(this.updateRoute, postData);
    }

    private toRx(club: Club): RxClub {
        if (!club) return null;
        return {
            ...club,
            clients: this.injector.get(ServiceContainer).leetUserService.toRxs(club.clients),
            coaches: this.injector.get(ServiceContainer).leetUserService.toRxs(club.coaches),
        };
    }
}
