import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class BuyInStartAndFinishRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let hasExerciseAfterBuyIn = false;
        let inBuyIn = false;
        let buyInHasExercises = false;
        const firstStep = steps[0];

        if (firstStep.isBuyInStart()) {
            inBuyIn = true;
        }

        for (let index = 1; index < steps.length; index++) {
            const step = steps[index];

            if (step.isBuyInStart()) {
                return false;
            }

            if (step.isBuyInFinish()) {
                if (!buyInHasExercises || !inBuyIn) {
                    return false;
                }

                inBuyIn = false;
                continue;
            }

            if (inBuyIn) {
                buyInHasExercises = buyInHasExercises || step.isExercise();
            } else {
                hasExerciseAfterBuyIn = hasExerciseAfterBuyIn || step.isExercise();
            }
        }

        return hasExerciseAfterBuyIn;
    }
}
