import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DefinitionState, STORE_KEY} from './club.reducer';

export const getClubState = createFeatureSelector<DefinitionState>(
    STORE_KEY
);

const selectClubs = (state: DefinitionState) => state.clubs;

export const getAll = createSelector(
    getClubState, selectClubs
);

export const get = createSelector(
    getClubState,
    (state: DefinitionState, id: string) => selectClubs(state).find((clubNode) => clubNode.id === id)
);
