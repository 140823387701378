import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { Observable, of, throwError } from 'rxjs';
import { TrainingDuration } from '../../model/definition/definition.models';
import * as TrainingDurationAction from '../../store/definition/training-duration.actions';
import * as TrainingDurationSelector from '../../store/definition/training-duration.selectors';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { catchError, distinctUntilChanged, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { CreateOrUpdateTrainingDuration } from '../../command/definition/training-duration.commands';
import { createDate } from '../../model/date/leet-date';
import { DefinitionMapper } from './definition.mapper';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';

@Injectable({
    providedIn: 'root',
})
export class TrainingDurationService {
    readonly findRoute = 'find-training-duration';
    readonly updateRoute = 'update-training-duration';

    constructor(
        private apiClient: ApiClient,
        private store: Store<DefinitionState>,
        private definitionMapper: DefinitionMapper
    ) {
    }

    getEmpty(): Observable<TrainingDuration> {
        const trainingDuration: TrainingDuration = {
            id: uuid4(),
            unit: '',
            value: '',
            status: 'enabled',
            createdAt: createDate(),
        };

        return of(trainingDuration);
    }

    loadAll(): Observable<TrainingDuration[]> {
        this.apiClient
            .get<TrainingDuration[]>(this.findRoute)
            .pipe(
                mergeMap((trainingDurations) => this.definitionMapper.hydrateDefinitions(trainingDurations)),
                tap((trainingDurations) => this.store.dispatch(TrainingDurationAction.loadAll({ trainingDurations })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getAll();
    }

    load(id: string): Observable<TrainingDuration> {
        this.apiClient
            .get<TrainingDuration>(this.findRoute, { id })
            .pipe(
                switchMap((rawTrainingDuration) => this.definitionMapper.hydrateDefinition(rawTrainingDuration)),
                tap((trainingDuration) => this.store.dispatch(TrainingDurationAction.load({ trainingDuration }))),
                catchError((error) => {
                    console.error(error);
                    return of(null);
                })
            )
            .toPromise()
            .then(() => {
            })
            .catch((error) => console.error(error));

        return this.getById(id);
    }

    getById(id: string): Observable<TrainingDuration> {
        return this.store.select(TrainingDurationSelector.get, id).pipe(distinctUntilChanged(areEquals), shareReplay());
    }

    getAll(): Observable<TrainingDuration[]> {
        return this.store
            .select(TrainingDurationSelector.getAll)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    update(command: CreateOrUpdateTrainingDuration): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.updateRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }
}
