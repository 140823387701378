import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiClient } from './service/network/api-client.service';
import { RouteGenerator } from './service/network/route-generator.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilderService } from './service/form/form-builder.service';
import { TranslateModule } from '@ngx-translate/core';
import { JsService } from './service/js/js.service';
import { NotificationService } from './page/notification.service';

@NgModule({
    declarations: [],
    imports: [TranslateModule, CommonModule, ReactiveFormsModule],
    providers: [JsService, ApiClient, RouteGenerator, FormBuilderService, NotificationService],
    exports: [TranslateModule],
})
export class SharedModule {}
