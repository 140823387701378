import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class BuyOutStartAndFinishRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let hasValidBuyOutFinish = false;
        let inBuyOut = false;
        let buyOutHasExercise = false;

        for (const step of steps) {
            if (step.isBuyOutStart()) {
                if (inBuyOut || hasValidBuyOutFinish) {
                    return false;
                }

                inBuyOut = true;
                hasValidBuyOutFinish = true;
                continue;
            }

            if (step.isBuyOutFinish()) {
                if (!inBuyOut) {
                    return false;
                }

                inBuyOut = false;
                continue;
            }

            if (inBuyOut) {
                buyOutHasExercise = buyOutHasExercise || step.isExercise();
            }
        }

        return !hasValidBuyOutFinish || (hasValidBuyOutFinish && buyOutHasExercise);
    }
}
