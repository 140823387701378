export const TIME_UNIT_SECOND = 'TIME_UNIT_SECOND';
export const TIME_UNIT_MINUTE = 'TIME_UNIT_MINUTE';
export const TIME_UNIT_HOUR = 'TIME_UNIT_HOUR';
export const DISTANCE_UNIT_FOOT = 'DISTANCE_UNIT_FOOT';
export const DISTANCE_UNIT_METERS = 'DISTANCE_UNIT_METERS';
export const DISTANCE_UNIT_KILOMETERS = 'DISTANCE_UNIT_KILOMETERS';
export const DISTANCE_UNIT_YARD = 'DISTANCE_UNIT_YARD';
export const DISTANCE_UNIT_MILE = 'DISTANCE_UNIT_MILE';
export const DISTANCE_UNIT_STEEP = 'DISTANCE_UNIT_STEEP';
export const NONE_UNIT = 'NONE';
export const WEIGHT_UNIT_KG = 'WEIGHT_UNIT_KG';
export const WEIGHT_UNIT_POUND = 'WEIGHT_UNIT_POUND';
export const WEIGHT_UNIT_POOD = 'WEIGHT_UNIT_POOD';
export const WEIGHT_UNIT_BW = 'WEIGHT_UNIT_BW';
export const WEIGHT_UNIT_RM = 'WEIGHT_UNIT_RM';
export const WEIGHT_UNIT_1_RF = 'WEIGHT_UNIT_1_RF';
export const WEIGHT_UNIT_2_RF = 'WEIGHT_UNIT_2_RF';
export const WEIGHT_UNIT_3_RF = 'WEIGHT_UNIT_3_RF';

export function timeMeasureUnits(): MeasureUnit[] {
    return timeUnits().map((value) => {
        return { value };
    });
}

export function distanceMeasureUnits(): MeasureUnit[] {
    return distanceUnits().map((value) => {
        return { value };
    });
}

export function weightMeasureUnits(): MeasureUnit[] {
    return allWeightUnits().map((value) => {
        return { value };
    });
}

export function serieMeasureUnits(): MeasureUnit[] {
    return [{ value: NONE_UNIT }];
}

export function repetitionMeasureUnits(): MeasureUnit[] {
    return [{ value: NONE_UNIT }];
}

export function timeUnits(): string[] {
    return [TIME_UNIT_SECOND, TIME_UNIT_MINUTE, TIME_UNIT_HOUR];
}

export function distanceUnits(): string[] {
    return [
        DISTANCE_UNIT_FOOT,
        DISTANCE_UNIT_METERS,
        DISTANCE_UNIT_KILOMETERS,
        DISTANCE_UNIT_YARD,
        DISTANCE_UNIT_MILE,
        DISTANCE_UNIT_STEEP,
    ];
}

export function allWeightUnits(): string[] {
    return [
        WEIGHT_UNIT_KG,
        WEIGHT_UNIT_POUND,
        WEIGHT_UNIT_POOD,
        WEIGHT_UNIT_BW,
        WEIGHT_UNIT_RM,
        WEIGHT_UNIT_1_RF,
        WEIGHT_UNIT_2_RF,
        WEIGHT_UNIT_3_RF,
    ];
}

export function isSerieMeasure(value: string): boolean {
    return value === NONE_UNIT;
}

export function isDistanceUnit(value: string): boolean {
    return distanceUnits().includes(value);
}

export function isWeightUnit(value: string): boolean {
    return allWeightUnits().includes(value);
}

export function isTimeUnit(value: string): boolean {
    return timeUnits().includes(value);
}

export function valueToTimeMeasureUnit(value: string): MeasureUnit | null {
    return isTimeUnit(value) ? { value } : null;
}

export interface MeasureUnit {
    value: string;
}
