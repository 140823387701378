import {
  distanceMeasureUnits,
  MeasureUnit,
  repetitionMeasureUnits,
  serieMeasureUnits,
  timeMeasureUnits,
  weightMeasureUnits
} from './measure-unit.model';

export const TIME_MEASURE = 'TIME_MEASURE';
export const WEIGHT_MEASURE = 'WEIGHT_MEASURE';
export const DISTANCE_MEASURE = 'DISTANCE_MEASURE';
export const REPETITION_MEASURE = 'REPETITION_MEASURE';
export const SERIE_MEASURE = 'SERIES_MEASURE';

export const GENDER_MODE_UNIFIED = 'unified';
export const GENDER_MODE_SEPARATED = 'separated';

export function types(): string[] {
  return [
    TIME_MEASURE,
    WEIGHT_MEASURE,
    DISTANCE_MEASURE,
    REPETITION_MEASURE,
    SERIE_MEASURE
  ];
}

export function genderModes() {
  return [
    GENDER_MODE_UNIFIED,
    GENDER_MODE_SEPARATED
  ];
}

export function timeMeasureTemplate(genderMode: string = GENDER_MODE_UNIFIED): MeasureTemplate {
  return {
    type: TIME_MEASURE,
    units: timeMeasureUnits(),
    genderMode
  };
}

export function weightMeasureTemplate(genderMode: string = GENDER_MODE_UNIFIED): MeasureTemplate {
  return {
    type: WEIGHT_MEASURE,
    units: weightMeasureUnits(),
    genderMode
  };
}

export function distanceMeasureTemplate(genderMode: string = GENDER_MODE_UNIFIED): MeasureTemplate {
  return {
    type: DISTANCE_MEASURE,
    units: distanceMeasureUnits(),
    genderMode
  };
}

export function serieMeasureTemplate(genderMode: string = GENDER_MODE_UNIFIED): MeasureTemplate {
  return {
    type: SERIE_MEASURE,
    units: serieMeasureUnits(),
    genderMode
  };
}

export function repetitionMeasureTemplate(genderMode: string = GENDER_MODE_UNIFIED): MeasureTemplate {
  return {
    type: REPETITION_MEASURE,
    units: repetitionMeasureUnits(),
    genderMode
  };
}

export interface MeasureTemplate {
  type: string;
  units: MeasureUnit[];
  genderMode: string;
}
