import {defer} from 'lodash';

export async function asyncDefer(callback: () => void): Promise<void> {
    const promise: Promise<void> = new Promise((resolve) => {
        defer(async () => {
            await callback();
            resolve();
        });
    });

    return promise;

}
