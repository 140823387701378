import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ComponentProps, ComponentRef, FrameworkDelegate, Mode, OverlayEventDetail} from '@ionic/core';
import _ from 'lodash';

export interface ModalOptions<T extends ComponentRef = ComponentRef> {
    component: T;
    componentProps?: ComponentProps<T>;
    presentingElement?: HTMLElement;
    showBackdrop?: boolean;
    backdropDismiss?: boolean;
    cssClass?: string | string[];
    delegate?: FrameworkDelegate;
    breakpoints?: number[];
    initialBreakpoint?: number;
    animated?: boolean;
    swipeToClose?: boolean;
    mode?: Mode;
    keyboardClose?: boolean;
    id?: string;
    onWillDismissCallback?: any;
}

@Injectable({
    providedIn: 'root',
})
export class Modal {

    constructor(private readonly modalCtrl: ModalController) {
        this.present = _.debounce(this.showModal, 500)
    }


    async present(options: ModalOptions) {
        return await this.showModal(options);
    }


    async showModal(options: ModalOptions): Promise<any> {

        let data = undefined;
        const modal = await this.modalCtrl.create(options);
        if (options.onWillDismissCallback)
            modal.onWillDismiss().then(options.onWillDismissCallback);

        await modal.present();
        if (data)
            return data;
    }

    async dismiss(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    async getTop(): Promise<HTMLIonModalElement> {
        return this.modalCtrl.getTop();
    }


}
