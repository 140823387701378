import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DefinitionState, STORE_KEY } from './call-to-action.reducer';

export const getLeetUserCallToActionsState = createFeatureSelector<DefinitionState>(STORE_KEY);

const selectLeetUsers = (state: DefinitionState) => state.leetUserCallToActions;

export const get = createSelector(getLeetUserCallToActionsState, (state: DefinitionState, leetUserId: string) =>
    selectLeetUsers(state).find((leetUserCallToAction) => leetUserCallToAction.leetUserId === leetUserId)
);
