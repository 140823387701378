import { Injectable } from '@angular/core';
import { ApiClient } from '../../../shared/service/network/api-client.service';
import { Store } from '@ngrx/store';
import { DefinitionState } from '../../store/definition/definition.reducer';
import { Observable, of, throwError } from 'rxjs';
import { DifficultyLevel, Objective } from '../../model/definition/definition.models';
import * as DifficultyAction from '../../store/definition/difficulty-level.actions';
import * as DifficultySelector from '../../store/definition/difficulty-level.selectors';
import { CreateOrUpdateDifficultyLevel } from '../../command/definition/difficulty-level.commands';
import { EntityUpdated } from '../../../shared/model/network/network-models';
import { catchError, distinctUntilChanged, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { v4 as uuid4 } from 'uuid';
import { areEquals } from '../../../shared/function/lowdash';
import { DefinitionMapper } from './definition.mapper';
import { createDate } from '../../model/date/leet-date';
import memorize from 'memorize-decorator';
import { skipWhileNullOrUndefined } from '../../../shared/function/rxjs.operators';
import { sortByName } from '../../function/definition.operators';
import { Session } from '../session/session.service';

@Injectable({
    providedIn: 'root',
})
export class DifficultyLevelService {
    readonly findRoute = 'api-v1-find-difficulty-level';
    readonly updateRoute = 'api-v1-update-difficulty-level';

    constructor(
        private apiClient: ApiClient,
        private definitionMapper: DefinitionMapper,
        private store: Store<DefinitionState>,
        private session: Session
    ) {
    }

    getEmpty(): Observable<DifficultyLevel> {
        const difficultyLevel = {
            id: uuid4(),
            name: '',
            status: 'enabled',
            createdAt: createDate(),
        };

        return of(difficultyLevel);
    }

    loadAll(): Observable<DifficultyLevel[]> {
        this.apiClient
            .get<DifficultyLevel[]>(this.findRoute)
            .pipe(
                mergeMap((rawDifficulties) => this.definitionMapper.hydrateDefinitions(rawDifficulties)),
                tap((difficulties) => this.store.dispatch(DifficultyAction.loadAll({ difficulties })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getAll();
    }

    @memorize({ ttl: 1000 })
    load(id: string): Observable<DifficultyLevel> {
        this.apiClient
            .get<DifficultyLevel>(this.findRoute, { id })
            .pipe(
                mergeMap((rawDifficulty) => this.definitionMapper.hydrateDefinition(rawDifficulty)),
                tap((difficultyLevel) => this.store.dispatch(DifficultyAction.load({ difficultyLevel })))
            )
            .toPromise()
            .then(() => {
            });

        return this.getById(id);
    }

    getPrivateScopedByCurrentUser(): Observable<Objective[]> {
        this.loadAll();
        return this.store
            .select(DifficultySelector.getPrivateScopedByCurrentUser, this.session.currentAuthUser().entityId)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay(), sortByName());
    }

    getById(id: string): Observable<DifficultyLevel> {
        return this.store
            .select(DifficultySelector.get, id)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay());
    }

    getAll(): Observable<DifficultyLevel[]> {
        return this.store
            .select(DifficultySelector.getAll)
            .pipe(skipWhileNullOrUndefined(), distinctUntilChanged(areEquals), shareReplay(), sortByName());
    }

    update(command: CreateOrUpdateDifficultyLevel): Observable<EntityUpdated> {
        return this.apiClient
            .post<any>(this.updateRoute, command, { id: command.id })
            .pipe(
                mergeMap(() => {
                    const response: EntityUpdated = { id: command.id };
                    return of(response);
                }),
                tap(() => this.load(command.id)),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }
}
