export function mapObjectToIds(objects: any): string[] {
    objects = objects.filter((object) => !!object);
    return objects.map((object) => object.id);
}

export function arrayMove<T>(array: T[], oldIndex: number, newIndex: number): T[] {
    if (newIndex >= array.length) {
        var k = newIndex - array.length + 1;
        while (k--) {
            array.push(undefined);
        }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

export function arrayInsert<T>(array: T[], items: T[], index: number | null = null): T[] {
    if (!index) {
        array.push(...items);
        return array;
    }

    array.splice(index, 0, ...items);
    return array;
}

export function mapAsync(array, callbackfn) {
    return Promise.all(array.map(callbackfn));
}

export async function filterAsync(array, callbackfn) {
    const filterMap = await mapAsync(array, callbackfn);
    return array.filter((value, index) => filterMap[index]);
}
