import {createAction, props} from '@ngrx/store';
import {LeetUser} from '../../model/user/leet-user.model';
import {Location} from '../../model/definition/definition.models';

export const loadMany = createAction(
    '[LeetUser] Load Many',
    props<{ leetUsers: LeetUser[] }>()
);

export const load = createAction(
  '[LeetUser] Load One',
  props<{ leetUser: LeetUser; }>()
);

export const loadClient = createAction(
    '[LeetUser] LoadClient',
    props<{ client: LeetUser, leetUser: LeetUser }>()
);
