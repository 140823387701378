import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {
    initChatState,
    initClubState,
    initDefinitionState,
    initExerciseTemplateState,
    initFileState,
    initLeetUserCallToActions,
    initLeetUserState,
    initPlanExecutionState,
    initPlanTemplateState,
    initPlayerState,
    initTrainingExecutionState,
    initTrainingSequenceState,
    initTrainingTemplateState,
    initUserState,
    initUserSubscriptionsState,
} from '../../../leet/store/meta/meta.actions';
import {AuthUser} from '../../model/authentication/auth-user.model';
import {plainToClass} from 'class-transformer';
import {TrainingExecution} from '../../../leet/model/training-execution/training-execution.model';
import {TrainingTemplate} from '../../../leet/model/training/training-template.model';
import {PlanTemplate} from '../../../leet/model/plan-template/plan-template.model';
import {StorageService} from './storage.service';
import {asyncDefer} from '../../function/async.helpers';

export const StorageStoreStateKey = 'store-state';

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private sessionAuthUser: AuthUser | null = null;

    constructor(private http: HttpClient, private storageService: StorageService, private store: Store) {
    }

    async initSessionUser(): Promise<void> {
        this.sessionAuthUser = await this.storageService.get('sessionUser');
    }

    async loadImage(imageUrl: string): Promise<string> {
        const storageValue = await this.storageService.get(imageUrl);
        if (storageValue) return storageValue;

        const blob = await this.http.get(imageUrl, {responseType: 'blob'}).toPromise();
        const reader = new FileReader();
        reader.onload = () => {
            const base64data = reader.result;
            this.storageService.set(imageUrl, base64data);
        };

        reader.readAsDataURL(blob);
        return imageUrl;
    }

    async cleanCachedStoreState(): Promise<void> {
        await this.storageService.clear();
    }

    async loadCachedStoreState(): Promise<void> {
        const state = await this.storageService.get(StorageStoreStateKey);

        console.log('LOAD CACHE ', state);

        if (!state) return Promise.resolve();

        this.store.dispatch(initUserState(state.user));
        this.store.dispatch(initLeetUserCallToActions(state['call-to-action']));
        this.store.dispatch(initUserSubscriptionsState(state['user-subscription']));
        this.store.dispatch(initDefinitionState(state['leet-item']));

        await asyncDefer(() => {
            console.time('loadCachedStoreState');
            this.store.dispatch(initChatState(state.chat));

            console.timeEnd('loadCachedStoreState');

        });

        await asyncDefer(() => {
            const trainingTemplates = state['training-template-templates'].trainingTemplates.map((plainTrainingTemplate) =>
                plainToClass(TrainingTemplate, plainTrainingTemplate)
            );

            this.store.dispatch(
                initTrainingTemplateState({
                    ...state['training-template-templates'],
                    trainingTemplates: trainingTemplates,
                })
            );
        });



        return;



        this.store.dispatch(initClubState(state.club));
        this.store.dispatch(initExerciseTemplateState(state['exercise-template']));
        this.store.dispatch(initFileState(state.file));
        this.store.dispatch(initLeetUserState(state['leet-user']));
        this.store.dispatch(initPlanExecutionState(state['plan-execution']));
        this.store.dispatch(initTrainingSequenceState(state['training-sequence']));
        this.store.dispatch(initPlayerState(state.player));
        this.store.dispatch(
            initPlanTemplateState({
                ...state['plan-templates'],
                planTemplates: state['plan-templates'].planTemplates.map((plainPlanTemplates) =>
                    plainToClass(PlanTemplate, plainPlanTemplates)
                ),
            })
        );
        this.store.dispatch(
            initTrainingExecutionState({
                ...state['training-template-execution'],
                trainingExecutions: state[
                    'training-template-execution'
                    ].trainingExecutions.map((plainTrainingExecution) =>
                    plainToClass(TrainingExecution, plainTrainingExecution)
                ),
            })
        );

    }

    loadSessionUser(authUser: AuthUser): void {
        this.sessionAuthUser = authUser;
        this.storageService.set('sessionUser', authUser);
    }

    removeSessionUser(): void {
        this.sessionAuthUser = null;
        this.storageService.remove('sessionUser');
        this.storageService.remove(StorageStoreStateKey);
    }

    sessionUser(): AuthUser | null {
        return this.sessionAuthUser;
    }
}
