import {createAction, props} from '@ngrx/store';
import {ExerciseTemplate} from '../../model/exercise-template/exercise-template.model';

export const load = createAction(
    '[Exercise Template] Load',
    props<{ exerciseTemplate: ExerciseTemplate }>()
);

export const remove = createAction(
    '[Exercise Template] Remove',
    props<{ id: string }>()
);

export const loadAll = createAction(
    '[Exercise Template] Load all',
    props<{ exerciseTemplates: ExerciseTemplate[] }>()
);

export const loadAllDefault = createAction(
    '[Exercise Template] Load all default',
    props<{ defaultExerciseTemplates: ExerciseTemplate[] }>()
);

export const loadByQueryFilter = createAction(
    '[Exercise Template] Load by query filter',
    props<{ serializedQuery: string, exerciseTemplates: ExerciseTemplate[] }>()
);


