import {createAction, props} from '@ngrx/store';
import {SessionSequence} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Session Sequence] Load all',
    props<{ sessionSequences: SessionSequence[] }>()
);

export const load = createAction(
    '[Session Sequence] Load',
    props<{ sessionSequence: SessionSequence }>()
);


