import { Injectable } from '@angular/core';
import { v4 as uuid4 } from 'uuid';
import { Rest } from '../../model/training-sequence/step/rest.model';
import { isTimeUnit, MeasureUnit, timeMeasureUnits } from '../../model/exercise-template/measure-unit.model';
import { Round } from '../../model/training-sequence/step/round.model';
import { TrainingExercise } from '../../model/training-sequence/step/training-exercise.model';
import { ExerciseTemplate } from '../../model/exercise-template/exercise-template.model';
import { Measure } from '../../model/training-sequence/measure.model';
import { TrainingExerciseRestriction } from '../restriction-validator/training-sequence/training-exercise/training-exercise-restriction';
import { ComplexStart } from '../../model/training-sequence/step/complex-start.model';
import { ComplexFinish } from '../../model/training-sequence/step/complex-finish.model';
import { BuyInStart } from '../../model/training-sequence/step/buy-in-start.model';
import { BuyInFinish } from '../../model/training-sequence/step/buy-in-finish.model';
import { BuyOutFinish } from '../../model/training-sequence/step/buy-out-finish.model';
import { BuyOutStart } from '../../model/training-sequence/step/buy-out-start.model';

@Injectable({
    providedIn: 'root',
})
export class StepFactory {
    public static newInstance(): StepFactory {
        return new StepFactory();
    }

    public createComplexStart(linkedId: string | null = null, series: number | null = null): ComplexStart {
        linkedId = linkedId ? linkedId : uuid4();

        return new ComplexStart(uuid4(), linkedId, series);
    }

    public createComplexFinish(linkedId: string | null = null): ComplexFinish {
        linkedId = linkedId ? linkedId : uuid4();

        return new ComplexFinish(uuid4(), linkedId);
    }

    public createBuyInStart(linkedId: string | null = null, numberOfRounds: number | null = null): BuyInStart {
        linkedId = linkedId ? linkedId : uuid4();

        return new BuyInStart(uuid4(), linkedId, numberOfRounds);
    }

    public createBuyInFinish(linkedId: string | null = null): BuyInFinish {
        linkedId = linkedId ? linkedId : uuid4();

        return new BuyInFinish(uuid4(), linkedId);
    }

    public createBuyOutStart(linkedId: string | null = null, numberOfRounds: number | null = null): BuyOutStart {
        linkedId = linkedId ? linkedId : uuid4();

        return new BuyOutStart(uuid4(), linkedId, numberOfRounds);
    }

    public createBuyOutFinish(linkedId: string | null = null): BuyOutFinish {
        return new BuyOutFinish(uuid4(), linkedId);
    }

    public createRest(
        linkedId: string | null = null,
        value: number | null = null,
        measureUnit: MeasureUnit | null = null
    ): Rest {
        linkedId = linkedId ? linkedId : uuid4();
        value = value ? value : 0;

        if (measureUnit && !isTimeUnit(measureUnit.value)) measureUnit = timeMeasureUnits().pop();

        return new Rest(uuid4(), linkedId, measureUnit, value);
    }

    public createRound(linkedId: string | null = null, name: string | null = null): Round {
        linkedId = linkedId ? linkedId : uuid4();
        return new Round(uuid4(), linkedId, name);
    }

    public createTrainingExercise(
        exerciseTemplate: ExerciseTemplate,
        restriction: TrainingExerciseRestriction,
        linkedId: string | null = null
    ): TrainingExercise | null {
        linkedId = linkedId ? linkedId : uuid4();

        const exerciseMeasure = this.firstValidExerciseMeasure(exerciseTemplate, restriction);

        if (!exerciseMeasure) return null;
        const measureMode = this.firstValidMeasureMode(restriction);

        const exerciseWeightMeasureTemplate = exerciseTemplate.weightMeasureTemplate;
        const weightMeasureTemplate = exerciseWeightMeasureTemplate
            ? new Measure(
                exerciseWeightMeasureTemplate.type,
                exerciseWeightMeasureTemplate.units[0],
                exerciseWeightMeasureTemplate.genderMode
            )
            : null;

        return new TrainingExercise(
            uuid4(),
            linkedId,
            exerciseTemplate,
            exerciseMeasure,
            measureMode,
            weightMeasureTemplate,
            1,
            weightMeasureTemplate ? 1 : 0,
            weightMeasureTemplate ? 1 : 0,
            1,
            restriction,
            0
        );
    }

    private firstValidExerciseMeasure(
        exerciseTemplate: ExerciseTemplate,
        restriction: TrainingExerciseRestriction
    ): Measure | null {
        const restrictionAvailableMeasureTemplateTypes = restriction.availableExerciseMeasureTemplates.map(
            (measureTemplate) => measureTemplate.type
        );

        const validMeasureTemplates = exerciseTemplate.availableMeasureTemplates.filter((measureTemplate) =>
            restrictionAvailableMeasureTemplateTypes.includes(measureTemplate.type)
        );

        if (validMeasureTemplates.length === 0) return null;

        for (let index = 0; index < validMeasureTemplates.length; index++) {
            const measureTemplate = validMeasureTemplates[index];

            const availableUnits = measureTemplate.units.map((unit) => unit.value);
            const allowedUnits = restriction.availableExerciseMeasureTemplates
                .filter((availableMeasureTemplate) => availableMeasureTemplate.type === measureTemplate.type)
                .pop()
                .units.map((unit) => unit.value);

            for (let availableUnitsIndex = 0; availableUnitsIndex < availableUnits.length; availableUnitsIndex++) {
                const availableUnitValue = availableUnits[availableUnitsIndex];

                if (!allowedUnits.includes(availableUnitValue)) continue;

                return new Measure(measureTemplate.type, { value: availableUnitValue }, measureTemplate.genderMode);
            }
        }

        return null;
    }

    private firstValidMeasureMode(restriction: TrainingExerciseRestriction): Measure | null {
        const measureTemplate = restriction.availableMeasureModeTemplates[0];

        return new Measure(measureTemplate.type, { value: measureTemplate.units[0].value }, measureTemplate.genderMode);
    }
}
