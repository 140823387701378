import { ActionStepExecution, StepExecution } from './step-execution';
import {
    MeasureUnit,
    TIME_UNIT_MINUTE,
    TIME_UNIT_SECOND,
    valueToTimeMeasureUnit,
} from '../exercise-template/measure-unit.model';
import { REST_STEP_TYPE } from '../training-sequence/step/step.types';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';

export class RestExecution extends ActionStepExecution {
    private readonly validTimeMeasureUnitsValues = [TIME_UNIT_SECOND, TIME_UNIT_MINUTE];

    private measureUnit: MeasureUnit;
    private value: number;

    constructor(measureUnit: MeasureUnit, value: number, status: string, stepId: string) {
        super(status, stepId, REST_STEP_TYPE);
        this.measureUnit = measureUnit;
        this.value = value;
    }

    public getMeasureUnit(): MeasureUnit {
        return this.measureUnit;
    }

    public getValue(): number {
        return this.value;
    }

    protected updateWithExecutionData(stepExecutionData: StepExecution | null): void {
        if (!stepExecutionData) throw new Error();

        const restExecutionData = stepExecutionData as RestExecution;

        this.measureUnit = restExecutionData.measureUnit;
        this.value = restExecutionData.value;
    }

    hasNextMeasureUnit(): boolean {
        return this.validTimeMeasureUnitsValues.length > 1;
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            RestExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getSpecificStepType(): RestExecution {
        return this;
    }

    getValidTimeMeasureUnitsValues(): string[] {
        return this.validTimeMeasureUnitsValues;
    }

    updateValue(value: number): void {
        this.value = value;
    }

    updateMeasureUnit(value: string): void {
        const isValidMeasureUnitType = this.validTimeMeasureUnitsValues.find(
            (measureUnitType) => measureUnitType === value
        );
        if (!isValidMeasureUnitType) return;

        this.measureUnit = valueToTimeMeasureUnit(value);
    }

    getTitle(): string {
        return 'Rest';
    }
}
