import { ContainerStepExecution, StepExecution } from './step-execution';
import { BUY_IN_START_STEP_TYPE } from '../training-sequence/step/step.types';
import { SerializerService } from '../../../shared/service/serializer/serializer.service';
import { MapperService } from '../../../shared/service/mapper/mapper.service';
import { v4 as uuid4 } from 'uuid';

export class BuyInStartExecution extends ContainerStepExecution {
    private numberOfRounds: number;

    constructor(numberOfRounds: number, status: string, stepId: string) {
        super(status, stepId, BUY_IN_START_STEP_TYPE);
        this.numberOfRounds = numberOfRounds;
    }

    public getNumberOfRounds(): number {
        return this.numberOfRounds;
    }

    protected updateWithExecutionData(stepExecutionData: ContainerStepExecution | null): void {
        if (!stepExecutionData) return;

        const buyInStartExecutionData = stepExecutionData as BuyInStartExecution;
        this.numberOfRounds = buyInStartExecutionData.getNumberOfRounds();
    }

    protected doClone(keepId: boolean): StepExecution {
        const serializer = SerializerService.newInstance();
        const newInstance = MapperService.newInstance().map(
            serializer.deserialize(serializer.serialize(this)),
            BuyInStartExecution
        );

        newInstance.id = keepId ? newInstance.id : uuid4();

        return newInstance;
    }

    getSpecificStepType(): BuyInStartExecution {
        return this;
    }
}
