import { Action, createReducer, on } from '@ngrx/store';
import { LeetUserCallToActions } from '../../model/user/leet-user.model';
import { initState } from '../meta/meta.reducers';
import { clearState, initLeetUserCallToActions } from '../meta/meta.actions';
import * as LeetUserCallToActionsActions from './call-to-action.actions';

export const STORE_KEY = 'call-to-action';

export interface DefinitionState {
    leetUserCallToActions: LeetUserCallToActions[];
}

export const initialState: DefinitionState = {
    leetUserCallToActions: [],
};

const loadLeetUserCallToAction = (state, { leetUserCallToActions }) => {
    if (!leetUserCallToActions) return state;
    const isLeetUserCallToActionInStore = !!state.leetUserCallToActions.find(
        (leetUserCallToActionNode) => leetUserCallToActions.id === leetUserCallToActionNode.id
    );
    if (isLeetUserCallToActionInStore) return updateLeetUserCallToActions(state, leetUserCallToActions);

    return addLeetUserCallToAction(state, leetUserCallToActions);
};

const addLeetUserCallToAction = (state, leetUserCallToActions) => {
    return {
        ...state,
        leetUserCallToActions: [...state.leetUserCallToActions, leetUserCallToActions],
    };
};

const updateLeetUserCallToActions = (state: DefinitionState, leetUserCallToActions: LeetUserCallToActions) => {
    return {
        ...state,
        leetUserCallToActions: state.leetUserCallToActions.map((storeLeetUserCallToActions: LeetUserCallToActions) => {
            if (storeLeetUserCallToActions.id !== leetUserCallToActions.id) return storeLeetUserCallToActions;
            return leetUserCallToActions;
        }),
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initLeetUserCallToActions, initState),
        on(LeetUserCallToActionsActions.load, loadLeetUserCallToAction)
    );

    return definitionReducer(state, action);
}
