import { createSelector } from '@ngrx/store';
import { getDefinitionState } from './definition.selector';
import { DefinitionState } from './definition.reducer';
import { isPublicScope } from '../../model/scope/scope.models';

const selectMuscleGroups = (state: DefinitionState) => state.muscleGroups;

export const getAll = createSelector(getDefinitionState, selectMuscleGroups);

export const getPrivateScopedByCurrentUser = createSelector(
    getDefinitionState,
    (state: DefinitionState, leetUserOwnerId: string) =>
        selectMuscleGroups(state).filter((muscleNode) => {
            if (muscleNode.status === 'disabled') return false;
            if (isPublicScope(muscleNode.scope.scope)) return true;
            return muscleNode.scope.leetUserOwnerId === leetUserOwnerId;
        })
);

export const getPublicAdminScoped = createSelector(getDefinitionState, (state: DefinitionState) =>
    selectMuscleGroups(state).filter((muscleNode) => {
        if (muscleNode.status === 'disabled') return false;
        return muscleNode.scope.scope === 'SCOPE_PUBLIC_ADMIN';
    })
);

export const get = createSelector(getDefinitionState, (state: DefinitionState, id: string) =>
    selectMuscleGroups(state).find((muscleNode) => muscleNode.id === id)
);
