import { SequenceRestriction } from './sequence-restriction.model';
import { Step } from '../step/step.model';

export class NoMultipleRestRestriction extends SequenceRestriction {
    public areStepsOrderValid(steps: Step[]): boolean {
        let isPrevStepRest = false;

        for (const step of steps) {
            if (step.isRest()) {
                if (isPrevStepRest) {
                    return false;
                }

                isPrevStepRest = true;
            }
        }

        return true;
    }
}
