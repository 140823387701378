import {createAction, props} from '@ngrx/store';
import {User} from '../../model/user/user.model';

export const load = createAction(
    '[User] Load',
    props<{ user: User }>()
);

export const loadAll = createAction(
    '[User] Load all',
    props<{ users: User[] }>()
);

export const loadByIds = createAction(
    '[User] Load by ids',
    props<{ users: User[] }>()
);


