import { Action, createReducer, on } from '@ngrx/store';
import * as TrainingExecutionActions from './training-execution.actions';
import { TrainingExecution } from '../../model/training-execution/training-execution.model';
import { clearState, initTrainingExecutionState } from '../meta/meta.actions';
import { initState } from '../meta/meta.reducers';

export const STORE_KEY = 'training-template-execution';

export interface DefinitionState {
    trainingExecutions: TrainingExecution[];
}

export const initialState: DefinitionState = {
    trainingExecutions: [],
};

const updatePlayerTrainingExecutions = (state, { trainingExecutions, playerId }) => {
    return {
        ...state,
        trainingExecutions: [
            ...state.trainingExecutions.filter((trainingExecution) => trainingExecution.playerId !== playerId),
            ...trainingExecutions,
        ],
    };
};

const updateTrainingExecution = (state, { trainingExecution }) => {
    return {
        ...state,
        trainingExecutions: [
            ...state.trainingExecutions.filter(
                (savedTrainingExecution) => savedTrainingExecution.id !== trainingExecution.id
            ),
            trainingExecution,
        ],
    };
};

const addOrUpdateTrainingExecutions = (state, { trainingExecutions }) => {
    const trainingExecutionIds = trainingExecutions.map((trainingExecution) => trainingExecution.id);
    return {
        ...state,
        trainingExecutions: [
            ...state.trainingExecutions.filter(
                (stateTrainingExecution) => !trainingExecutionIds.includes(stateTrainingExecution.id)
            ),
            ...trainingExecutions,
        ],
    };
};

const removeTrainingExecution = (state, { id }) => {
    return {
        ...state,
        trainingExecutions: [
            ...state.trainingExecutions.filter((stateTrainingExecution) => stateTrainingExecution.id !== id),
        ],
    };
};

export function reducer(state: DefinitionState | undefined, action: Action) {
    const definitionReducer = createReducer(
        initialState,
        on(clearState, () => initialState),
        on(initTrainingExecutionState, initState),
        on(TrainingExecutionActions.loadByPlayer, updatePlayerTrainingExecutions),
        on(TrainingExecutionActions.load, updateTrainingExecution),
        on(TrainingExecutionActions.loadByParametersSuccess, addOrUpdateTrainingExecutions),
        on(TrainingExecutionActions.remove, removeTrainingExecution)
    );

    return definitionReducer(state, action);
}
