export function isMale(gender: Gender): boolean {
    return gender.type === MALE_GENDER;
}

export function isFemale(gender: Gender): boolean {
    return gender.type === FEMALE_GENDER;
}

export function isUnknown(gender: Gender): boolean {
    return gender.type === UNKNOWN_GENDER;
}

export const MALE_GENDER = 'male';
export const FEMALE_GENDER = 'female';
export const UNKNOWN_GENDER = 'unknown';

export interface Gender {
    type: string;
}
