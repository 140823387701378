import {createAction, props} from '@ngrx/store';
import {Objective} from '../../model/definition/definition.models';

export const loadAll = createAction(
    '[Objective] Load all',
    props<{ objectives: Objective[] }>()
);

export const load = createAction(
    '[Objective] Load',
    props<{ objective: Objective }>()
);


