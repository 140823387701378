import { ContainerStep, Step } from './step.model';
import * as StepTypes from './step.types';
import { v4 as uuid4 } from 'uuid';

export class ComplexStart extends ContainerStep {
    private series: number | null;

    constructor(id: string, linkedId: string, series: number | null) {
        super(id, linkedId, StepTypes.COMPLEX_START_STEP_TYPE);

        this.series = series;
        this.assert();
    }

    public getSeries(): number | null {
        return this.series;
    }

    public updateSeries(series: number | null): void {
        if (!series || series < 1) {
            return;
        }

        this.series = series;
    }

    protected doAssert(): void {
    }

    protected doClone(maintainLinkedId: boolean): Step {
        const linkedId = maintainLinkedId ? this.getLinkedId() : uuid4();

        return new ComplexStart(uuid4(), linkedId, this.series);
    }

    getSpecificStepType(): Step {
        return this as ComplexStart;
    }
}
